import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import { API_URL } from "../../constents/helpers";
// import ContiLogo from "../../assets/continental_logo.svg";
import EUT_logo from "../../assets/EUT_logo.png";
import { IconHemberger } from "../../Components/Icons";

function Logo(props) {
  let location = useLocation();
  // debugger
  return (
    <Fragment>
      {props.isUserAuthenticated &&
      (location.pathname === "/newproducts" ||
        location.pathname === "/orders") ? (
        <div
          className={
            props.collapsefilter ? "filter-icon-btn active" : "filter-icon-btn"
          }
          onClick={props.showfilters}
        >
          <IconHemberger />
        </div>
      ) : (
        <Fragment>
          {location.pathname === "/profile" ||
          location.pathname === "/security" ||
          location.pathname === "/accounting" ||
          location.pathname === "/notificationsetting" ||
          location.pathname === "/complaints" ? (
            <div
              className={
                props.collapseUserNav
                  ? "filter-icon-btn active"
                  : "filter-icon-btn"
              }
              onClick={props.showUserNav}
            >
              <span className="icon-open-menu"></span>
            </div>
          ) : null}
        </Fragment>
      )}

      {props.isMobile ? (
        <Fragment>
          {props.isUserAuthenticated ? (
            <Col xs={5} sm={3} md={2} lg={4} xl={3} className="px-0 px-md-2">
              <Link to="/dashboard" className="navbar-brand">
                {/* {props.adminData && (
                  <img
                    src={
                      `${API_URL}var/assets` +
                      props.adminData.mobileLogo.fullpath
                    }
                    alt="This is DMG"
                  />
                )} */}
                <img src={EUT_logo} alt="conti-Logo" />
              </Link>
            </Col>
          ) : (
            <Col xs={2} sm={2} md={2} lg={4} xl={3} className="px-0 px-md-2">
              <Link to="/" className="navbar-brand">
                {/* {props.adminData && (
                  <img
                    src={
                      `${API_URL}var/assets` +
                      props.adminData.mobileLogo.fullpath
                    }
                    alt="This is DMG"
                  />
                )} */}
                <img src={EUT_logo} alt="conti-Logo" />
              </Link>
            </Col>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {props.isUserAuthenticated ? (
            <div className="logo-wrapper">
              <Link to="/dashboard" className="navbar-brand main-logo">
                {/* {props.adminData && (
                  <img
                    src={
                      `${API_URL}var/assets` +
                      props.adminData.desktopLogo.fullpath
                    }
                    alt="Kislev"
                  />
                )} */}
                <img src={EUT_logo} alt="conti-Logo" />
              </Link>
            </div>
          ) : (
            <Col
              xs={5}
              sm={3}
              md={2}
              lg={4}
              xl={6}
              className="px-0 px-md-2 d-none d-lg-inline-block"
            >
              {/* {(location.pathname === "/brand" ||
                location.pathname === "/sales" ||
                location.pathname === "/tiretips" ||
                location.pathname === "/history" ||
                location.pathname === "/rnd" ||
                location.pathname === "/morethentire" ||
                location.pathname === "/testwinner" ||
                location.pathname === "/madeingermany" ||
                location.pathname === "/no-1-in-OE" ||
                location.pathname === "/safety" ||
                location.pathname === "/tiremaintenance" ||
                location.pathname === "/tiretechnology") && (
                <Link to="/brand">
                  <div className="logo-conti">
                    <img src={EUT_logo} alt="conti-Logo" />
                  </div>
                </Link>
              )} */}
              <Link to="/" className="navbar-brand main-logo">
                {props.adminData && (
                  // <img
                  //   src={
                  //     `${API_URL}var/assets` +
                  //     props.adminData.desktopLogo.fullpath
                  //   }
                  //   alt="This is DMG"
                  // />
                  <img src={EUT_logo} alt="conti-Logo" />
                )}
              </Link>
            </Col>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
export default Logo;
