export const COLLAPSE_FILTER = "COLLAPSE_FILTER";
export const FILTER_TOGGLE = "FILTER_TOGGLE";
export const USERNAV_TOGGLE = "USERNAV_TOGGLE";
export const LAYOUT_ERROR = "LAYOUT_ERROR";
export const GET_FEATURE_PRODUCT = "GET_FEATURE_PRODUCT";
export const GET_FEATURE_PRODUCT_ERROR = "GET_FEATURE_PRODUCT_ERROR";
export const DEFAUL_LANGUAGE = "DEFAUL_LANGUAGE";
export const GET_ADMIN_DATA = "GET_ADMIN_DATA";
export const GET_ADMIN_DATA_ERROR = "GET_ADMIN_DATA_ERROR";
export const SET_LANGUAGE_DIRECTION = "SET_LANGUAGE_DIRECTION";
export const CLEAR_LAYOUT_DATA = "CLEAR_LAYOUT_DATA";

// User Details
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_CREDIT = "GET_USER_CREDIT";
export const GET_USER_DEFERRED_CHEQ = "GET_USER_DEFERRED_CHEQ";
export const GET_USER_NOTIFICATION_SETTING = "GET_USER_NOTIFICATION_SETTING";
export const GET_USER_NOTIFICATION = "GET_USER_NOTIFICATION";
export const LOADMORE_NOTIFICATION = "LOADMORE_NOTIFICATION";
export const GET_USER_STOCK = "GET_USER_STOCK";
export const GET_USER_COMPLAINT = "GET_USER_COMPLAINT";
export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const GET_USER_ORDERS_ERROR = "GET_USER_ORDERS_ERROR";
export const CREATE_USER_COMPLAINT = "CREATE_USER_COMPLAINT";
export const CREATE_USER_COMPLAINT_ERROR = "CREATE_USER_COMPLAINT_ERROR";
export const SAVE_PROFILE = "SAVE_PROFILE";
export const SAVE_PROFILE_ERROR = "SAVE_PROFILE_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const VERIFY_PASSWORD_ERROR = "VERIFY_PASSWORD_ERROR";
export const CANCEL_USER_COMPLAINT = "CANCEL_USER_COMPLAINT";
export const CANCEL_USER_COMPLAINT_ERROR = "CANCEL_USER_COMPLAINT_ERROR";
export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const GET_TIRELIST = "GET_TIRELIST";
export const GET_TIRELIST_ERROR = "GET_TIRELIST_ERROR";
export const UPDATE_USER_NOTIFICATION_SETTING =
  "UPDATE_USER_NOTIFICATION_SETTING";
export const UPDATE_USER_NOTIFICATION_SETTING_ERROR =
  "UPDATE_USER_NOTIFICATION_SETTING_ERROR";
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const CREATE_USER_NOTIFICATION_SETTING =
  "CREATE_USER_NOTIFICATION_SETTING";
export const CREATE_USER_NOTIFICATION_SETTING_ERROR =
  "CREATE_USER_NOTIFICATION_SETTING_ERROR";
export const UPDATE_USER_NOTIFICATION = "UPDATE_USER_NOTIFICATION";
export const UPDATE_USER_NOTIFICATION_ERROR = "UPDATE_USER_NOTIFICATION_ERROR";
export const UPDATE_RECENTLY_VIEWED_PRODUCT_QUANTITY =
  "UPDATE_RECENTLY_VIEWED_PRODUCT_QUANTITY";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const UPDATE_USER_EMAIL_ERROR = "UPDATE_USER_EMAIL_ERROR";
export const UPDATE_USER_PHONENUMBER = "UPDATE_USER_PHONENUMBER";
export const UPDATE_USER_PHONENUMBER_ERROR = "UPDATE_USER_PHONENUMBER_ERROR";
export const GET_TARGET_INFO = "GET_TARGET_INFO";
export const GET_TARGET_INFO_ERROR = "GET_TARGET_INFO_ERROR";

// Product Page
export const GET_PRODUCT_DETAIL_ERROR = "GET_PRODUCT_DETAIL_ERROR";
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";
export const GET_PRODUCT_IMAGE = "GET_PRODUCT_IMAGE";
export const GET_PRODUCT_IMAGE_ERROR = "GET_PRODUCT_IMAGE_ERROR";
export const GET_PRODUCT_FEATURE = "GET_PRODUCT_FEATURE";
export const GET_PRODUCT_TECH = "GET_PRODUCT_TECH";
export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
export const GET_PRODUCT_EULABELS = "GET_PRODUCT_EULABELS";
export const GET_FILTER_PRODUCT = "GET_FILTER_PRODUCT";
export const GET_FILTER_PRODUCT_ERROR = "GET_FILTER_PRODUCT_ERROR";
export const GET_TIRE_DETAIL = "GET_TIRE_DETAIL";
export const GET_PRODUCT_PRESS = "GET_PRODUCT_PRESS";
export const SAVE_FEEDBACK = "SAVE_FEEDBACK";
export const CLEAR_PRODUCT_DATA = "CLEAR_PRODUCT_DATA";
export const GET_MODAL_PRODUCT_DETAIL = "GET_MODAL_PRODUCT_DETAIL";
export const GET_PRODUCT_DETAIL_ID = "GET_PRODUCT_DETAIL_ID";
export const TOGGLE_MODAL_PRODUCT_DETAIL = "TOGGLE_MODAL_PRODUCT_DETAIL";
export const SET_PRODUCT_DETAIL_ID = "SET_PRODUCT_DETAIL_ID";
export const GET_PRODUCT_DETAIL_ALL_DATA = "GET_PRODUCT_DETAIL_ALL_DATA";

// Login
export const LOGIN = "LOGIN";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const IS_USER_AUTHENTICATED = "IS_USER_AUTHENTICATED";
export const PROGRAM_ACTIVE_STATE = "PROGRAM_ACTIVE_STATE";
export const PROGRAM_CONFIG_ACTIVE_STATE = "PROGRAM_CONFIG_ACTIVE_STATE";
export const ON_LOGOUT = "ON_LOGOUT";
export const VERIFY_USER_EMAIL = "VERIFY_USER_EMAIL";
export const VERIFY_USER_EMAIL_ERROR = "VERIFY_USER_EMAIL_ERROR";
export const RESET_USER_EMAIL = "RESET_USER_EMAIL";
export const CLEAR_AUTH_DATA = "CLEAR_AUTH_DATA";

// Cart
export const GET_CART_DETAIL = "GET_CART_DETAIL";
export const GET_CART_DETAIL_ERROR = "GET_CART_DETAIL_ERROR";
export const CREATE_CART = "CREATE_CART";
export const CREATE_CART_ERROR = "CREATE_CART_ERROR";
export const CART_ITEMS = "CART_ITEMS";
export const CART_ITEM_ERROR = "CART_ITEM_ERROR";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const UPDATE_CART_ERROR = "UPDATE_CART_ERROR";
export const CLEAR_UPDATEDITEM = "CLEAR_UPDATEDITEM";
export const GENERATE_ORDER_ITEMNUMBER = "GENERATE_ORDER_ITEMNUMBER";
export const GENERATE_ORDER_ITEMNUMBER_ERROR =
  "GENERATE_ORDER_ITEMNUMBER_ERROR";
export const GENERATE_INVOICE_NUMBER = "GENERATE_INVOICE_NUMBER";
export const GENERATE_INVOICE_NUMBER_ERROR = "GENERATE_INVOICE_NUMBER_ERROR";
export const GENERATE_ORDER_NUMBER = "GENERATE_ORDER_NUMBER";
export const GENERATE_ORDER_NUMBER_ERROR = "GENERATE_ORDER_NUMBER_ERROR";
export const CONFIRM_ORDER = "CONFIRM_ORDER";
export const CONFIRM_ORDER_ERROR = "CONFIRM_ORDER_ERROR";
export const CONFIRM_CALLOFF = "CONFIRM_CALLOFF";
export const CONFIRM_CALLOFF_ERROR = "CONFIRM_CALLOFF_ERROR";
export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_ERROR = "DELETE_ITEM_ERROR";
export const GET_PROMOS = "GET_PROMOS";
export const GET_PROMOS_ERROR = "GET_PROMOS_ERROR";
export const CREATE_ORDER_STATUS = "CREATE_ORDER_STATUS";
export const STATUS_ERROR = "STATUS_ERROR";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_ERROR = "CREATE_INVOICE_ERROR";
export const SHIPPING_ADDRESS = "SHIPPING_ADDRESS";
export const SHIPPING_ADDRESS_ERROR = "SHIPPING_ADDRESS_ERROR";
export const PROMO_VALIDATE = "PROMO_VALIDATE";
export const PROMO_VALIDATE_ERROR = "PROMO_VALIDATE_ERROR";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const SEND_MAIL = "SEND_MAIL";
export const SEND_MAIL_ERROR = "SEND_MAIL_ERROR";
export const UPDATE_ADD_TO_CART_QUANTITY = "UPDATE_ADD_TO_CART_QUANTITY";
export const UPDATE_RESERVED_STOCK_QUANTITY = "UPDATE_RESERVED_STOCK_QUANTITY";
export const UPDATE_FEATURE_PRODUCT_CART_QUANTITY =
  "UPDATE_FEATURE_PRODUCT_CART_QUANTITY";
export const GET_USER_CART_ID = "GET_USER_CART_ID";
export const GET_SHIPPING_ADDRESS = "GET_SHIPPING_ADDRESS";
export const GET_SHIPPING_ADDRESS_ERROR = "GET_SHIPPING_ADDRESS_ERROR";
export const GET_SHIPPING_ADDRESS_2 = "GET_SHIPPING_ADDRESS_2";
export const GET_SHIPPING_ADDRESS_2_ERROR = "GET_SHIPPING_ADDRESS_ERROR_2";
export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS";
export const UPDATE_CART_ITEM_DISABLE = "UPDATE_CART_ITEM_DISABLE";
export const CREATE_CART_ITEM = "CREATE_CART_ITEM";
export const ADD_CARTITEM_TO_CART = "ADD_CARTITEM_TO_CART";
export const UPDATE_RESERVED_STOCK = "UPDATE_RESERVED_STOCK";
export const UPDATE_RESERVED_STOCK_ERROR = "UPDATE_RESERVED_STOCK_ERROR";

// Search & Filters
export const LIST_PRODUCT = "LIST_PRODUCT";
export const LIST_PRODUCT_ERROR = "LIST_PRODUCT_ERROR";
export const FILTER_OPTIONS = "FILTER_OPTIONS";
export const FILTER_OPTIONS_ERROR = "FILTER_OPTIONS_ERROR";
export const FILTER_PRODUCT = "FILTER_PRODUCT";
export const FILTER_PRODUCT_ERROR = "FILTER_PRODUCT_ERROR";
export const UPDATE_FILTER_OPTIONS = "UPDATE_FILTER_OPTIONS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SEARCH_PRODUCTS_ERROR = "SEARCH_PRODUCTS_ERROR";
export const LOADMORE_PRODUCT = "LOADMORE_PRODUCT";
export const LOADMORE_PRODUCT_WITH_SEARCH = "LOADMORE_PRODUCT_WITH_SEARCH";
export const SELECT_WAREHOUSE = "SELECT_WAREHOUSE";

// export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const ADD_RECENT_VIEW = "ADD_RECENT_VIEW";
export const ADD_RECENT_VIEW_ERROR = "ADD_RECENT_VIEW_ERROR";
export const GET_RECENT_VIEW = "GET_RECENT_VIEW";
export const GET_RECENT_VIEW_ERROR = "GET_RECENT_VIEW_ERROR";

export const GET_PROMO_LIST = "GET_PROMO_LIST";
export const GET_PROMO_LIST_ERROR = "GET_PROMO_LIST_ERROR";
export const CLEARDATA = "CLEARDATA";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_ERROR = "CANCEL_ORDER_ERROR";
export const CLEAR_ORDER_DATA = "CLEAR_ORDER_DATA";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_ORDER_DETAILS_ERROR = "GET_ORDER_DETAILS_ERROR";

export const FILTER_ORDER = "FILTER_ORDER";

export const PAGE_CHANGE = "PAGE_CHANGE";

export const DEACTIVE_PROMO = "DEACTIVE_PROMO";
export const DEACTIVE_PROMO_ERROR = "DEACTIVE_PROMO_ERROR";
export const GET_FEEFBACK_QUESTION = "GET_FEEFBACK_QUESTION";
export const GET_FEEFBACK_QUESTION_ERROR = "GET_FEEFBACK_QUESTION_ERROR";
export const UPDATE_RESERVED_STOCK_ADD_TO_CART_QUANTITY =
  "UPDATE_RESERVED_STOCK_ADD_TO_CART_QUANTITY";
export const REMOVE_RESERVED_STOCK_QUANTITY = "REMOVE_RESERVED_STOCK_QUANTITY";
export const EXPORT_ORDER = "EXPORT_ORDER";
export const EXPORT_ORDER_ERROR = "EXPORT_ORDER_ERROR";
export const UPDATE_PRICE = "UPDATE_PRICE";
export const RECENT_UPDATE_PRICE = "RECENT_UPDATE_PRICE";
export const NORTIFY_ME = "NORTIFY_ME";
export const NORTIFY_ME_ERROR = "NORTIFY_ME_ERROR";
export const CLEAR_DATA = "CLEAR_DATA";
export const GET_USER_CALLOFFORDER = "GET_USER_CALLOFFORDER";
export const GET_USER_CALLOFFORDER_ERROR = "GET_USER_CALLOFFORDER_ERROR";
export const GET_CALLOFF_DETAILS = "GET_CALLOFF_DETAILS";
export const GET_CALLOFF_DETAILS_ERROR = "GET_CALLOFF_DETAILS_ERROR";
export const EXPORT_CALLOFF = "EXPORT_CALLOFF";
export const EXPORT_CALLOFF_ERROR = "EXPORT_CALLOFF_ERROR";
export const SEND_MAIL_CALLOFF = "SEND_MAIL_CALLOFF";
export const SEND_MAIL_CALLOFF_ERROR = "SEND_MAIL_CALLOFF_ERROR";
export const SHOW_SIMILER = "SHOW_SIMILER";
export const VERIYFY_VOUCHER = "VERIYFY_VOUCHER";
export const VERIYFY_VOUCHER_ERROR = "VERIYFY_VOUCHER_ERROR";
export const GET_PROMO_VOUCHER = "GET_PROMO_VOUCHER";
export const GET_PROMO_VOUCHER_ERROR = "GET_PROMO_VOUCHER_ERROR";
export const VALIDATE_USER_FOR_FIRSTTIME_PROMO =
  "VALIDATE_USER_FOR_FIRSTTIME_PROMO";
export const VALIDATE_USER_FOR_FIRSTTIME_PROMO_ERROR =
  "VALIDATE_USER_FOR_FIRSTTIME_PROMO_ERROR";
