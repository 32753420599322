import React, { Fragment, Suspense } from "react";
import { Navbar, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Logo from "../Logo";
import Search from "../Search";
import UserPanel from "../UserPanel";
import Filters from "./filtersSelection";
import { connect } from "react-redux";
import {
  onCollapseToggle,
  onCollapseUserNav,
} from "../../store/actions/layout";
// import Login from './login';
import ForgotPassword from "./forgotpassword";
import UserNav from "./usernav";
import { GET_USER_NOTIFICATION } from "../../store/actions/userDetail";
import Loader from "../Loader";

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    collapsefilter: state.Layout.collapsefilter,
    collapseUserNav: state.Layout.collapseUserNav,
    isUserAuthenticated: state.Auth.isUserAuthenticated,
    userNotification: state.UserDetail.userNotification,
    lang: state.Layout.defaultLanguage,
    adminData: state.Layout.adminData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCollapseToggle: (payload) => dispatch(onCollapseToggle(payload)),
    onCollapseUserNav: (payload) => dispatch(onCollapseUserNav(payload)),
    GET_USER_NOTIFICATION: (payload) =>
      dispatch(GET_USER_NOTIFICATION(payload)),
  };
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      forgotpassword: false,
      tireList: [],
      scroll: false,
    };
  }

  // componentDidMount(){

  //     if(this.props.isMobile === true){
  //         debugger
  //         this.showUserNav()
  //     }
  // }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userId, lang } = this.props;
    if (userId !== nextProps.userId && nextProps.userId !== undefined) {
      const payload = {
        userId: nextProps.userId,
        order: "id",
        lang: lang,
        orderkey: "desc",
        offset: 0,
      };
      this.props.GET_USER_NOTIFICATION(payload);
    }

    if (this.props.isMobile !== nextProps.isMobile) {
      this.showUserNav();
    }
    if (lang !== nextProps.lang && this.props.userId) {
      const payload = {
        userId: nextProps.userId,
        order: "id",
        lang: nextProps.lang,
        orderkey: "desc",
        offset: 0,
      };
      this.props.GET_USER_NOTIFICATION(payload);
    }
    if (
      window.location.pathname === "/products" &&
      this.props.isMobile === false
    ) {
      const payload = {
        collapseUserNav: true,
      };
      this.props.onCollapseUserNav(payload);
    }
    window.addEventListener("scroll", this.handleScroll);
  }

  showfilters = () => {
    const payload = {
      collapsefilter: !this.props.collapsefilter,
    };
    this.props.onCollapseToggle(payload);
  };
  onChangePagefilters = () => {
    const payload = {
      collapsefilter: false,
    };
    this.props.onCollapseToggle(payload);
  };
  showUserNav = () => {
    const payload = {
      collapseUserNav: !this.props.collapseUserNav,
    };
    this.props.onCollapseUserNav(payload);
  };
  onLogin = () => {
    this.setState({
      forgotpassword: false,
    });
  };
  onForgotPassword = () => {
    this.setState({
      showLogin: false,
      forgotpassword: !this.state.forgotpassword,
    });
  };

  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      this.setState({
        scroll: true,
      });
    } else {
      this.setState({
        scroll: false,
      });
    }
  };

  render() {
    let pathname = window.location.pathname;

    return (
      <Fragment>
        <Navbar
          color={this.props.isUserAuthenticated ? "" : "landing-page"}
          light
          expand="md"
          fixed="top"
          className={
            this.props.isUserAuthenticated
              ? "main-header bg-darker"
              : this.state.scroll
              ? "scrolledHeader bg-light"
              : "bg-light "
          }
        >
          {this.props.isUserAuthenticated ? (
            <Fragment>
              <Logo
                showfilters={this.showfilters}
                showUserNav={this.showUserNav}
                collapsefilter={this.props.collapsefilter}
                collapseUserNav={this.props.collapseUserNav}
                isUserAuthenticated={this.props.isUserAuthenticated}
                isMobile={this.props.isMobile}
                adminData={this.props.adminData}
              />

              <Suspense fallback={<Loader />}>
                <UserPanel
                  userId={this.props.userId}
                  lang={this.props.lang}
                  isMobile={this.props.isMobile}
                  isUserAuthenticated={this.props.isUserAuthenticated}
                  onLogin={this.onLogin}
                  isLogout={this.props.isLogout}
                  userNotification={this.props.userNotification}
                  changeLanguage={this.props.changeLanguage}
                  callModalCloseHandler={this.props.callModalCloseHandler}
                  giveFeedbackModalClose={this.props.giveFeedbackModalClose}
                />
              </Suspense>
            </Fragment>
          ) : (
            <div
              className={
                pathname === "/brand" ||
                pathname === "/sales" ||
                pathname === "/tiretips" ||
                pathname === "/history" ||
                pathname === "/rnd" ||
                pathname === "/morethentire" ||
                pathname === "/testwinner" ||
                pathname === "/madeingermany" ||
                pathname === "/no-1-in-OE" ||
                pathname === "/safety" ||
                pathname === "/tiremaintenance" ||
                pathname === "/tiretechnology"
                  ? "navWapperWithBrand"
                  : "navWapper"
              }
            >
              <Logo
                showfilters={this.showfilters}
                showUserNav={this.showUserNav}
                collapsefilter={this.props.collapsefilter}
                collapseUserNav={this.props.collapseUserNav}
                isUserAuthenticated={this.props.isUserAuthenticated}
                isMobile={this.props.isMobile}
                adminData={this.props.adminData}
              />
              {
                this.props.isUserAuthenticated ? (
                  <Suspense fallback={<Loader />}>
                    <Search
                      isMobile={this.props.isMobile}
                      changeLanguage={this.props.changeLanguage}
                    />
                  </Suspense>
                ) : null
                // <Suspense fallback={<Loader />}>
                //     <LanguageChange isMobile={this.props.isMobile} changeLanguage={this.props.changeLanguage} />
                // </Suspense>
              }
              <Suspense fallback={<Loader />}>
                <UserPanel
                  isMobile={this.props.isMobile}
                  isUserAuthenticated={this.props.isUserAuthenticated}
                  onLogin={this.onLogin}
                  isLogout={this.props.isLogout}
                  userNotification={this.props.userNotification}
                  changeLanguage={this.props.changeLanguage}
                  callModalCloseHandler={this.props.callModalCloseHandler}
                  giveFeedbackModalClose={this.props.giveFeedbackModalClose}
                />
              </Suspense>
            </div>
          )}
        </Navbar>
        <Suspense fallback={<Loader />}>
          <Filters
            showfilters={this.showfilters}
            onChangePage={this.onChangePagefilters}
            collapsefilter={this.props.collapsefilter}
          />
        </Suspense>

        {this.props.location.pathname === "/profile" ||
        this.props.location.pathname === "/security" ||
        this.props.location.pathname === "/accounting" ||
        this.props.location.pathname === "/notificationsetting" ||
        this.props.location.pathname === "/complaints" ? (
          <Suspense fallback={<Loader />}>
            <UserNav
              showUserNav={this.showUserNav}
              collapseUserNav={this.props.collapseUserNav}
              isLogout={this.props.isLogout}
              isMobile={this.props.isMobile}
            />
          </Suspense>
        ) : null}
        {/* {this.state.showLogin && !this.props.isUserAuthenticated &&
                    <Suspense fallback={<Loader />}>
                        <Login onForgotPassword={this.onForgotPassword} />
                    </Suspense>
                } */}
        <Suspense fallback={<Loader />}>
          <ForgotPassword
            forgotpassword={this.state.forgotpassword}
            onLogin={this.onLogin}
          />
        </Suspense>
      </Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
