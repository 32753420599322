import axios from 'axios'
import {API_URL} from '../constents/helpers'

// const loginInterface = {
//     'username': 'username',
//     'password' : 'password'
// }

// const getLoginParams = ({username,password}) => {
//     return {
//         [loginInterface['username']]: username,
//         [loginInterface['password']] : password
//     }
// }


const Storage = window.localStorage
const SessionStorage = window.sessionStorage
class Auth {
    static _data
    static user_id   //We can have userId from cognitoTokens.
    static isUserAuthenticated
    
    static _clear() {
        this._data = undefined
        this.user_id=undefined
        this.isUserAuthenticated = undefined
        this.username = undefined
        
    }

    static initialize() {
        try {
            const data = JSON.parse(atob(Storage.getItem('data')))
            this.isUserAuthenticated = data['isUserAuthenticated']
            this.user_id = data['user_id']
            this._data = data
            return ({isUserAuthenticated: this.isUserAuthenticated , userId: this.user_id})
        } catch (e) {
            return undefined
        }
    }
    // static _setDataFromJWTToken(token) {
    //     const payload = JSON.parse(atob(token.split('.')[1]))
    //     // this.userId = payload['custom:userId']
    //     this.username = payload['cognito:username']
    //     this.isUserAuthenticated = true
    //     this.userPoolId = payload['iss'].split('/')[3]
    // }

    static async loginWithUserNameAndPassword(args) {
        try { 
            var bodyFormData = new FormData();
            bodyFormData.set('username', `${args.username}`);
            bodyFormData.set('password', `${args.password}`);
            bodyFormData.set('lang', `${args.lang}`); 
            let axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            }
            
            const LOGIN_URL = `${API_URL}login/`

            const { data } = await axios.post(LOGIN_URL, bodyFormData, axiosConfig)
            // console.log(data)
            // const {data} = await axios({
            //     method: 'post',
            //     url: `https://backend.beta.contiimport.nextdigitalmarketing.com/login/`,
            //     data: getLoginParams(args),
            // })
            
            return this.afterLogin(data)                                                                                                                                                                      
            

        } catch(e) {
            
            const response = e.response;
            if (response) {
                const data = response.data
                if(data) {
                    const message = data.details
                    // console.log(message);
                    throw new Error(message)
                } else {
                    throw new Error('Server Error')
                }
            } else {
                throw new Error('Server Error')
            }
        }
    }

    static logout() {
        this._clear()
        Storage.removeItem('data')
        SessionStorage.removeItem('cart')
        SessionStorage.removeItem('cartId')
       
    }

    static get data() {
        if(!this._data) {
            this.initialize()
        }
        return this._data
    }
    
    //setTokens after LogIn
    static afterLogin(data) {
        const username = data['user_username']
        const user_id = data['user_id']
        const isUserAuthenticated = true

        this.user_id = user_id
        this.username = username
        this.isUserAuthenticated = isUserAuthenticated

        const authData = {
            username,
            user_id,
            isUserAuthenticated 
        }
        // this._setDataFromJWTToken(token)

        Storage.setItem('data',btoa(JSON.stringify(authData)))
        return {
            user_id,
            isUserAuthenticated
        }
    }

}

export default Auth;