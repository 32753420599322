import React, { Fragment, lazy, Suspense } from "react";
// import PageTitle from './PageTitle';
// import Filters from './filters';
import { Container } from "reactstrap";
import Header from "../Header";
// import ListProduct from '../../Components/ListPrducts';
// import FeatureProduct from '../../Components/FeatureProduct';
import { connect } from "react-redux";
// import ProductDetails from '../../Components/ProductDetails';
import { isMobileOnly as isMobile, isTablet } from "react-device-detect";
import { Redirect, Route, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Auth from "../../models/Auth";
import { CLEAR_AUTH_DATA, LOGOUT, SET_IS_USER_AUTHENTICATED } from "../../store/actions/auth";
import { CLEAR_DATA } from "../../store/actions/cart";
import {
  CLEAR_LAYOUT_DATA,
  DEFAULT_LANGUAGE,
  GET_ADMIN_DATA,
  onCollapseUserNav,
  setLanguageDirection,
} from "../../store/actions/layout";
import { CLEAR_PRODUCT_DATA, GET_FILTER_PRODUCT } from "../../store/actions/products";
import { CLEAR_USER_DATA, GET_USER_CALLOFFORDER, GET_USER_ORDERS } from "../../store/actions/userDetail";
import { GET_ADMIN_DATA_QUERY } from "../../store/queries/settings";
import Loader from "../Loader";
import Onboarding from "../../Components/Onboarding";
import ResetPasswords from "../../Components/ResetPasswords";
import Register from "../../Components/Register";
import RegisterSuccess from "../../Components/Register/success";
// import CallModal from '../../Components/CallModal/CallModal';
const Storage = window.localStorage;

const Promotions = lazy(() => import("../../Components/Promotions"));
const Cart = lazy(() => import("../../Components/Cart"));
const Products = lazy(() => import("../../Components/Products/product"));
const Home = lazy(() => import("../../Components/Home/index"));
const UserDetails = lazy(() => import("../../Components/UserDetails"));
const About = lazy(() => import("../../Components/About"));
const CallModal = lazy(() => import("../../Components/CallModal/CallModal"));
const Newproducts = lazy(() => import("../../Components/Products/product"));
const ReservedStock = lazy(() => import("../../Components/ReservedStock"));
const Dashboard = lazy(() => import("../../Components/Dashboard"));
// const GiveFeedbackModal = lazy(() =>
//   import("../../Components/GiveFeedbackModal/GiveFeedbackModal")
// );

const Orders = lazy(() => import("../../Components/Orders"));
const BrandLanding = lazy(() => import("../../Components/BrandLanding"));

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    collapsefilter: state.Layout.collapsefilter,
    collapseUserNav: state.Layout.collapseUserNav,
    isUserAuthenticated: state.Auth.isUserAuthenticated,
    userId: state.Auth.userId,
    closeSearchBox: state.Layout.closeSearchBox,
    lang: state.Layout.defaultLanguage,
    mailsend: state.Cart.mailsend,
  };
};

const mapDispatchToProps = {
  SET_IS_USER_AUTHENTICATED: () => SET_IS_USER_AUTHENTICATED(),
  LOGOUT: () => LOGOUT(),
  onCollapseUserNav: (payload) => onCollapseUserNav(payload),
  DEFAULT_LANGUAGE: (payload) => DEFAULT_LANGUAGE(payload),
  GET_ADMIN_DATA: (payload) => GET_ADMIN_DATA(payload),
  setLanguageDirection: (payload) => setLanguageDirection(payload),
  GET_USER_ORDERS: (query) => GET_USER_ORDERS(query),
  GET_USER_CALLOFFORDER: (query) => GET_USER_CALLOFFORDER(query),
  CLEAR_DATA: () => CLEAR_DATA(),
  CLEAR_AUTH_DATA: () => CLEAR_AUTH_DATA(),
  CLEAR_LAYOUT_DATA: () => CLEAR_LAYOUT_DATA(),
  CLEAR_USER_DATA: () => CLEAR_USER_DATA(),
  CLEAR_PRODUCT_DATA: () => CLEAR_PRODUCT_DATA(),
  GET_FILTER_PRODUCT: () => GET_FILTER_PRODUCT(),
  // FILTER_PRODUCT: (payload) => FILTER_PRODUCT(payload),
};
let pathname = window.location.pathname;
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isMobile: false,
      productId: null,
      isLoading: false,
      redirect: false,
      callModalToggle: false,
      giveFeedbackModalToggle: false,
    };
  }

  componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize);
    this.props.DEFAULT_LANGUAGE(this.props.lang);
    this.setLanguageDirectionHandler(this.props.lang);
    if (!this.props.isUserAuthenticated) {
      if (
        pathname === "/home" ||
        pathname === "/products" ||
        pathname === "/cart" ||
        pathname === "/promotions" ||
        pathname === "/notification" ||
        pathname === "/profile" ||
        pathname === "/dashboard" ||
        pathname === "/orders" ||
        pathname === "/security" ||
        pathname === "/accounting" ||
        pathname === "/notificationsetting" ||
        pathname === "/reservedstock" ||
        pathname === "/complaints" ||
        pathname === "/newproducts" ||
        pathname === "/reservedstock-2" ||
        pathname === "/brand" ||
        pathname === "/sales" ||
        pathname === "/tiretips" ||
        pathname === "/history" ||
        pathname === "/rnd" ||
        pathname === "/morethentire" ||
        pathname === "/testwinner" ||
        pathname === "/madeingermany" ||
        pathname === "/no-1-in-OE" ||
        pathname === "/safety" ||
        pathname === "/tiremaintenance" ||
        pathname === "/tiretechnology"
      ) {
        if (!Auth.data) {
          this.props.LOGOUT();
          this.moveToLogin();
        } else if (pathname.startsWith("/auth/onboarding") ||
          pathname.startsWith("/register") ||
          pathname.startsWith("/register/success") ||
          pathname.startsWith("/auth/reset-password")
        ) {
          // do nothing
        } else {
          this.props.SET_IS_USER_AUTHENTICATED();
        }
      }
    }
    this.props.GET_ADMIN_DATA({
      query: GET_ADMIN_DATA_QUERY(this.props.lang),
    });
    window.addEventListener("keyup", this.escapeToProductsPage.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setLanguageDirectionHandler(this.props.lang);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { lang, userId, mailsend } = this.props;
    if (lang !== nextProps.lang) {
      this.props.GET_ADMIN_DATA({
        query: GET_ADMIN_DATA_QUERY(nextProps.lang),
      });
    }

    if (window.innerWidth >= 768 && window.innerWidth <= 960 && this.state.isMobile !== isTablet) {
      this.resize();
    }
    if (userId !== nextProps.userId && nextProps.userId !== undefined) {
      //   this.setState({ isLoading: true })
      // this.props.GET_USER_ORDERS(GET_USER_ORDERS_QUERY(nextProps.userId));
      //   this.setState({ isLoading: true })
      // this.props.GET_USER_CALLOFFORDER(GET_USER_CALLOFFORDER_QUERY(nextProps.userId));
      // this.props.GET_FILTER_PRODUCT();
    }

    if (mailsend !== nextProps.mailsend && nextProps.mailsend !== undefined) {
      // this.props.GET_USER_ORDERS(GET_USER_ORDERS_QUERY(nextProps.userId));
    }

    if (userId !== nextProps.userId && nextProps.userId === undefined) {
      window.location.reload();
    }

    // if(userOrders !== nextProps.userOrders){
    //     this.setState({ isLoading: false })
    // }
    // if(userCalloff !== nextProps.userCalloff){
    //     this.setState({ isLoading: false })
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.escapeToProductsPage);
  }

  escapeToProductsPage(e) {
    if (
      e.key === "Escape" &&
      this.props &&
      this.props.isUserAuthenticated &&
      window.location.pathname !== "/products"
    ) {
      this.props.history.push("/products");
    }
  }

  resize = () => {
    if (window.innerWidth >= 768 && window.innerWidth <= 960) {
      this.setState({
        isMobile: true,
      });
    }
    if (this.state.isMobile !== isMobile) {
      this.setState({
        isMobile: isMobile,
      });
    }
  };

  moveToLogin = () => {
    this.props.history.push("/home");
    Storage.removeItem("data");
    Storage.removeItem("rememberData");
  };

  isLogout = () => {
    this.props.LOGOUT();
    const payload = {
      collapseUserNav: false,
    };
    // let removefromFilters = {}
    this.props.onCollapseUserNav(payload);
    this.props.CLEAR_DATA();
    this.props.CLEAR_AUTH_DATA();
    this.props.CLEAR_LAYOUT_DATA();
    this.props.CLEAR_PRODUCT_DATA();
    this.props.CLEAR_USER_DATA();
    // this.props.FILTER_PRODUCT(removefromFilters)

    this.props.history.push("/home");
  };

  callModalCloseHandler = () => {
    this.setState({
      callModalToggle: !this.state.callModalToggle,
    });
  };
  giveFeedbackModalClose = () => {
    this.setState({
      giveFeedbackModalToggle: !this.state.giveFeedbackModalToggle,
    });
  };

  setLanguageDirectionHandler = (lang) => {
    let languageDirection = "";
    if (lang === "he") {
      languageDirection = "rtl";
    }
    if (lang === "en") {
      languageDirection = "ltr";
    }
    if (lang === "de") {
      languageDirection = "ltr";
    }
    this.props.setLanguageDirection(languageDirection);
  };

  render() {
    const { collapsefilter, isUserAuthenticated, collapseUserNav, changeLanguage } = this.props;

    // console.log(collapsefilter)
    return (
      <Fragment>
        {this.state.isLoading && <Loader />}
        <Header
          isMobile={this.state.isMobile}
          isLogout={this.isLogout}
          userId={this.props.userId}
          changeLanguage={(lang) => {
            changeLanguage(lang);
          }}
          callModalCloseHandler={this.callModalCloseHandler}
          giveFeedbackModalClose={this.giveFeedbackModalClose}
        />
        <Container
          className={
            !isUserAuthenticated
              ? "loginpage"
              : collapsefilter ||
                (collapseUserNav &&
                  (window.location.pathname === "/profile" ||
                    window.location.pathname === "/security" ||
                    window.location.pathname === "/accounting" ||
                    window.location.pathname === "/notificationsetting" ||
                    window.location.pathname === "/complaints"))
                ? "filter-on main-container"
                : "main-container"
          }
        >
          <Suspense fallback={<Loader />}>
            <Route
              exact
              path="/"
              render={() => {
                return !isUserAuthenticated ? <Redirect to="/home" /> : <Redirect to="/products" />;
              }}
            />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/about" component={About} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/home" component={Home} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/auth/onboarding/:token" component={Onboarding} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/register" exact component={Register} /></Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/register/success" component={RegisterSuccess} /></Suspense>
          <Suspense fallback={<Loader />}>
            <Route
              path="/auth/reset-password/:token"
              component={ResetPasswords}
            />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/promotions" component={Promotions} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/cart" component={() => <Cart isMobile={this.state.isMobile} />} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route path="/dashboard" component={Dashboard} />
          </Suspense>

          <Suspense fallback={<Loader />}>
            <Route
              path="/products"
              component={() => (
                <div className="product-page" style={this.props.closeSearchBox ? { paddingTop: "3rem" } : null}>
                  <Products modalToggle={this.modalToggle} isMobile={this.state.isMobile} />{" "}
                </div>
              )}
            />
          </Suspense>

          <Suspense fallback={<Loader />}>
            <Route
              path="/newproducts"
              component={() => (
                <div className="product-page" style={this.props.closeSearchBox ? { paddingTop: "3rem" } : null}>
                  <Newproducts modalToggle={this.modalToggle} isMobile={this.state.isMobile} />{" "}
                </div>
              )}
            />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Route
              path="/reservedstock"
              component={() => (
                <div className="product-page" style={this.props.closeSearchBox ? { paddingTop: "3rem" } : null}>
                  <ReservedStock modalToggle={this.modalToggle} isMobile={this.state.isMobile} />{" "}
                </div>
              )}
            />
          </Suspense>

          <Suspense fallback={<Loader />}>
            <Route path="/profile" component={() => <UserDetails isMobile={this.state.isMobile} />} />
            <Route path="/security" component={() => <UserDetails isMobile={this.state.isMobile} />} />
            <Route path="/accounting" component={() => <UserDetails isMobile={this.state.isMobile} />} />
            <Route path="/notificationsetting" component={() => <UserDetails isMobile={this.state.isMobile} />} />
            <Route path="/notification" component={() => <UserDetails isMobile={this.state.isMobile} />} />
            <Route path="/complaints" component={() => <UserDetails isMobile={this.state.isMobile} />} />
          </Suspense>

          <Suspense fallback={<Loader />}>
            <Route path="/orders" component={() => <Orders isMobile={this.state.isMobile} />} />
          </Suspense>
        </Container>
        <Suspense fallback={<Loader />}>
          <Route path="/brand" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/history" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/rnd" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/morethentire" component={() => <BrandLanding isMobile={this.state.isMobile} />} />

          <Route path="/sales" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/testwinner" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/madeingermany" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/no-1-in-OE" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/tiretips" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/safety" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/tiremaintenance" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
          <Route path="/tiretechnology" component={() => <BrandLanding isMobile={this.state.isMobile} />} />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <CallModal
            callModalToggle={this.state.callModalToggle}
            callModalClose={() => {
              this.callModalCloseHandler();
            }}
          />
        </Suspense>
        {/* <Suspense fallback={<Loader />}>
          <GiveFeedbackModal
            giveFeedbackModalToggle={this.state.giveFeedbackModalToggle}
            giveFeedbackModalClose={this.giveFeedbackModalClose}
            feedbackDataSuccess={this.props.feedbackData}
          />
        </Suspense> */}
        <ToastContainer limit={1} />
      </Fragment>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
