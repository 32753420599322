import React from "react";
import ReactDOM from "react-dom";
import { register } from "./serviceWorkerRegistration";

import { BrowserRouter as Router } from "react-router-dom";
import "./scss/style.scss";

import Main from "./Layout/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { API_URL, API_REQUESTS } from "./constents/helpers";

import "./i18n";
import i18n from "./i18n";
const Storage = window.localStorage;

let lang = Storage.i18nextLng;
// console.log("----------------------This is my lang: ", lang)
let dir;

const changeLanguage = (lng) => {
  // debugger
  if (lng === "he") {
    dir = "rtl";
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
  if (lng === "en" || lng === "de") {
    dir = "ltr";
    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    // dir = "ltr"
  }
  i18n.changeLanguage(lng);
};

// Instantiate required constructor fields
const cache = new InMemoryCache();
const link = new createHttpLink({
  uri: `${API_URL}${API_REQUESTS.PRODUCTS}`,
});

const client = new ApolloClient({
  link: link,
  cache: cache,
});

const browserLanguage = navigator.language;
// debugger
if (browserLanguage.includes("en")) {
  dir = "ltr";
  lang = "en";
  document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  i18n.changeLanguage("en");
}
if (browserLanguage.includes("he")) {
  dir = "rtl";
  lang = "he";
  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  i18n.changeLanguage("he");
}
if (browserLanguage.includes("de")) {
  dir = "ltr";
  lang = "de";
  document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  i18n.changeLanguage("de");
}

const store = configureStore();

const app = (
  <Router>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Main changeLanguage={changeLanguage} languageDirection={dir} lang={lang} />
      </Provider>
    </ApolloProvider>
  </Router>
);

ReactDOM.render(app, document.getElementById("root"));
document.getElementsByTagName("html")[0].setAttribute("dir", dir);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register();
