import {
  Card,
  CardBody,
  CardHeader,
  Input,
  FormGroup,
  Label,
  Button,
  Alert,
} from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { API_URL } from "../../constents/helpers";
import { connect } from "react-redux";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import showicon from "../../assets/eye.svg";
import Axios from "../../models/Axios";
import * as Joi from "joi";

const mapStateToProps = (state) => {
  return {
    adminData: state.Layout.adminData,
    lang: state.Layout.defaultLanguage,
  };
};

function RegisterSuccess(props) {
  const { t } = useTranslation();

  return (
    <div className="user-register">
      <Card className="main-card">
        <CardHeader>
          <Link to="/" className="navbar-brand main-logo">
            {props.adminData && (
              <img
                src={
                  `${API_URL}var/assets` + props.adminData.desktopLogo.fullpath
                }
                alt="Tirecloud"
              />
            )}
          </Link>
        </CardHeader>
        <CardBody>
          <div className="main-form">
            <h2>{t("register.successTitle")}</h2>
            <p>{t("register.successDescription")}</p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default connect(mapStateToProps)(RegisterSuccess);
