// eslint-disable-next-line no-unused-vars
import _ from "../constents/Axios";

class Axios {
    // eslint-disable-next-line getter-return
    static get axios() {
        // eslint-disable-next-line no-undef
        return _.getInstance()
    }
}

export default Axios;