import * as Types from "../../constents/actionTypes";
import Axios from "../../models/Axios";
import { v4 as uuidv4 } from "uuid";
import {
  API_URL,
  API_REQUESTS,
  IMPORT_URL,
  PARENTS_ID,
  DISPLAY_LIMIT,
} from "../../constents/helpers";
import moment from "moment";
import axios from "axios";
import { GET_USER_CART_ID_AND_FEATURED_PRODUCTS_QUERY } from "../queries/cart";

export const GET_CART_DETAIL = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: query,
    });
    if (response) {
      dispatch({
        type: Types.GET_CART_DETAIL,
        itemIncart: response.data.data.getCart.cartItem,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_CART_DETAIL_ERROR, error });
  }
};

export const UPDATE_RESERVED_STOCK = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      query
    );
    dispatch({
      type: Types.UPDATE_RESERVED_STOCK,
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: Types.UPDATE_RESERVED_STOCK_ERROR, error });
  }
};

export const CREATE_CART = (payload) => async (dispatch) => {
  try {
    // debugger
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: payload },
    });
    dispatch({
      type: Types.CREATE_CART,
      createdCartDetails: response.data.data.createCart.output,
    });
  } catch (error) {
    dispatch({ type: Types.CREATE_CART_ERROR, error });
  }
};

// export const CREATE_CARTITEM = (query)=>async (dispatch)=>{
//   try{
//       const response = await Axios.axios.post(`${API_URL}${API_REQUESTS.PRODUCTS}`, query)
//     dispatch({
//       type: Types.CREATE_CARTITEM,
//       updateCartDetails : response.data.data
//       })

//     }catch(error){
//       dispatch({
//         type:Types.CREATE_CARTITEM_ERROR, error
//       })
//     }
// }

export const UPDATE_CART = (query) => async (dispatch) => {
  try {
    //   const response = await Axios.axios({
    //     method: 'post',
    //     url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
    //     data: {query:query}

    // })
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.PRODUCTS}`,
      query
    );
    dispatch({
      type: Types.UPDATE_CART,
      updateCartDetails: response.data.data.updateCart.output.modificationDate,
    });
  } catch (error) {
    dispatch({
      type: Types.UPDATE_CART_ERROR,
      error,
    });
  }
};

// export const UPDATE_CART_NEW = (query)=>async (dispatch)=>{
//   try{
//     const response = await Axios.axios.post(`${API_URL}${API_REQUESTS.PRODUCTS}`, query)

//   dispatch({
//     type: Types.UPDATE_CART,
//     updateCartDetails : response.data.data
//     })

//   }catch(error){
//     dispatch({
//       type:Types.UPDATE_CART_ERROR, error
//     })
//   }
// }

export const LIST_PRODUCT = (payload) => async (dispatch) => {
  const { lang, userId, selectedFilters } = payload;
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}filtersearch/?limit=${DISPLAY_LIMIT}&offset=0&lang=${lang}&userId=${userId}&orderkey=stock&order=desc&onlyForSale=true`,
    });
    if (response) {
      dispatch({
        type: Types.LIST_PRODUCT,
        listProducts: response.data.tireSize,
        selectedFilters: selectedFilters,
        morelistEnd: false,
      });
    } else {
      dispatch({ type: Types.LIST_PRODUCT_ERROR, error: "No Product Found" });
    }
  } catch (error) {
    dispatch({ type: Types.LIST_PRODUCT_ERROR, error });
  }
};

export const LOADMORE_PRODUCT = (payload) => async (dispatch) => {
  try {
    // debugger
    const { offset, selectedFilters, lang, userId } = payload;
    let response = [];
    let filters = ``;
    if (selectedFilters) {
      const {
        width,
        height,
        inch,
        segment,
        load,
        speed,
        brand,
        promotion,
        orderkey,
        warehouse,
        order,
        searchData,
        Passenger,
        Trucks,
        Others,
      } = selectedFilters;
      if (offset) {
        filters = filters += `&offset=${offset}`;
      }
      if (searchData) {
        filters = filters += `&filter=${searchData}`;
      }
      if (width) {
        filters = filters += `&width=${width}`;
      }
      if (height) {
        filters = filters += `&height=${height}`;
      }
      if (inch) {
        filters = filters += `&inch=${inch}`;
      }
      if (segment) {
        filters = filters += `&segment=${segment}`;
      }
      if (load) {
        filters = filters += `&load=${load}`;
      }
      if (speed) {
        filters = filters += `&speed=${speed}`;
      }
      if (brand) {
        filters = filters += `&brand=${brand}`;
      }
      if (warehouse) {
        filters = filters += `&warehouse=${warehouse}`;
      }
      if (promotion) {
        if (promotion === 2) {
          filters = filters += `&promotion=0`;
        } else {
          filters = filters += `&promotion=1`;
        }
      }
      if (!promotion && orderkey) {
        filters = filters += `&orderkey=${orderkey}`;
      }
      if (!promotion && order) {
        filters = filters += `&order=${order}`;
      }
      if (Passenger || Trucks || Others) {
        let othersegment;

        if (Passenger) {
          othersegment = `&othersegment=1`;
        } else if (Trucks) {
          othersegment = `&othersegment=2`;
        } else if (Others) {
          othersegment = `&othersegment=3`;
        }

        if (Passenger && Trucks) {
          othersegment = `&othersegment=1,2`;
        } else if (Passenger && Others) {
          othersegment = `&othersegment=1,3`;
        } else if (Trucks && Others) {
          othersegment = `&othersegment=2,3`;
        }

        if (Passenger && Trucks && Others) {
          othersegment = `&othersegment=1,2,3`;
        }
        filters = filters += othersegment;
      }
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=${DISPLAY_LIMIT}&userId=${userId}&lang=${lang}${filters}`,
      });
    } else {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=${DISPLAY_LIMIT}&userId=${userId}&lang=${lang}&offset=${offset}&orderkey=stock&order=desc`,
      });
    }
    if (
      response.data &&
      response.data.tireSize &&
      response.data.tireSize.length > 0
    ) {
      dispatch({
        type: Types.LOADMORE_PRODUCT,
        moreProducts: response.data.tireSize,
      });
    } else {
      dispatch({
        type: Types.LIST_PRODUCT_ERROR,
        error: "No More Product Found",
      });
    }
  } catch (error) {
    dispatch({ type: Types.LIST_PRODUCT_ERROR, error });
  }
};

export const LOADMORE_PRODUCT_WITH_SEARCH = (payload) => async (dispatch) => {
  try {
    const { offset, searchData, lang, userId, warehouse } = payload;
    let response = [];
    let filters = ``;
    if (searchData) {
      if (offset) {
        filters = filters += `&offset=${offset}`;
      }
      if (searchData) {
        filters = filters += `&filter=${searchData}`;
      }
      if (warehouse) {
        filters = filters += `&warehouse=${warehouse}`;
      }
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=${DISPLAY_LIMIT}&userId=${userId}&lang=${lang}${filters}`,
      });
    } else {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=${DISPLAY_LIMIT}&userId=${userId}&lang=${lang}&offset=${offset}&orderkey=stock&order=desc`,
      });
    }
    if (
      response.data &&
      response.data.tireSize &&
      response.data.tireSize.length > 0
    ) {
      dispatch({
        type: Types.LOADMORE_PRODUCT_WITH_SEARCH,
        moreProducts2: response.data.tireSize,
      });
    } else {
      dispatch({
        type: Types.LIST_PRODUCT_ERROR,
        error: "No More Product Found",
      });
    }
  } catch (error) {
    dispatch({ type: Types.LIST_PRODUCT_ERROR, error });
  }
};

export const GET_FILTER_OPTIONS = (lang) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}filterdata/?lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.FILTER_OPTIONS,
        filterOptions: response.data.tireParameters,
      });
    }
  } catch (error) {
    dispatch({ type: Types.FILTER_OPTIONS_ERROR, error });
  }
};
export const UPDATE_FILTER_OPTIONS = (payload) => async (dispatch) => {
  try {
    const {
      searchData,
      warehouse,
      width,
      height,
      inch,
      load,
      speed,
      brand,
      segment,
      lang,
      Passenger,
      Trucks,
      Others,
    } = payload;
    let response = [];
    let filters = ``;
    if (payload) {
      if (searchData) {
        filters = filters += `filter=${searchData}&`;
      }
      if (warehouse) {
        filters = filters += `warehouse=${warehouse}&`;
      }
      if (lang) {
        filters = filters += `lang=${lang}`;
      }
      if (width) {
        filters = filters += `&width=${width}`;
      }
      if (height) {
        filters = filters += `&height=${height}`;
      }
      if (inch) {
        filters = filters += `&inch=${inch}`;
      }
      if (segment) {
        filters = filters += `&segment=${segment}`;
      }
      if (load) {
        filters = filters += `&load=${load}`;
      }
      if (speed) {
        filters = filters += `&speed=${speed}`;
      }
      if (brand) {
        filters = filters += `&brand=${brand}`;
      }
      if (Passenger || Trucks || Others) {
        let othersegment;
        if (Passenger) {
          othersegment = `&othersegment=1`;
        } else if (Trucks) {
          othersegment = `&othersegment=2`;
        } else if (Others) {
          othersegment = `&othersegment=3`;
        }

        if (Passenger && Trucks) {
          othersegment = `&othersegment=1,2`;
        } else if (Passenger && Others) {
          othersegment = `&othersegment=1,3`;
        } else if (Trucks && Others) {
          othersegment = `&othersegment=2,3`;
        }

        if (Passenger && Trucks && Others) {
          othersegment = `&othersegment=1,2,3`;
        }
        filters = filters += othersegment;
      }
    }
    // debugger
    response = await Axios.axios.get(`${API_URL}filterdata/?${filters}`);
    if (response) {
      dispatch({
        type: Types.UPDATE_FILTER_OPTIONS,
        updatedfilterOptions: response.data.tireParameters,
        selectedFilters: payload,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.FILTER_OPTIONS_ERROR,
      error,
    });
  }
};
export const ADD_RECENT_VIEW = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.PRODUCTS}`,
      query
    );
    if (response) {
      dispatch({
        type: Types.ADD_RECENT_VIEW,
        recentAdded: response.data.data.createRecentView.output.id,
      });
    }
  } catch (error) {
    dispatch({ type: Types.ADD_RECENT_VIEW_ERROR, error });
  }
};
export const GET_RECENT_VIEW = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.PRODUCTS}`,
      query
    );
    if (response) {
      dispatch({
        type: Types.GET_RECENT_VIEW,
        recentView: response.data.data.getUser.recentView,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_RECENT_VIEW_ERROR, error });
  }
};
export const GET_RECENT_VIEW_RESTAPI =
  ({ userId, lang, limit = 10, offset = 0 }) =>
  async (dispatch) => {
    try {
      const response = await Axios.axios.get(
        `${API_URL}api/v1/users/recent-view?lang=${lang}&userId=${userId}&limit=${limit}&offset=${offset}`
      );
      if (response) {
        dispatch({
          type: Types.GET_RECENT_VIEW,
          recentView: response.data.userRecentViews,
        });
      }
      // Dummy data
      // dispatch({
      //   type: Types.GET_RECENT_VIEW,
      //   recentView: dummyRecentlyViews,
      // });
    } catch (error) {
      dispatch({ type: Types.GET_RECENT_VIEW_ERROR, error });
    }
  };

export const FILTER_PRODUCT = (payload) => async (dispatch) => {
  try {
    // debugger
    const {
      width,
      height,
      inch,
      segment,
      load,
      speed,
      brand,
      promotion,
      orderkey,
      order,
      searchData,
      lang,
      Passenger,
      Trucks,
      Others,
      userId,
      warehouse,
    } = payload;
    let response = [];
    let filters = ``;
    if (payload) {
      if (userId) {
        filters = filters += `userId=${userId}`;
      }
      if (searchData) {
        filters = filters += `&filter=${searchData}`;
      }
      if (lang) {
        filters = filters += `&lang=${lang}`;
      }
      if (width) {
        filters = filters += `&width=${width}`;
      }
      if (height) {
        filters = filters += `&height=${height}`;
      }
      if (inch) {
        filters = filters += `&inch=${inch}`;
      }
      if (segment) {
        filters = filters += `&segment=${segment}`;
      }
      if (load) {
        filters = filters += `&load=${load}`;
      }
      if (speed) {
        filters = filters += `&speed=${speed}`;
      }
      if (brand) {
        filters = filters += `&brand=${brand}`;
      }
      if (warehouse) {
        filters = filters += `&warehouse=${warehouse}`;
      }
      if (promotion) {
        if (promotion === 2) {
          filters = filters += `&promotion=0`;
        } else {
          filters = filters += `&promotion=1`;
        }
      }
      if (!promotion && orderkey) {
        filters = filters += `&orderkey=${orderkey}`;
      }
      if (!promotion && order) {
        filters = filters += `&order=${order}`;
      }
      if (Passenger || Trucks || Others) {
        let othersegment;

        if (Passenger) {
          othersegment = `&othersegment=1`;
        } else if (Trucks) {
          othersegment = `&othersegment=2`;
        } else if (Others) {
          othersegment = `&othersegment=3`;
        }

        if (Passenger && Trucks) {
          othersegment = `&othersegment=1,2`;
        } else if (Passenger && Others) {
          othersegment = `&othersegment=1,3`;
        } else if (Trucks && Others) {
          othersegment = `&othersegment=2,3`;
        }

        if (Passenger && Trucks && Others) {
          othersegment = `&othersegment=1,2,3`;
        }
        filters = filters += othersegment;
      }
      // debugger
      // response = await Axios.axios({
      //   method: 'get',
      //   url: `${API_URL}filtersearch/?${filters}`,
      // })
    }
    // else {
    //   response = await Axios.axios({
    //     method: 'get',
    //     url: `${API_URL}filtersearch/?limit=15&lang=${lang}&offset=0&orderkey=stock&userId=${userId}&order=desc`,
    //   })
    // }

    if (
      filters &&
      (width ||
        height ||
        inch ||
        segment ||
        load ||
        speed ||
        brand ||
        promotion ||
        searchData ||
        Passenger ||
        Trucks ||
        Others ||
        warehouse ||
        orderkey)
    ) {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?${filters}&limit=${DISPLAY_LIMIT}&onlyForSale=true`,
      });
    } else {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=${DISPLAY_LIMIT}&lang=${lang}&offset=0&orderkey=stock&userId=${userId}&order=desc`,
      });
    }

    // debugger
    if (response) {
      dispatch({
        type: Types.FILTER_PRODUCT,
        filterList: response.data.tireSize,
        selectedFilters: payload,
      });
    } else {
      dispatch({
        type: Types.FILTER_PRODUCT_ERROR,
        morelistEnd: true,
        morelistMsg: "No Product Found",
      });
    }
  } catch (error) {
    dispatch({ type: Types.FILTER_PRODUCT_ERROR, error });
  }
};
export const CLEAR_ERROR = () => async (dispatch) => {
  dispatch({
    type: Types.FILTER_PRODUCT_ERROR,
    morelistEnd: false,
    morelistMsg: null,
  });
};
export const REMOVE_SEARCH = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_FILTERS,
    searchData: undefined,
  });
};

export const SEARCH_PRODUCTS = (payload) => async (dispatch) => {
  try {
    // debugger
    const { searchFilter, lang, userId, warehouse } = payload;
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}filtersearch/?onlyForSale=true&limit=${DISPLAY_LIMIT}&lang=${lang}&offset=0&userId=${userId}&warehouse=${warehouse}${
        searchFilter ? `&filter=${searchFilter}` : ""
      }`,
    });
    if (response) {
      dispatch({
        type: Types.SEARCH_PRODUCTS,
        searchedProductsList: response.data.tireSize,
        searchData: payload.searchFilter,
        warehouse: payload.warehouse,
      });
    } else {
      dispatch({
        type: Types.SEARCH_PRODUCTS_ERROR,
        error: "No Product Found",
      });
    }
  } catch (error) {
    dispatch({ type: Types.SEARCH_PRODUCTS_ERROR, error });
  }
};

export const GENERATE_ORDER_ITEMNUMBER = () => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}generateorderitemnumber/`
    );
    if (response) {
      dispatch({
        type: Types.GENERATE_ORDER_ITEMNUMBER,
        orderItemNumber: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GENERATE_ORDER_ITEMNUMBER_ERROR,
      error,
    });
  }
};

export const GENERATE_INVOICE_NUMBER = () => async (dispatch) => {
  try {
    const response = await Axios.axios.get(`${API_URL}generateinvoicenumber/`);
    //  debugger
    if (response) {
      dispatch({
        type: Types.GENERATE_INVOICE_NUMBER,
        invoiceNumber: response.data.invoiceid,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GENERATE_INVOICE_NUMBER_ERROR,
      error,
    });
  }
};

export const GENERATE_ORDER_NUMBER = () => async (dispatch) => {
  try {
    const response = await Axios.axios.get(`${API_URL}generateordernumber/`);
    //  debugger
    if (response) {
      dispatch({
        type: Types.GENERATE_ORDER_NUMBER,
        orderNumber: response.data.orderid,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GENERATE_ORDER_NUMBER_ERROR,
      error,
    });
  }
};

export const CONFIRM_CALLOFF = (payload) => async (dispatch) => {
  try {
    let response = [];
    for (let i = 0; i < payload.length; i++) {
      let totalQty = 0;

      let orderKey = 0;
      await Axios.axios
        .get(`${API_URL}generateorderitemnumber/`)
        .then((response) => {
          // debugger
          orderKey = response.data.orderitemid;
        });

      let CONFIRM_CALLOFF_QUERY = {
        query: ` mutation updateOrderItem($ItemId:Int!, $quantity:Int!, $id:Int!, $price:Float!, $totalPrice:Float!,  $reservedStockCallOff: Int!,$discountAmount:Float!, $totalPriceAfterDiscount:Float!){
          updateOrderItem(id: $ItemId, input: {quantity: $quantity, tireSize: {id: $id, type: "object"}, price: $price, totalPrice: $totalPrice, discountAmount: $discountAmount, totalPriceAfterDiscount: $totalPriceAfterDiscount, reservedStockCallOff: {id: $reservedStockCallOff, type: "object"}, isCallOff: true}) {
          success
          message
          output(defaultLanguage: "en") {
            id
            key
          }
        }
      }`,
        variables: {
          ItemId: orderKey,
          quantity: totalQty,
          id: parseInt(payload[i].tireSize.id),
          price: 0,
          totalPrice: 0,
          reservedStockCallOff: parseInt(payload[i].reservedStock.id), // Pass the reserve stock if there; if there is no reserve stock pass "NULL"
          discountAmount: 0,
          totalPriceAfterDiscount: 0,
        },
      };

      let createItem = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        CONFIRM_CALLOFF_QUERY
      );
      response.push(createItem.data.data.updateOrderItem.output);
    }
    // for Loop Ends
    // debugger
    if (response) {
      dispatch({
        type: Types.CONFIRM_CALLOFF,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.CONFIRM_CALLOFF_ERROR,
      error,
    });
  }
};

export const CREATE_ORDER_STATUS = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.CREATE_ORDER_STATUS,
        orderStatus: undefined,
      });
    } else {
      let response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        query
      );
      if (response) {
        dispatch({
          type: Types.CREATE_ORDER_STATUS,
          orderStatus: response.data.data.createOrderStatus.output,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: Types.STATUS_ERROR,
      error,
    });
  }
};

export const CREATE_INVOICE = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.CREATE_INVOICE,
        invoiceStatus: undefined,
      });
    } else {
      let response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        query
      );
      if (response) {
        dispatch({
          type: Types.CREATE_INVOICE,
          invoiceStatus: response.data.data.updateInvoice.output,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: Types.CREATE_INVOICE_ERROR,
      error,
    });
  }
};

export const PROMO_VALIDATE = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.PROMO_VALIDATE,
        payload: undefined,
      });
    } else {
      let response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        query
      );
      if (response) {
        dispatch({
          type: Types.PROMO_VALIDATE,
          promoDetails:
            response.data.data.getPromotionCodeListing.edges[0].node,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: Types.PROMO_VALIDATE_ERROR,
      error,
    });
  }
};

export const CREATE_ORDER = (query) => async (dispatch) => {
  try {
    let response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      query
    );
    if (response) {
      dispatch({
        type: Types.CREATE_ORDER,
        orderDetails: response.data.data.updateOrder.output,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.CREATE_ORDER_ERROR,
      error,
    });
  }
};

export const SEND_MAIL = (payload) => async (dispatch) => {
  try {
    if (payload === "reset") {
      dispatch({
        type: Types.SEND_MAIL,
        payload: undefined,
      });
    } else {
      // const { email, orderId } = payload;
      // const response = await Axios.axios.get(
      //   `${API_URL}notifyorderviaemail/?email=${email}&orderId=${orderId}`
      // );
      const {
        email,
        orderId,
        errorMessage,
        phoneNumber,
        defaultCommunication,
        lpoFile,
      } = payload;
      if (!email || !orderId) {
        dispatch({
          type: Types.SEND_MAIL_ERROR,
          error: "Order Id or email not present",
        });
      }
      const formData = new FormData();
      if (lpoFile && lpoFile.files.length > 0) {
        formData.append("file", lpoFile.files[0]);
      }

      const response = await Axios.axios.post(
        `${API_URL}notifyorderviaemail/?email=${email}&orderId=${orderId}&phone=${phoneNumber}&defaultCommunication=${defaultCommunication}&errorMessage=${errorMessage}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch({
        type: Types.SEND_MAIL,
        payload: response.data.status,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.SEND_MAIL_ERROR,
      error,
    });
  }
};

export const DELETE_ITEM = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.PRODUCTS}`,
      query
    );
    dispatch({
      type: Types.DELETE_ITEM,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: Types.DELETE_ITEM_ERROR,
      error,
    });
  }
};

export const UPDATE_ADD_TO_CART_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_ADD_TO_CART_QUANTITY,
      payload: { itemId, quantity },
    });
  };

export const UPDATE_RESERVED_STOCK_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_RESERVED_STOCK_QUANTITY,
      payload: { itemId, quantity },
    });
  };
export const UPDATE_RECENTLY_VIEWED_PRODUCT_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_RECENTLY_VIEWED_PRODUCT_QUANTITY,
      payload: { itemId, quantity },
    });
  };

const getSpecialPrice = (productDetail, userId) => {
  const dateFilteredData =
    productDetail &&
    productDetail.specialPrice &&
    productDetail.specialPrice.length &&
    productDetail.specialPrice.filter((sp) => {
      return (
        sp.user &&
        sp.user.find((u) => {
          return u.id === userId?.toString();
        })
      );
    });

  // console.log('final filtered data', userFilteredData);
  return dateFilteredData;
};

export const UPDATE_CART_ITEM =
  (id, quantity, lang, item, userId) => async (dispatch) => {
    try {
      // debugger
      let UPDATE_CART_QUERY;

      let specialPrice = getSpecialPrice(item && item.tireDot, userId);
      // console.log(specialPrice)
      // debugger

      if (
        specialPrice &&
        specialPrice.length &&
        specialPrice[0].specialPrice < item.tireDot.price &&
        quantity >= specialPrice[0].minPurchaseQuantity
      ) {
        const specialPriceId = specialPrice[0].id;
        UPDATE_CART_QUERY = {
          query: `
            mutation {  
                updateCartItem (
                id: ${id}
                input: {
                    quantity: ${quantity},
                    specialPrice: {id: ${specialPriceId}, type: "object" }       
                } 
                ) {
                success
                    message
                    output(defaultLanguage: "en") {
                    id
                    key
                    modificationDate        
                    }    
                }
            }
        `,
        };
      } else {
        UPDATE_CART_QUERY = {
          query: `
            mutation {  
                updateCartItem (
                id: ${id}
                input: {
                    quantity: ${quantity},    
                } 
                ) {
                success
                    message
                    output(defaultLanguage: "en") {
                    id
                    key
                    modificationDate        
                    }    
                }
            }
        `,
        };
      }

      // console.log('$$$$$$$$$$$$------------',UPDATE_CART_QUERY, '------------$$$$$$$$$$$$')
      // debugger
      await Axios.axios.post(
        `${API_URL}${API_REQUESTS.PRODUCTS}`,
        UPDATE_CART_QUERY
      );

      const cartId = JSON.parse(atob(sessionStorage.getItem("cartId")));
      const GET_CART_DETAIL_QUERY = {
        query: `{
        getCart(id: ${cartId}, defaultLanguage: "${lang}") {
          id
          user {
            ... on object_user {
              id
              userGroup {
                ... on object_userGroup {
                  id
                  groupType
                }
              }
            }
          }
          cartItem {
            ... on object_cartItem {
              id
              quantity
              tireDot {
                ... on object_tireDot {
                  id
                  price
                  stock
                  yearOfManufacture
                  specialPrice {
                    ... on object_specialPrice {
                      id
                      specialPrice
                      validFrom
                      validTo
                      minPurchaseQuantity
                      user {
                        ... on object_user {
                          id
                        }
                      }
                      userGroup {
                        ... on object_userGroup {
                          id
                          groupType
                        }
                      }
                    }
                  }
                }
              }
              tireSize {
                ... on object_tireSize {
                  id
                  width
                  height
                  diameter
                  contructionType
                  loadRating
                  loadRating1
                  speedRating
                  speedRating1
                  speedRating2
                  suffix
                  loadCapacity
                  oeKey
                  validFrom
                  validUntil
                  tire {
                    ... on object_tire {
                      id
                      title
                      sizeDescription
                      frFlag
                      ssrMark
                      evKey
                      segment {
                        ... on object_segment {
                          name
                        }
                      }
                      brand {
                        ... on object_brand {
                          brandName
                        }
                      }
                      certifiedBy {
                        ... on object_certifiedBy {
                          tooltip
                          icon {
                            fullpath
                          }
                        }
                      }
                    }
                  }
                  promotion {
                    ... on object_promotion {
                      id
                      minPurchaseType
                      minPurchaseValue
                      discountType
                      discountTypeValue
                      freeShipping
                      includeCheapestProductFree
                      freeProductQuantity
                      maxDiscountAmount
                      freeTire {
                        ... on object_freeTire {
                          id
                          stock
                          tire {
                            ... on object_tireSize {
                              id
                              width
                              height
                              diameter
                              contructionType
                              loadRating
                              speedRating
                              tire {
                                ... on object_tire {
                                  title
                                }
                              }
                            }
                          }
                        }
                      }
                      providePromotionCode
                      promotionCodeValidDays
                      promotionCodeValidDaysValue
                      promotionCodeValidDate
                      promotionCodeValidDateValue
                      validFrom
                      validUntil
                      title
                      userGroup {
                        ... on object_userGroup {
                          id
                          groupType
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `,
      };
      const response = await Axios.axios({
        method: "post",
        url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
        data: GET_CART_DETAIL_QUERY,
      });
      if (response) {
        dispatch({
          type: Types.UPDATE_CART_ITEM,
          itemiId: id,
          updatedQuantity: quantity,
          itemIncart: response.data.data.getCart.cartItem,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_CART_ERROR,
        error,
      });
    }
  };

export const CLEAR_UPDATEDITEM = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_UPDATEDITEM,
    payload: null,
  });
};

export const GET_USER_CART_ID = (id) => async (dispatch) => {
  try {
    // debugger
    const GET_USER_CART_ID_QUERY = `{
      getUser(id:${id}) {
        cart{
        ... on object_cart {
          id
        }
        }
      }
    }`;
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.PRODUCTS}`,
      {
        query: GET_USER_CART_ID_QUERY,
      }
    );
    if (response) {
      dispatch({
        type: Types.GET_USER_CART_ID,
        payload: response.data.data.getUser.cart,
      });
    }
  } catch (error) {}
};

export const GET_USER_CART_ID_AND_FEATURED_PRODUCTS =
  (id, lang) => async (dispatch) => {
    try {
      const date = new Date();
      let currentDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let currentTime =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      const response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.PRODUCTS}`,
        {
          query: GET_USER_CART_ID_AND_FEATURED_PRODUCTS_QUERY(
            id,
            lang,
            currentDate,
            currentTime
          ),
        }
      );
      // debugger
      dispatch({
        type: Types.GET_USER_CART_ID,
        payload: response.data.data.getUser.cart,
      });
      dispatch({
        type: Types.GET_FEATURE_PRODUCT,
        payload: response.data.data.getTireSizeListing,
      });
    } catch (error) {}
  };

export const GET_SHIPPING_ADDRESS = (id, lang) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}latestshippingaddress/?userid=${id}&lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.GET_SHIPPING_ADDRESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GET_SHIPPING_ADDRESS_ERROR,
      error,
    });
  }
};

export const GET_SHIPPING_ADDRESS_2 = (id, lang) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}shipping-addresses/?userid=${id}&lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.GET_SHIPPING_ADDRESS_2,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GET_SHIPPING_ADDRESS_2_ERROR,
      error,
    });
  }
};

export const SET_SHIPPING_ADDRESS = (id) => async (dispatch) => {
  dispatch({
    type: Types.SET_SHIPPING_ADDRESS,
    shipAddressId: id,
  });
};

export const CREATE_SHIPPING_ADDRESS = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.SHIPPING_ADDRESS,
        shipAddressId: undefined,
      });
    } else {
      // console.log(query)
      // debugger
      const {
        address,
        city,
        country,
        deliverTo,
        phoneNumber,
        state,
        userId,
        zipcode,
      } = query;
      let response = await Axios.axios.post(
        `${API_URL}createshippingaddress/?address=${address}&city=${city}&country=${country}&deliverto=${deliverTo}&phonenumber=${phoneNumber}&state=${state}&userid=${userId}&zipcode=${zipcode}`
      );
      // const response = await Axios.axios({
      //       method: 'post',
      //       url: `${API_URL}createshippingaddress`,
      //       data: query

      //   })
      // debugger
      if (response) {
        dispatch({
          type: Types.SHIPPING_ADDRESS,
          shipAddressId: response.data.id,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: Types.SHIPPING_ADDRESS_ERROR,
      error,
    });
  }
};

export const UPDATE_CART_ITEM_DISABLE = (id, value) => (dispatch) => {
  dispatch({
    type: Types.UPDATE_CART_ITEM_DISABLE,
    payload: { id, value },
  });
};

export const CREATE_CART_ITEM =
  (
    itemId,
    dotId,
    itemQuantity,
    itemPrice,
    cartId,
    cartItems,
    lang,
    specialPriceId
  ) =>
  async (dispatch) => {
    try {
      const createCartItemKey = uuidv4();
      let CREATE_CART_ITEM_QUERY;
      if (specialPriceId) {
        CREATE_CART_ITEM_QUERY = `mutation {  
        createCartItem (
          parentId: ${PARENTS_ID.CREATE_CART_ITEM}, 
          key: "${createCartItemKey}", 
          input: {
            quantity: ${itemQuantity},
            tireSize: {id: ${itemId}, type: "object"},
            tireDot: {id: ${dotId}, type: "object"},
          } 
        ) {
          success
            message
            output(defaultLanguage: "en") {
              id
              key
              creationDate
              fullpath        
            }    
        }
      }`;
      } else {
        CREATE_CART_ITEM_QUERY = `mutation {  
        createCartItem (
          parentId: ${PARENTS_ID.CREATE_CART_ITEM}, 
          key: "${createCartItemKey}", 
          input: {
            quantity: ${itemQuantity},
            tireSize: {id: ${itemId}, type: "object"},
            tireDot: {id: ${dotId}, type: "object"},
          } 
        ) {
          success
            message
            output(defaultLanguage: "en") {
              id
              key
              creationDate
              fullpath        
            }    
        }
      }`;
      }

      // debugger

      const createCartItemResponse = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.PRODUCTS}`,
        {
          query: CREATE_CART_ITEM_QUERY,
        }
      );
      const createCartItemResponseData =
        createCartItemResponse.data.data.createCartItem.output;
      let updateCartTotalPrice = 0;
      // Updating UPDATE CART ITEM LIST
      let updateCartItemList = "";

      cartItems.forEach((item) => {
        updateCartTotalPrice = updateCartTotalPrice +=
          item.quantity * Math.round(item.tireDot.price);
        updateCartItemList += `{ id: ${parseInt(item.id)}, type: "object" },`;
      });
      updateCartItemList += `{ id: ${parseInt(
        createCartItemResponseData.id
      )}, type: "object" }`;
      // Updating UPDATE CART ITEM LIST Ends
      updateCartTotalPrice = updateCartTotalPrice + itemQuantity * itemPrice;
      const UPDATE_CART_QUERY = `mutation {  
    updateCart ( 
       id: ${parseInt(cartId)},    
       input: {
         totalAmount: ${updateCartTotalPrice},
         cartItem: [${updateCartItemList}]      
       } 
     ) {
       success
         message
         output(defaultLanguage: "en") {
           modificationDate
           
         }
     }
   }`;
      await Axios.axios.post(`${API_URL}${API_REQUESTS.PRODUCTS}`, {
        query: UPDATE_CART_QUERY,
      });

      const GET_CART_DETAIL_QUERY = `{
        getCart(id: ${parseInt(cartId)}, defaultLanguage: "${lang}") {
          id
          user {
            ... on object_user {
              id
              userGroup {
                ... on object_userGroup {
                  id
                  groupType
                }
              }
            }
          }
          cartItem {
            ... on object_cartItem {
              id
              quantity
              tireDot {
                ... on object_tireDot {
                  id
                  price
                  stock
                  specialPrice {
                    ... on object_specialPrice {
                      id
                      specialPrice
                      validFrom
                      validTo
                      minPurchaseQuantity
                      user {
                        ... on object_user {
                          id
                        }
                      }
                      userGroup {
                        ... on object_userGroup {
                          id
                          groupType
                        }
                      }
                    }
                  }
                }
              }
              tireSize {
                ... on object_tireSize {
                  id
                  width
                  height
                  diameter
                  contructionType
                  loadRating
                  loadRating1
                  speedRating
                  speedRating1
                  speedRating2
                  suffix
                  loadCapacity
                  oeKey
                  validFrom
                  validUntil
                  tire {
                    ... on object_tire {
                      id
                      title
                      sizeDescription
                      frFlag
                      ssrMark
                      evKey
                      segment {
                        ... on object_segment {
                          name
                        }
                      }
                      brand {
                        ... on object_brand {
                          brandName
                        }
                      }
                      certifiedBy {
                        ... on object_certifiedBy {
                          tooltip
                          icon {
                            fullpath
                          }
                        }
                      }
                    }
                  }
                  promotion {
                    ... on object_promotion {
                      id
                      minPurchaseType
                      minPurchaseValue
                      discountType
                      discountTypeValue
                      freeShipping
                      includeCheapestProductFree
                      freeProductQuantity
                      maxDiscountAmount
                      freeTire {
                        ... on object_freeTire {
                          id
                          stock
                          tire {
                            ... on object_tireSize {
                              id
                              width
                              height
                              diameter
                              contructionType
                              loadRating
                              speedRating
                              tire {
                                ... on object_tire {
                                  title
                                }
                              }
                            }
                          }
                        }
                      }
                      providePromotionCode
                      promotionCodeValidDays
                      promotionCodeValidDaysValue
                      promotionCodeValidDate
                      promotionCodeValidDateValue
                      validFrom
                      validUntil
                      title
                      userGroup {
                        ... on object_userGroup {
                          id
                          groupType
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `;

      const getCartDetailResponse = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.PRODUCTS}`,
        {
          query: GET_CART_DETAIL_QUERY,
        }
      );
      const getCartDetailResponseData =
        getCartDetailResponse.data.data.getCart.cartItem;
      dispatch({
        type: Types.CREATE_CART_ITEM,
        payload: getCartDetailResponseData,
      });
    } catch (error) {}
  };

export const DEACTIVE_PROMO = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      query
    );
    dispatch({
      type: Types.DEACTIVE_PROMO,
      payload: response.data.data,
    });
  } catch (error) {}
};

/**
 *
 * @param {number} orderId
 * @param {HTMLInputElement} lpoFile
 * @returns
 */
export const EXPORT_ORDER = (orderId, lpoFile) => async (dispatch) => {
  try {
    const formData = new FormData();

    if (lpoFile.files.length > 0) {
      formData.append("lpo", lpoFile.files[0]);
    }

    const response = await Axios.axios.post(
      `${IMPORT_URL}orderExport?orderId=${orderId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // debugger;
    dispatch({
      type: Types.EXPORT_ORDER,
      exportDetails: response.data,
    });
  } catch (error) {
    dispatch({
      type: Types.EXPORT_ORDER,
      exportDetails: error.message,
    });
  }
};

export const UPDATE_PRICE = (itemId, selectPrice) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_PRICE,
    payload: { itemId, selectPrice },
  });
};
export const RECENT_UPDATE_PRICE =
  (itemId, selectPrice) => async (dispatch) => {
    dispatch({
      type: Types.RECENT_UPDATE_PRICE,
      payload: { itemId, selectPrice },
    });
  };

export const NORTIFYME = (userId, productId) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}notifyme/?tiresizeId=${productId}&userId=${userId}`
    );
    if (response.data.status) {
      dispatch({
        type: Types.NORTIFY_ME,
        payload: new Date().getTime(),
      });
    }
  } catch (error) {
    dispatch({
      type: Types.NORTIFY_ME_ERROR,
      error,
    });
  }
};

export const CLEAR_DATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_DATA,
    searchData: undefined,
  });
};

export const NEW_CONFIRM_ORDER_2 =
  (payload, userId, invoiceTotal, createOrder, promotionValidate) =>
  async (dispatch) => {
    try {
      const date = new Date();
      let currentDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let currentTime =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

      let orderKeyList;
      await Axios.axios
        .get(`${API_URL}generateNumbersForOrder/?orderItem=${payload.length}`)
        .then((response) => {
          orderKeyList = response.data;
        });

      let orderItems = orderKeyList.orderItem;

      const final_payload = payload.map((item, index) => ({
        ...item,
        orderItemId: orderItems[index].orderItemId,
      }));

      let orderItemsList = [];

      for (let i = 0; i < final_payload.length; i++) {
        let totalQty = 0;
        let totalAmount = 0;
        // let totalTax = 0
        let totaldiscount = 0;
        let shippingCharge = 0;
        let totalProductAmount = 0;
        let totalReservedStock = 0;
        let promoId = null;
        let additionalQuantity = 0;
        let reservedStockResponseKey = null;

        // Calculation of Discount per Item
        let reseredStockQty = 0;
        if (final_payload[i].reservedStock) {
          if (payload[i].quantity <= payload[i].reservedStock.quantity) {
            reseredStockQty = payload[i].quantity;
          } else {
            reseredStockQty = payload[i].reservedStock.quantity;
          }
        }

        if (final_payload[i].reservedStockQuantity) {
          totalReservedStock = totalReservedStock +=
            payload[i].reservedStockQuantity;
        }

        let specialPrice = getSpecialPrice(final_payload[i].tireDot, userId);

        let itemPrice =
          specialPrice &&
          specialPrice.length &&
          specialPrice[0].specialPrice < final_payload[i].tireDot.price
            ? specialPrice[0].specialPrice
            : final_payload[i].tireDot.price;

        let total =
          (final_payload[i].quantity - reseredStockQty) * Math.round(itemPrice);

        if (
          final_payload[i].tireSize.promotion &&
          final_payload[i].tireSize.promotion.length > 0 &&
          !final_payload[i].tireSize.promotion[0].freeShipping
        ) {
          shippingCharge = shippingCharge += 0;
        }
        if (
          final_payload[i].tireSize.promotion &&
          final_payload[i].tireSize.promotion.length > 0 &&
          final_payload[i].tireSize.promotion[0].discountType &&
          final_payload[i].tireSize.promotion[0].discountType.includes(
            "Percentage"
          ) &&
          final_payload[i].tireSize.promotion[0].minPurchaseValue <=
            final_payload[i].quantity - reseredStockQty
        ) {
          let percenValue =
            final_payload[i].tireSize.promotion &&
            final_payload[i].tireSize.promotion.length > 0 &&
            final_payload[i].tireSize.promotion[0].discountTypeValue / 100;
          let dicountAmount = percenValue * total;
          if (
            dicountAmount >
            final_payload[i].tireSize.promotion[0].maxDiscountAmount
          ) {
            dicountAmount =
              final_payload[i].tireSize.promotion[0].maxDiscountAmount;
          }
          promoId = final_payload[i].tireSize.promotion[0].id;
          totaldiscount = totaldiscount += dicountAmount;
          totalAmount = totalAmount += total - dicountAmount;
          totalProductAmount = totalProductAmount += total - dicountAmount;
          totalQty = totalQty += parseInt(final_payload[i].quantity);
        } else if (
          final_payload[i].tireSize.promotion &&
          final_payload[i].tireSize.promotion.length > 0 &&
          final_payload[i].tireSize.promotion[0].discountType &&
          final_payload[i].tireSize.promotion[0].discountType.includes(
            "Amount"
          ) &&
          final_payload[i].tireSize.promotion[0].minPurchaseValue ===
            final_payload[i].quantity - reseredStockQty
        ) {
          let dicountAmount =
            final_payload[i].tireSize.promotion &&
            final_payload[i].tireSize.promotion.length > 0 &&
            final_payload[i].tireSize.promotion[0].discountTypeValue;
          promoId = final_payload[i].tireSize.promotion[0].id;
          totaldiscount = totaldiscount += dicountAmount;
          totalAmount = totalAmount += total - dicountAmount;
          totalProductAmount = totalProductAmount += total - dicountAmount;
          totalQty = totalQty += parseInt(final_payload[i].quantity);
        } else if (
          final_payload[i].tireSize.promotion &&
          final_payload[i].tireSize.promotion.length > 0 &&
          final_payload[i].tireSize.promotion[0].discountType &&
          final_payload[i].tireSize.promotion[0].discountType.includes(
            "Amount"
          ) &&
          final_payload[i].tireSize.promotion[0].minPurchaseValue < total
        ) {
          let dicountAmount =
            final_payload[i].tireSize.promotion &&
            final_payload[i].tireSize.promotion.length > 0 &&
            final_payload[i].tireSize.promotion[0].discountTypeValue;
          promoId = final_payload[i].tireSize.promotion[0].id;
          totaldiscount = totaldiscount += dicountAmount;
          totalAmount = totalAmount += total - dicountAmount;
          totalProductAmount = totalProductAmount += total - dicountAmount;
          totalQty = totalQty += parseInt(final_payload[i].quantity);
        } else if (
          final_payload[i].tireSize.promotion &&
          final_payload[i].tireSize.promotion.length > 0 &&
          final_payload[i].tireSize.promotion[0].discountType &&
          final_payload[i].tireSize.promotion[0].discountType.includes(
            "Additional Free Product"
          ) &&
          final_payload[i].tireSize.promotion[0].minPurchaseValue <=
            final_payload[i].quantity
        ) {
          let newQty = final_payload[i].quantity.toString();
          let freeQty = parseInt(newQty.charAt(0));

          additionalQuantity =
            final_payload[i].tireSize.promotion[0].freeProductQuantity *
            freeQty;
          promoId = final_payload[i].tireSize.promotion[0].id;
          totalAmount = totalAmount += total;
          totalProductAmount = totalProductAmount += total;
          totalQty = totalQty += parseInt(final_payload[i].quantity);
        } else {
          totalQty = totalQty += parseInt(final_payload[i].quantity);
          totalAmount = totalAmount += total;
          totalProductAmount = totalProductAmount += total;
        }

        // Reserved Stock Logic
        if (final_payload[i].reservedStockQuantity) {
          const reservedStockKey = `reserveStock${uuidv4()}`;
          let RESERVE_STOCK_QUERY = {
            query: `mutation createReservedStock($key:String!, $quantity:Int!, $totalQuantity:Int!) {
            createReservedStock(
              parentId: ${PARENTS_ID.CREATE_RESERVED_STOCK}, 
              key: $key, 
              input: {
                totalQuantity:$totalQuantity
                quantity: $quantity
              }) 
            {
              success
              message
              output(defaultLanguage: "en") {
                id
                key
              }
            }
          }`,
            variables: {
              key: reservedStockKey,
              totalQuantity: final_payload[i].reservedStockQuantity,
              quantity: final_payload[i].reservedStockQuantity,
            },
          };
          await Axios.axios
            .post(`${API_URL}${API_REQUESTS.USER}`, RESERVE_STOCK_QUERY)
            .then((response) => {
              reservedStockResponseKey =
                response.data.data.createReservedStock.output.id;
            });
        }
        // Reserved Stock Logic Ends

        // let orderKey = 0
        // await Axios.axios.get(`${API_URL}generateorderitemnumber/`).then(response => {
        //   orderKey = response.data.orderItemId
        // });

        // Send Promo Code by Mail
        if (
          final_payload[i].tireSize.promotion &&
          final_payload[i].tireSize.promotion.length > 0 &&
          final_payload[i].tireSize.promotion[0].providePromotionCode
        ) {
          await Axios.axios.get(
            `${API_URL}createandsendpromocode/?promotionId=${final_payload[i].tireSize.promotion[0].id}&userId=${userId}`
          );
        }
        let CONFIRM_ORDER_QUERY;

        if (reservedStockResponseKey) {
          CONFIRM_ORDER_QUERY = {
            query: ` mutation updateOrderItem($ItemId:Int!, $quantity:Int!, $id:Int!, $price:Float!, $totalPrice:Float!,  $reservedStock: Int!,$discountAmount:Float!, $totalPriceAfterDiscount:Float!,$additionalQuantity:Int!){
            updateOrderItem(id: $ItemId, input: {quantity: $quantity, additionalQuantity:$additionalQuantity,tireSize: {id: $id, type: "object"}, price: $price, totalPrice: $totalPrice, discountAmount: $discountAmount, totalPriceAfterDiscount: $totalPriceAfterDiscount, reservedStock: {id: $reservedStock, type: "object"}}) {
            success
            message
            output(defaultLanguage: "en") {
              id
              key
            }
          }
        }`,
            variables: {
              ItemId: final_payload[i].orderItemId,
              quantity: totalQty,
              id: parseInt(final_payload[i].tireSize.id),
              price: Math.round(itemPrice),
              // totalPrice: totalAmount + parseInt(totalTax),
              totalPrice: Math.round(totalAmount + parseInt(totaldiscount)),
              reservedStock: parseInt(reservedStockResponseKey), // Pass the reserve stock if there; if there is no reserve stock pass "NULL"
              discountAmount: totaldiscount,
              totalPriceAfterDiscount: totalAmount,
              additionalQuantity: additionalQuantity,
              // totalPriceAfterDiscount: totalAmount + parseInt(totalTax) - parseInt(totaldiscount),
            },
          };
        } else {
          if (
            specialPrice &&
            specialPrice.length &&
            specialPrice[0].specialPrice < final_payload[i].tireDot.price
          ) {
            CONFIRM_ORDER_QUERY = {
              query: `mutation updateOrderItem(
                $ItemId:Int!, 
                $tireDotId:Int!,
                $yearOfManufacture:Int!,
                $quantity:Int!, 
                $id:Int!, 
                $price:Float!, 
                $totalPrice:Float!, 
                $discountAmount:Float!, 
                $totalPriceAfterDiscount:Float!,
                $additionalQuantity:Int!
                ) {
                  updateOrderItem(
                    id: $ItemId,
                    input: {
                      quantity: $quantity, 
                      additionalQuantity:$additionalQuantity,
                      yearOfManufacture: $yearOfManufacture,
                      tireDot: {id: $tireDotId, type: "object"}
                      tireSize: {id: $id, type: "object"}
                      price: $price,
                      totalPrice: $totalPrice,
                      discountAmount: $discountAmount,
                      totalPriceAfterDiscount: $totalPriceAfterDiscount
                    }
                  ) {
             success
             message
             output(defaultLanguage: "en") {
               id
               key
             }
           }
         }`,
              variables: {
                ItemId: final_payload[i].orderItemId,
                quantity: totalQty,
                id: parseInt(final_payload[i].tireSize.id),
                tireDotId: parseInt(final_payload[i].tireDot.id),
                yearOfManufacture: final_payload[i].tireDot.yearOfManufacture,
                price: Math.round(specialPrice[0].specialPrice),
                // totalPrice: totalAmount + parseInt(totalTax),
                totalPrice: Math.round(totalAmount + parseInt(totaldiscount)),
                discountAmount: totaldiscount,
                totalPriceAfterDiscount: totalAmount,
                additionalQuantity: additionalQuantity,
                // totalPriceAfterDiscount: totalAmount + parseInt(totalTax) - parseInt(totaldiscount),
              },
            };
          }
          if (promoId) {
            CONFIRM_ORDER_QUERY = {
              query: ` mutation updateOrderItem($ItemId:Int!, $quantity:Int!, $id:Int!, $price:Float!, $totalPrice:Float!, $discountAmount:Float!, $totalPriceAfterDiscount:Float!, $promotionId:Int!, $additionalQuantity:Int!){
            updateOrderItem(id: $ItemId, input: {quantity: $quantity,additionalQuantity:$additionalQuantity, tireSize: {id: $id, type: "object"}, price: $price, totalPrice: $totalPrice, discountAmount: $discountAmount, totalPriceAfterDiscount: $totalPriceAfterDiscount, promotion: {id: $promotionId, type: "object"}}) {
            success
            message
            output(defaultLanguage: "en") {
              id
              key
            }
          }
        }`,
              variables: {
                ItemId: final_payload[i].orderItemId,
                quantity: totalQty,
                id: parseInt(final_payload[i].tireSize.id),
                price: Math.round(final_payload[i].tireDot.price),
                // totalPrice: totalAmount + parseInt(totalTax),
                totalPrice: Math.round(totalAmount + parseInt(totaldiscount)),
                discountAmount: totaldiscount,
                totalPriceAfterDiscount: totalAmount,
                promotionId: promoId,
                additionalQuantity: additionalQuantity,
                // totalPriceAfterDiscount: totalAmount + parseInt(totalTax) - parseInt(totaldiscount),
              },
            };
          }

          if (
            !(
              specialPrice &&
              specialPrice.length &&
              specialPrice[0].specialPrice < final_payload[i].tireDot.price
            ) &&
            !promoId
          ) {
            CONFIRM_ORDER_QUERY = {
              query: ` mutation updateOrderItem($ItemId:Int!, $quantity:Int!, $id:Int!, $price:Float!, $totalPrice:Float!, $discountAmount:Float!, $totalPriceAfterDiscount:Float!, $additionalQuantity:Int!){
             updateOrderItem(id: $ItemId, input: {quantity: $quantity,additionalQuantity:$additionalQuantity, tireSize: {id: $id, type: "object"}, price: $price, totalPrice: $totalPrice, discountAmount: $discountAmount, totalPriceAfterDiscount: $totalPriceAfterDiscount}) {
             success
             message
             output(defaultLanguage: "en") {
               id
               key
             }
           }
         }`,
              variables: {
                ItemId: final_payload[i].orderItemId,
                quantity: totalQty,
                id: parseInt(final_payload[i].tireSize.id),
                price: Math.round(final_payload[i].tireDot.price),
                // totalPrice: totalAmount + parseInt(totalTax),
                totalPrice: Math.round(totalAmount + parseInt(totaldiscount)),
                discountAmount: totaldiscount,
                totalPriceAfterDiscount: totalAmount,
                additionalQuantity: additionalQuantity,
                // totalPriceAfterDiscount: totalAmount + parseInt(totalTax) - parseInt(totaldiscount),
              },
            };
          }
        }

        let createItem = await Axios.axios.post(
          `${API_URL}${API_REQUESTS.USER}`,
          CONFIRM_ORDER_QUERY
        );
        orderItemsList.push(createItem.data.data.updateOrderItem.output);
      }
      // for Loop Ends
      // debugger
      if (orderItemsList) {
        const statusKey = `orderstatus${uuidv4()}`;
        const ORDER_STATUS_QUERY = {
          query: ` mutation createOrderStatus($key:String!, $statusDateTime:String!) {
        createOrderStatus(parentId: ${PARENTS_ID.CREATE_ORDER_STATUS}, key: $key, input: {status: "In process", statusDateTime: $statusDateTime}) {
          success
          message
          output(defaultLanguage: "en") {
            id
            key
          }
        }
      }
      `,
          variables: {
            key: statusKey,
            statusDateTime: `${currentDate} ${currentTime}`,
          },
        };

        let orderStatus;
        let invoiceNumber = orderKeyList.invoice.invoiceId;
        let invoiceStatus;
        let orderNumber = orderKeyList.order.orderId;
        let orderDetails;
        await Axios.axios
          .post(`${API_URL}${API_REQUESTS.USER}`, ORDER_STATUS_QUERY)
          .then((response) => {
            orderStatus = response.data.data.createOrderStatus.output.id;
          });

        // if(orderStatus){
        //   await Axios.axios.get(`${API_URL}generateinvoicenumber/`).then(response=>{
        //     invoiceNumber = response.data.invoiceid
        //   })
        // }
        if (invoiceNumber) {
          const INVOICE_QUERY = {
            query: `mutation updateInvoice($id:Int!, $shippingCharge:Float!, $discountAmount:Float!, $totalExcludeTax:Float!, $taxAmount:Float!, $totalIncludeTax:Float!, $invoiceType:String! $paymentType:String!, $isOpenInvoice:Boolean! ){
            updateInvoice(id: $id, input: {invoiceType: $invoiceType, shippingCharge: $shippingCharge, discountAmount: $discountAmount, totalExcludeTax: $totalExcludeTax, taxAmount: $taxAmount, totalIncludeTax: $totalIncludeTax, paymentType:$paymentType, isOpenInvoice:$isOpenInvoice}) {
          success
          message
          output(defaultLanguage: "en") {
            id
            key
          }
        }
      }
      `,
            variables: {
              id: invoiceNumber,
              invoiceType: "Billable",
              shippingCharge: invoiceTotal.shippingCharge,
              discountAmount: invoiceTotal.discount,
              totalExcludeTax: invoiceTotal.totalAmount,
              taxAmount: invoiceTotal.totalTax,
              totalIncludeTax:
                invoiceTotal.shippingCharge +
                invoiceTotal.totalAmount +
                invoiceTotal.totalTax,
              paymentType: "Credit Check",
              isOpenInvoice: true,
            },
          };

          await Axios.axios
            .post(`${API_URL}${API_REQUESTS.USER}`, INVOICE_QUERY)
            .then((response) => {
              invoiceStatus = response.data.data.updateInvoice.output;
            });
        }
        // if(invoiceStatus){
        //   await Axios.axios.get(`${API_URL}generateordernumber/`).then(response=>{
        //     orderNumber = response.data.orderid
        //   })
        // }
        if (orderNumber) {
          let orderItems = "";
          for (let i = 0; i < orderItemsList.length; i++) {
            if (orderItems.includes(orderItemsList[i].id)) {
            } else {
              orderItems = orderItems.concat(
                `{id:${parseInt(orderItemsList[i].id)}, type:"object"},`
              );
            }
          }
          // debugger
          let CREATE_ORDER_QUERY;
          if (createOrder.validPromoId) {
            CREATE_ORDER_QUERY = {
              query: `mutation {
            updateOrder(
            id: ${orderNumber}, 
            input: {
              orderDate: "${moment(createOrder.orderData).format(
                "DD-MM-YYYY HH:mm"
              )}",
              estimatedDelivery: "${moment(createOrder.deliveredDate).format(
                "DD-MM-YYYY HH:mm"
              )}",
              totalQuantity: ${createOrder.totalQuantity},
              promotionCode: {id: ${createOrder.validPromoId}, type:"object"},
              isCancelled: false,
              orderStatus: {id: ${orderStatus}, type:"object"} ,
              user:{id: ${userId}, type: "object"},
              shippingAddress: {id: ${
                createOrder.shippingAddressId
              }, type:"object"},
              orderItem: [${orderItems}]
              invoice:{id:${invoiceStatus.id}, type:"object"}
            }) 
          {
            success
            message
            output(defaultLanguage: "en") {
              id
              key
            }
          }
        }
        `,
            };
          } else {
            // debugger
            if (promotionValidate && promotionValidate.promotionId) {
              CREATE_ORDER_QUERY = {
                query: `mutation {
              updateOrder(
              id: ${orderNumber}, 
              input: {
                orderDate: "${moment(createOrder.orderData).format(
                  "DD-MM-YYYY HH:mm"
                )}",
                estimatedDelivery: "${moment(createOrder.deliveredDate).format(
                  "DD-MM-YYYY HH:mm"
                )}",
                totalQuantity: ${createOrder.totalQuantity},
                isCancelled: false,
                orderStatus: {id: ${orderStatus}, type:"object"} ,
                user:{id: ${userId}, type: "object"},
                orderItem: [${orderItems}]
                invoice:{id:${invoiceStatus.id}, type:"object"},
                voucher: "${promotionValidate.voucher}",
                voucherPromotion: {id: ${
                  promotionValidate.promotionId
                }, type:"object"}
              }) 
            {
              success
              message
              output(defaultLanguage: "en") {
                id
                key
              }
            }
          }
          `,
              };
            } else {
              CREATE_ORDER_QUERY = {
                query: `mutation {
              updateOrder(
              id: ${orderNumber}, 
              input: {
                orderDate: "${moment(createOrder.orderData).format(
                  "DD-MM-YYYY HH:mm"
                )}",
                estimatedDelivery: "${moment(createOrder.deliveredDate).format(
                  "DD-MM-YYYY HH:mm"
                )}",
                totalQuantity: ${createOrder.totalQuantity},
                isCancelled: false,
                isReservedStock: false,
                orderStatus: {id: ${orderStatus}, type:"object"} ,
                user:{id: ${userId}, type: "object"},
                ${
                  createOrder.shipAddressId
                    ? `shippingAddress: {id: ${createOrder.shipAddressId}, type:"object"},`
                    : ``
                }
                orderItem: [${orderItems}]
                invoice:{id:${invoiceStatus.id}, type:"object"},
              }) 
            {
              success
              message
              output(defaultLanguage: "en") {
                id
                key
              }
            }
          }
          `,
              };
            }
          }

          await Axios.axios
            .post(`${API_URL}${API_REQUESTS.USER}`, CREATE_ORDER_QUERY)
            .then((response) => {
              orderDetails = response.data.data.updateOrder.output;
            });
        }
        if (orderDetails) {
          dispatch({
            type: Types.CREATE_ORDER,
            orderDetails: orderDetails,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: Types.CONFIRM_ORDER_ERROR,
        error,
      });
    }
  };

export const NEW_CONFIRM_CALLOFF =
  (payload, userId, createOrder) => async (dispatch) => {
    try {
      const date = new Date();
      let currentDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let currentTime =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

      let orderItemsList = [];
      for (let i = 0; i < payload.length; i++) {
        let totalQty = payload[i].quantity;

        let orderKey;
        await Axios.axios
          .get(`${API_URL}generateCallOffItemNumber/`)
          .then((response) => {
            orderKey = response.data[0].callOffItemId;
          });

        let CONFIRM_CALLOFF_QUERY = {
          query: ` mutation updateCallOffItem($Id:Int!, $quantity:Float!,$price:Float!,$orderNumber: String!,$productCode: String! , $orderId:Int!){
          updateCallOffItem(
            id: $Id, 
            input: {
              quantity: $quantity, 
              price: $price, 
              orderNumber: $orderNumber, 
              productCode: $productCode, 
              orderItem: {id: $orderId, type: "object"}}) {
            success
            message
            output(defaultLanguage: "en") {
              id
            }
          }
        }
      `,
          variables: {
            Id: orderKey,
            quantity: totalQty,
            orderId: parseInt(payload[i].id),
            price: 0,
            orderNumber: payload[i].orderNumber,
            productCode: payload[i].tireSize.code,
          },
        };

        let updateCallofItem = await Axios.axios.post(
          `${API_URL}${API_REQUESTS.USER}`,
          CONFIRM_CALLOFF_QUERY
        );
        // debugger
        if (updateCallofItem.data.data.updateCallOffItem.output) {
          orderItemsList.push(
            updateCallofItem.data.data.updateCallOffItem.output
          );
        }
      }
      // for Loop Ends

      if (orderItemsList && orderItemsList.length > 0) {
        const statusKey = `orderstatus${uuidv4()}`;
        const ORDER_STATUS_QUERY = {
          query: ` mutation createOrderStatus($key:String!, $statusDateTime:String!) {
        createOrderStatus(parentId: ${PARENTS_ID.CREATE_ORDER_STATUS}, key: $key, input: {status: "In process", statusDateTime: $statusDateTime}) {
          success
          message
          output(defaultLanguage: "en") {
            id
            key
          }
        }
      }
      `,
          variables: {
            key: statusKey,
            statusDateTime: `${currentDate} ${currentTime}`,
          },
        };

        let orderStatus;
        let callOffNumber;
        let orderDetails;
        await Axios.axios
          .post(`${API_URL}${API_REQUESTS.USER}`, ORDER_STATUS_QUERY)
          .then((response) => {
            orderStatus = response.data.data.createOrderStatus.output.id;
          });

        if (orderStatus) {
          await Axios.axios
            .get(`${API_URL}generateCallOffNumber/`)
            .then((response) => {
              callOffNumber = response.data.callOffId;
            });
        }
        if (callOffNumber) {
          let orderItems = "";
          for (let i = 0; i < orderItemsList.length; i++) {
            if (orderItems.includes(orderItemsList[i].id)) {
            } else {
              orderItems = orderItems.concat(
                `{id:${parseInt(orderItemsList[i].id)}, type:"object"},`
              );
            }
          }
          let CREATE_CALLOFF_QUERY = {
            query: `mutation {
            updateCallOff(
            id: ${callOffNumber}, 
            input: {
              callOffDate: "${moment(createOrder.orderData).format(
                "DD-MM-YYYY HH:mm"
              )}",
              totalQuantity: ${createOrder.totalQuantity},
              orderStatus: {id: ${orderStatus}, type:"object"} ,
              callOffItem: [${orderItems}]
              user:{id: ${userId}, type: "object"},
            }) 
          {
            success
            message
            output(defaultLanguage: "en") {
              id
              key
            }
          }
        }
        `,
          };

          await Axios.axios
            .post(`${API_URL}${API_REQUESTS.USER}`, CREATE_CALLOFF_QUERY)
            .then((response) => {
              orderDetails = response.data.data.updateCallOff.output;
            });
        }
        if (orderDetails) {
          dispatch({
            type: Types.CONFIRM_CALLOFF,
            orderDetails: orderDetails,
          });
        }
      } else {
        dispatch({
          type: Types.CONFIRM_CALLOFF_ERROR,
          callOffOrderError: true,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CONFIRM_CALLOFF_ERROR,
        error,
      });
    }
  };

export const EXPORT_CALLOFF = (orderId) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${IMPORT_URL}callOffExport?callOffId=${orderId}`
    );
    dispatch({
      type: Types.EXPORT_CALLOFF,
      calloffExportDetails: response.data,
    });
  } catch (error) {
    dispatch({
      type: Types.EXPORT_CALLOFF,
      calloffExportDetails: error.message,
    });
  }
};

export const SEND_MAIL_CALLOFF = (payload) => async (dispatch) => {
  try {
    if (payload === "reset") {
      dispatch({
        type: Types.SEND_MAIL_CALLOFF,
        payload: undefined,
      });
    } else {
      const {
        email,
        calloffId,
        errorMessage,
        phoneNumber,
        defaultCommunication,
      } = payload;
      const response = await Axios.axios.get(
        `${API_URL}notifyCallOffViaEmail/?email=${email}&callOffId=${calloffId}&phone=${phoneNumber}&defaultCommunication=${defaultCommunication}&errorMessage=${errorMessage}`
      );
      dispatch({
        type: Types.SEND_MAIL_CALLOFF,
        payload: response.data.status,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.SEND_MAIL_CALLOFF_ERROR,
      error,
    });
  }
};
export const VERIYFY_VOUCHER = (payload) => async (dispatch) => {
  const { items, userId, voucher, lang } = payload;
  let finalresponse;
  // let final_itmes = JSON.stringify(items)
  await axios({
    method: "post",
    url: `${API_URL}verifyVoucher/`,
    data: JSON.stringify({
      items: items,
      userId: userId,
      voucher: voucher,
      lang: lang,
    }),
  }).then(function (response) {
    finalresponse = response.data;
  });

  if (finalresponse) {
    dispatch({
      type: Types.VERIYFY_VOUCHER,
      verifyVoucher: finalresponse,
    });
  }
};
export const GET_PROMO_VOUCHER = (lang) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}getVoucherPromotion/?lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.GET_PROMO_VOUCHER,
        promoVoucher: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GET_PROMO_VOUCHER_ERROR,
      error,
    });
  }
};

export const VALIDATE_USER_FOR_FIRSTTIME_PROMO =
  (payload) => async (dispatch) => {
    try {
      const { userId, voucher } = payload;
      const response = await Axios.axios.get(
        `${API_URL}validateUserForVoucher/?userId=${userId}&voucher=${voucher}`
      );
      if (response) {
        dispatch({
          type: Types.VALIDATE_USER_FOR_FIRSTTIME_PROMO,
          userValidated: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.VALIDATE_USER_FOR_FIRSTTIME_PROMO_ERROR,
        error,
      });
    }
  };
