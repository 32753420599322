import * as Types from "../../constents/actionTypes";
import Axios from "../../models/Axios";
import { API_URL, API_REQUESTS, DISPLAY_LIMIT } from "../../constents/helpers";
import axios from "axios";

export const GET_USER_DETAILS = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    if (response) {
      dispatch({
        type: Types.GET_USER_DETAILS,
        profileDetails: response.data.data.getUser,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const SAVE_PROFILE = (query) => async (dispatch) => {
  try {
    let response = await Axios.axios.post(`${API_URL}${API_REQUESTS.USER}`, query);
    if (response) {
      dispatch({
        type: Types.SAVE_PROFILE,
        saveDetails: response.data.data,
      });
    }
  } catch (error) {
    dispatch({ type: Types.SAVE_PROFILE_ERROR, error });
  }
};

export const CHANGE_PASSWORD = (query) => async (dispatch) => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.set("userId", `${query.userId}`);
    bodyFormData.set("newpassword", `${query.newpassword}`);
    bodyFormData.set("confirm", `${query.confirm}`);
    bodyFormData.set("lang", `${query.lang}`);
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const CHANGEPASSWORD_URL = `${API_URL}changepassword/`;

    let response = await axios.post(CHANGEPASSWORD_URL, bodyFormData, axiosConfig);
    // debugger
    if (response) {
      dispatch({
        type: Types.CHANGE_PASSWORD,
        updatedpassword: response.data,
      });
    }
  } catch (error) {
    dispatch({ type: Types.CHANGE_PASSWORD_ERROR, error });
  }
};

export const VERIFY_PASSWORD = (query) => async (dispatch) => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.set("userId", `${query.userId}`);
    bodyFormData.set("password", `${query.password}`);
    bodyFormData.set("lang", `${query.lang}`);
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const VERIFY_URL = `${API_URL}verifyuser/`;

    let response = await axios.post(VERIFY_URL, bodyFormData, axiosConfig);
    if (response) {
      dispatch({
        type: Types.VERIFY_PASSWORD,
        passwordverify: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.VERIFY_PASSWORD_ERROR,
      verifyfail: error.response.data,
    });
  }
};

export const CLEAR_DATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_DATA,
  });
};

export const GET_USER_CREDIT = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_USER_CREDIT,
      creditDetails: response.data.data.getUser,
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const GET_USER_DEFERRED_CHEQ = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    // debugger
    dispatch({
      type: Types.GET_USER_DEFERRED_CHEQ,
      cheqDetails: response.data.data.getUser.userDeferredCheque,
      openInvoices: response.data.data.getUser.userAccountReceivable,
      // userAccountReceivable:response.data.data.getUser.userAccountReceivable
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const GET_NOTIFICATION_LIST = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_NOTIFICATION_LIST,
      notificationList: response.data.data.getNotificationTypeListing.edges,
      // userNotificationSettings : response.data.data.getUser.userNotify
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};
export const GET_USER_NOTIFICATION_SETTING = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_USER_NOTIFICATION_SETTING,
      // userNotificationSettings : response.data.data.getNotificationTypeListing.edges
      userNotificationSettings: response.data.data.getUser.userNotify,
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const UPDATE_USER_NOTIFICATION_SETTING = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    dispatch({
      type: Types.UPDATE_USER_NOTIFICATION_SETTING,
      updateNotificationSettings: response.data.data.updateUserNotify.output.modificationDate,
    });
  } catch (error) {
    dispatch({
      type: Types.UPDATE_USER_NOTIFICATION_SETTING_ERROR,
      error: error,
    });
  }
};

export const CREATE_USER_NOTIFICATION_SETTING = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    if (response) {
      dispatch({
        type: Types.CREATE_USER_NOTIFICATION_SETTING,
        createdNotificationSettings: response.data.data.createUserNotify.output.creationDate,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.CREATE_USER_NOTIFICATION_SETTING_ERROR,
      error: error,
    });
  }
};

export const GET_USER_NOTIFICATION = (payload) => async (dispatch) => {
  // console.log(query)
  try {
    const { userId, order, lang, orderkey, offset } = payload;
    const response = await Axios.axios.get(
      `${API_URL}getnoticelist/?userId=${userId}&lang=${lang}&limit=${DISPLAY_LIMIT}&offset=${offset}&order=${order}&orderkey=${orderkey}`
    );
    // debugger
    // const response = await Axios.axios({
    //         method: 'post',
    //         url: `${API_URL}${API_REQUESTS}getnoticelist/?userId=${userId}&limit=3&offset=0&order=${order}&orderkey=ASC`,
    //         data: {query:query}

    // })
    dispatch({
      type: Types.GET_USER_NOTIFICATION,
      userNotification: response.data.map((noti) => {
        return { ...noti, isNew: typeof noti.isNew === "string" ? parseInt(noti.isNew) : noti.isNew };
      }),
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const LOADMORE_NOTIFICATION = (payload) => async (dispatch) => {
  // console.log(payload)
  try {
    const { userId, offset, order, lang, orderkey } = payload;
    const response = await Axios.axios.get(
      `${API_URL}getnoticelist/?userId=${userId}&lang=${lang}&limit=3&offset=${offset}&order=${order}&orderkey=${orderkey}`
    );
    // debugger
    if (response.data.length > 0) {
      dispatch({
        type: Types.LOADMORE_NOTIFICATION,
        moreUserNotification: response.data,
        offset: offset,
        // loadMore : true
      });
    } else {
      dispatch({
        type: Types.LOADMORE_NOTIFICATION,
        moreUserNotification: response.data,
        loadMore: false,
        offset: offset,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const UPDATE_USER_NOTIFICATION = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    dispatch({
      type: Types.UPDATE_USER_NOTIFICATION,
      updateUserNotification: response.data.data.updateNotice.output.modificationDate,
    });
  } catch (error) {
    dispatch({ type: Types.UPDATE_USER_NOTIFICATION_ERROR, error: error });
  }
};

export const GET_USER_STOCK = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_USER_STOCK,
      userStock: response.data.data.getUser.order,
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};
export const GET_USER_COMPLAINT = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });

    dispatch({
      type: Types.GET_USER_COMPLAINT,
      userComplaintList: response.data.data.getUser.userComplaint,
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};
export const CANCEL_USER_COMPLAINT = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: query,
    });

    dispatch({
      type: Types.CANCEL_USER_COMPLAINT,
      cancelComplaint: response.data.data,
    });
  } catch (error) {
    dispatch({ type: Types.CANCEL_USER_COMPLAINT_ERROR, error: error });
  }
};

export const GET_USER_ORDERS = (query) => async (dispatch) => {
  // debugger
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_USER_ORDERS,
      userOrders: response.data.data.getUser.order,
    });
  } catch (error) {
    dispatch({ type: Types.GET_USER_ORDERS_ERROR, error: error });
  }
};

export const GET_USER_CALLOFFORDER = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    // debugger
    if (response) {
      dispatch({
        type: Types.GET_USER_CALLOFFORDER,
        userCalloff: response.data.data.getUser.callOff,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_USER_CALLOFFORDER_ERROR, error: error });
  }
};
// export const CREATE_USER_COMPLAINT = (query) => async (dispatch) => {
//     try {
//         const response = await Axios.axios({
//             method: 'post',
//             url: `${API_URL}${API_REQUESTS.USER}`,
//             data: query

//         })
//         dispatch({
//             type: Types.CREATE_USER_COMPLAINT,
//             userComplaint: response.data.data.createUserComplaint
//         })
//     } catch (error) {
//         dispatch({ type: Types.CREATE_USER_COMPLAINT_ERROR, error: error })
//     }
// }

export const CREATE_USER_COMPLAINT = (query) => async (dispatch) => {
  try {
    let complaint_id = 0;

    await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: query,
    }).then((response) => {
      complaint_id = response.data.data.createUserComplaint.output.id;
    });
    // debugger
    const response = await Axios.axios.post(`${API_URL}userComplaintExport/?id=${complaint_id}`);
    if (response) {
      dispatch({
        type: Types.CREATE_USER_COMPLAINT,
        userComplaint: response.data,
      });
    }
  } catch (error) {
    dispatch({ type: Types.CREATE_USER_COMPLAINT_ERROR, error: error });
  }
};

export const GET_USER_ADDRESS = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    if (response) {
      dispatch({
        type: Types.GET_USER_ADDRESS,
        userAddress: response.data.data.getUser,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const GET_TIRELIST = (payload) => async (dispatch) => {
  try {
    const { brand, segment } = payload;
    const response = await Axios.axios.get(`${API_URL}productsize/?brand=${brand}&segment=${segment}`);
    if (response) {
      dispatch({
        type: Types.GET_TIRELIST,
        tireDetails: response.data,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_TIRELIST_ERROR, error: error });
  }
};

export const CANCEL_ORDER = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: query,
    });
    if (response) {
      dispatch({
        type: Types.CANCEL_ORDER,
        cancelOrder: response.data.data.updateOrder.output.modificationDate,
      });
    }
  } catch (error) {
    dispatch({ type: Types.CANCEL_ORDER_ERROR, error: error });
  }
};

export const GET_ORDER_DETAILS = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    if (response) {
      dispatch({
        type: Types.GET_ORDER_DETAILS,
        orderDetail: response.data.data.getOrder,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_ORDER_DETAILS_ERROR, error: error });
  }
};

export const GET_CALLOFF_DETAILS = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: query },
    });
    if (response) {
      dispatch({
        type: Types.GET_CALLOFF_DETAILS,
        calloffDetail: response.data.data.getCallOff,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_CALLOFF_DETAILS_ERROR, error: error });
  }
};

export const CLEARORDERDATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_ORDER_DATA,
  });
};

export const FILTER_ORDER = (payload) => async (dispatch) => {
  dispatch({
    type: Types.FILTER_ORDER,
    orderFilter: payload,
  });
};

export const UPDATE_RESERVED_STOCK_ADD_TO_CART_QUANTITY = (itemId, quantity) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_RESERVED_STOCK_ADD_TO_CART_QUANTITY,
    payload: { itemId, quantity },
  });
};

export const REMOVE_RESERVED_STOCK_QUANTITY = (itemId) => async (dispatch) => {
  dispatch({
    type: Types.REMOVE_RESERVED_STOCK_QUANTITY,
    itemId: itemId,
  });
};

export const SHOW_SIMILER = (itemId, showHide) => async (dispatch) => {
  //  debugger
  dispatch({
    type: Types.SHOW_SIMILER,
    payload: {
      itemId,
      showHide,
    },
  });
};

export const UPDATE_USER_PHONENUMBER = (payload) => async (dispatch) => {
  try {
    const { userId, lang, phoneNumber } = payload;
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}updateUserPhoneNumber/?userId=${userId}&lang=${lang}&phoneNumber=${phoneNumber}`,
    });
    if (response) {
      dispatch({
        type: Types.UPDATE_USER_PHONENUMBER,
        updatedphone: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.UPDATE_USER_PHONENUMBER_ERROR,
      serverError: error.response.data,
    });
  }
};
export const UPDATE_USER_EMAIL = (payload) => async (dispatch) => {
  try {
    const { userId, lang, email } = payload;
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}updateUserEmail/?userId=${userId}&lang=${lang}&email=${email}`,
      // data: payload
    });
    if (response) {
      dispatch({
        type: Types.UPDATE_USER_EMAIL,
        userEmail: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.UPDATE_USER_EMAIL_ERROR,
      serverError: error.response.data,
    });
  }
};

export const GET_TARGET_INFO = (payload) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: { query: payload },
    });
    if (response) {
      dispatch({
        type: Types.GET_TARGET_INFO,
        targetInfo: response.data.data.getUser,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GET_TARGET_INFO_ERROR,
      error,
    });
  }
};
export function CLEAR_USER_DATA() {
  return (dispatch) => {
    dispatch({
      type: Types.CLEAR_USER_DATA,
    });
  };
}
