export const GET_ADMIN_DATA_QUERY = (lang) => {
  return `{
    getAdminSettingsListing(first: 1,defaultLanguage: "${lang}") {
      edges {
        node {
          desktopLogo {
              fullpath
          }
          mobileLogo {
            fullpath
          }
          email
          showCustomerSupport
          phoneNumber
          customerSupportMessage
          customerSupportTitle
          customerSupportSubTitle
          details
          loginTitle
          loginText
          aboutUsContent        
        }
      }
    }
  }`;
};
