import * as Types from "../../constents/actionTypes";
import Axios from "../../models/Axios";
// import {API_URL, API_REQUESTS} from '../../constents/helpers'
import { API_URL, API_REQUESTS, PARENTS_ID } from "../../constents/helpers";
import { v4 as uuidv4 } from "uuid";
import { GET_PRODUCT_FILTER_QUERY } from "../queries/product";

export const GET_FEATURE_PRODUCT =
  (lang, currentDate, currentTime) => async (dispatch) => {
    // console.log(query)
    try {
      const FEATURE_ITEMS = {
        query: `{getTireSizeListing(defaultLanguage: "${lang}", filter: "{\\"showasFeaturedProduct\\" : \\"1\\", \\"validFrom\\": {\\"$lte\\" :\\"${currentDate} ${currentTime}\\"}, \\"validUntil\\": {\\"$gte\\" :\\"${currentDate} ${currentTime}\\"} }") {
                edges {
                  node {
                    id
                    width
                    height
                    diameter
                    contructionType
                    loadRating
                    loadRating1
                    speedRating
                    speedRating1
                    speedRating2
                    suffix
                    loadCapacity
                    oeKey
                    validFrom
                    validUntil
                    featureProductLabel
                    
                    dots {
                      ... on object_tireDot {
                        id
                        price
                        stock
                        yearOfManufacture
                        specialPrice {
                          ... on object_specialPrice {
                            id
                            validTo
                            validFrom
                            specialPrice
                            minPurchaseQuantity
                            user {
                                ... on object_user{
                                  id
                                }
                              }
                            userGroup {
                              ... on object_userGroup {
                                id
                                groupType
                              }
                            }
                          }
                        }
                      }
                    }
                    tire {
                      ... on object_tire {
                        id
                        title
                        sizeDescription
                        frFlag
                        ssrMark
                        evKey
                        segment {
                          ... on object_segment {
                            name
                          }
                        }
                        brand {
                          ... on object_brand {
                            brandName
                          }
                        }
                        certifiedBy {
                          ... on object_certifiedBy {
                            tooltip
                            icon {
                              fullpath
                            }
                          }
                        }
                      }
                    }
                    promotion {
                      ... on object_promotion {
                        id
                        minPurchaseType
                        minPurchaseValue
                        discountType
                        discountTypeValue
                        freeShipping
                        includeCheapestProductFree
                        freeProductQuantity
                        maxDiscountAmount
                        freeTire {
                          ... on object_freeTire {
                            id
                            stock
                            tire {
                              ... on object_tireSize {
                                code
                                width
                                height
                                diameter
                                contructionType
                                loadRating
                                loadRating1
                                speedRating
                                speedRating1
                                speedRating2
                                suffix
                                loadCapacity
                                oeKey
                                tire {
                                  ... on object_tire {
                                    title
                                    sizeDescription
                                    frFlag
                                    ssrMark
                                  }
                                }
                              }
                            }
                          }
                        }
                        providePromotionCode
                        promotionCodeValidDays
                        promotionCodeValidDaysValue
                        promotionCodeValidDate
                        promotionCodeValidDateValue
                        validFrom
                        validUntil
                        title
                        userGroup {
                          ... on object_userGroup {
                            id
                            groupType
                          }
                        }
                      }}}}}}`,
      };
      const response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.PRODUCTS}`,
        FEATURE_ITEMS
      );
      // debugger
      dispatch({
        type: Types.GET_FEATURE_PRODUCT,
        payload: response.data.data.getTireSizeListing,
      });
    } catch (error) {
      dispatch({ type: Types.GET_FEATURE_PRODUCT_ERROR, error: error });
    }
  };

export const GET_PRODUCT_DETAILS = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_DETAIL,
      productDetail: response.data.data.getTireSize,
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};

export const GET_PRODUCT_IMAGE = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_IMAGE,
      productImage: response.data.data.getTireSize.mediaFiles,
      productLine: response.data.data.getTireSize.productLine,
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_IMAGE_ERROR, error: error });
  }
};
export const GET_PRODUCT_FEATURES = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_FEATURE,
      productFeature: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_TECH = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_TECH,
      productTechnology: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_CATEGORY = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_CATEGORY,
      productCategory: response.data.data.getTireSize.tire[0].tireCategory,
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_EULABELS = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_EULABELS,
      productEulabels: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_PRESS = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_PRESS,
      productpress: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_DETAIL_ALL_DATA = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_DETAIL_ALL_DATA,
      productDetail: response.data.data.getTireSize,
      productImage: response.data.data.getProductImage.mediaFiles,
      productLine: response.data.data.getProductImage.productLine,
      tireDetails: response.data.data.getTireDetail.tire[0],
      productTechnology: response.data.data.getProductTech,
      productFeature: response.data.data.getProductFeatures.tire[0],
      productEulabels: response.data.data.getEULabels.tire[0],
      productpress: response.data.data.getEULabels.tire[0],
      productCategory: response.data.data.getCategories.tire[0].tireCategory,
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_TIRE_DETAIL = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_TIRE_DETAIL,
      tireDetails: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};

export const GET_PROMO_LIST = (query) => async (dispatch) => {
  // console.log(query)
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    if (response) {
      dispatch({
        type: Types.GET_PROMO_LIST,
        promoList: response.data.data.getPromotionListing.edges,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_PROMO_LIST_ERROR, error: error });
  }
};

export const CLEARDATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEARDATA,
  });
};

export const UPDATE_FEATURE_PRODUCT_CART_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_FEATURE_PRODUCT_CART_QUANTITY,
      payload: { itemId, quantity },
    });
  };

// export const SAVE_FEEDBACK = (userId, feedbackData) => async (dispatch) => {
//     const saveFeedbackKey = uuidv4();
//     const saveFeedbackQuery = `mutation {
//         createUserFeedback(
//           parentId: 169386,
//           key: "${saveFeedbackKey}",
//           input: {
//             user: {id: ${userId}, type: "object"},
//             feedback: "${feedbackData}"
//           }) {
//           success
//           message
//           output(defaultLanguage: "en") {
//             id
//           }
//         }
//       }
//       `
//     const saveFeedbackResponse = await Axios.axios.post(`${API_URL}${API_REQUESTS.USER}`, { query: saveFeedbackQuery });
//     // console.log("111111111111", saveFeedbackResponse);
//     dispatch({
//         type: Types.SAVE_FEEDBACK,
//         payload: saveFeedbackResponse.data.data.createUserFeedback.output.id
//     })
// }

export const SAVE_FEEDBACK = (userId, feedbackData) => async (dispatch) => {
  let saveFeedbackResponse;
  for (let i = 0; i < feedbackData.length; i++) {
    const saveFeedbackKey = uuidv4();
    const saveFeedbackQuery = `mutation {
            createUserFeedbackAnswer(
              parentId: ${PARENTS_ID.CREATE_FEEDBACK_ANSWER},
              key: "${saveFeedbackKey}", 
              input: {
                feedbackAnswer: "${feedbackData[i].ans}",
                user: {id: ${userId}, type: "object"},
                question: {id: ${feedbackData[i].id}, type: "object"}
              } 
            ){
                success
                message
                output(defaultLanguage: "en") {
                  id
                  
                }
                }
          }
          `;
    saveFeedbackResponse = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      { query: saveFeedbackQuery }
    );
  }

  // console.log("---------------------", saveFeedbackResponse);
  // debugger
  dispatch({
    type: Types.SAVE_FEEDBACK,
    payload: saveFeedbackResponse.data.data.createUserFeedbackAnswer.output.id,
  });
};
export const GET_FEEFBACK_QUESTION = (payload) => async (dispatch) => {
  try {
    const { userId, lang } = payload;
    const response = await Axios.axios.get(
      `${API_URL}feedbackquestion/?userId=${userId}&lang=${lang}`
    );
    // debugger
    if (response) {
      dispatch({
        type: Types.GET_FEEFBACK_QUESTION,
        feedbackQuestion: response.data.feedbackQuestions,
      });
    }
  } catch (error) {
    dispatch({ type: Types.GET_FEEFBACK_QUESTION_ERROR, error: error });
  }
};
export function CLEAR_PRODUCT_DATA() {
  return (dispatch) => {
    dispatch({
      type: Types.CLEAR_PRODUCT_DATA,
    });
  };
}

export const GET_FILTER_PRODUCT = () => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: GET_PRODUCT_FILTER_QUERY() },
    });
    if (response) {
      dispatch({
        type: Types.GET_FILTER_PRODUCT,
        filterProductItem: response.data.data.getTireSizeListing.edges,
      });
    }
  } catch (error) {
    dispatch({
      type: Types.GET_FILTER_PRODUCT_ERROR,
      error,
    });
  }
};

export const TOGGLE_MODAL_PRODUCT_DETAIL = () => (dispatch) => {
  dispatch({
    type: Types.TOGGLE_MODAL_PRODUCT_DETAIL,
  });
};
export const SET_PRODUCT_DETAIL_ID = (payload) => (dispatch) => {
  dispatch({
    type: Types.SET_PRODUCT_DETAIL_ID,
    payload,
  });
};
