import * as Types from '../../constents/actionTypes';
import Axios from '../../models/Axios'
import { API_URL, API_REQUESTS } from '../../constents/helpers'

export const onCollapseToggle = (payload) => async (dispatch) => {
  const { collapsefilter } = payload
  try {
    dispatch({
      type: Types.FILTER_TOGGLE,
      payload: collapsefilter
    })
  } catch (error) {
    dispatch({ type: Types.LAYOUT_ERROR, error })
  }
}
export const onCollapseUserNav = (payload) => async (dispatch) => {
  const { collapseUserNav } = payload
  try {
    dispatch({
      type: Types.USERNAV_TOGGLE,
      payload: collapseUserNav
    })
  } catch (error) {
    dispatch({ type: Types.LAYOUT_ERROR, error })
  }
}
export const setLanguageDirection = (payload) => (dispatch) => {
  try {
    dispatch({
      type: Types.SET_LANGUAGE_DIRECTION,
      payload: payload
    })
  } catch (error) {

  }
}
export const DEFAULT_LANGUAGE = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: Types.DEFAUL_LANGUAGE,
      payload: payload
    })
  } catch (error) {
    dispatch({ type: Types.LAYOUT_ERROR, error })
  }
}
export const onPageChange = (payload) => async (dispatch) => {
  const { closeSearchBox } = payload
  try {
    dispatch({
      type: Types.PAGE_CHANGE,
      payload: closeSearchBox
    })
  } catch (error) {
    dispatch({ type: Types.LAYOUT_ERROR, error })
  }
}

export const GET_ADMIN_DATA = (payload) => async (dispatch) => {
  try {
    // const response = await Axios.axios.get(`${API_URL}${API_REQUESTS.ADMIN}`)
    const response = await Axios.axios({
      method: 'post',
      url: `${API_URL}${API_REQUESTS.ADMIN}`,
      data: payload
    })
    if (response) {
      dispatch({
        type: Types.GET_ADMIN_DATA,
        adminData: response.data.data.getAdminSettingsListing.edges[0].node
      })
    }
  } catch (error) {
    dispatch({
      type: Types.GET_ADMIN_DATA_ERROR,
      error: error.response && error.response.data.details
    })
  }
}

export function CLEAR_LAYOUT_DATA(){
  return dispatch =>{
      dispatch({
          type:Types.CLEAR_LAYOUT_DATA
      })
  }
}