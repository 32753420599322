import React, { Fragment, Suspense } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  IconBell,
  IconBox,
  IconCart,
  IconHemberger,
  IconHome,
  IconLogout,
  IconOrder,
  IconSetting,
  IconUser,
} from "../../Components/Icons";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import Loader from "../Loader";
import Navigation from "../Navigation";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, NavLink as NavLinks } from "react-router-dom";
import CallModal from "../../Components/CallModal/CallModal";
import {
  onCollapseToggle,
  onCollapseUserNav,
  onPageChange,
} from "../../store/actions/layout";
import { toast } from "react-toastify";
import LanguageChange from "../Search/languageChange";
import contiLogo from "../../assets/Continental_Stand_Alone.svg";
import UserMarkup from "../../assets/NoOneInOE-inner-page-2.jpg";
import {
  GET_USER_NOTIFICATION,
  UPDATE_USER_NOTIFICATION,
  GET_USER_CREDIT,
} from "../../store/actions/userDetail";

import Search from "../Search";
const SessionStorage = window.sessionStorage;
const mapStateToProps = (state) => {
  return {
    cartItem: state.Cart.cartItem,
    creditDetails: state.UserDetail.creditDetails,
    itemIncart: state.Cart.itemIncart,
    orderDetails: state.Cart.orderDetails,
    collapsefilter: state.Layout.collapsefilter,
    collapseUserNav: state.Layout.collapseUserNav,
    adminData: state.Layout.adminData,
    feedbackData: state.Product.feedbackData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GET_USER_CREDIT: (query) => dispatch(GET_USER_CREDIT(query)),
    onPageChange: (payload) => dispatch(onPageChange(payload)),
    onCollapseToggle: (payload) => dispatch(onCollapseToggle(payload)),
    onCollapseUserNav: (payload) => dispatch(onCollapseUserNav(payload)),
    UPDATE_USER_NOTIFICATION: (query) =>
      dispatch(UPDATE_USER_NOTIFICATION(query)),
    GET_USER_NOTIFICATION: (payload) =>
      dispatch(GET_USER_NOTIFICATION(payload)),
  };
};

class UserPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOn: false,
      cartId: null,
      totalItems: 0,
      unreadmsg: 0,
      activeLogin: false,
      callModalToggle: false,
      langSwitcherOpen: false,
    };
  }

  showfilters = () => {
    const payload = {
      collapsefilter: false,
    };
    this.props.onCollapseToggle(payload);
  };
  showUserNav = () => {
    const payload = {
      collapseUserNav: false,
    };
    this.props.onCollapseUserNav(payload);
  };
  showMenu = () => {
    this.setState({
      isMenuOn: !this.state.isMenuOn,
    });
    // const payload = {
    //   closeSearchBox: false,
    // };
    // this.props.onPageChange(payload);
    // this.showfilters();
    // this.showUserNav();
  };

  handleActiveLogin = () => {
    this.setState({
      activeLogin: !this.state.activeLogin,
    });
  };
  componentDidMount() {
    if (SessionStorage.getItem("cart")) {
      const cart = JSON.parse(SessionStorage.getItem("cart"));
      this.setState({
        totalItems: cart && cart.length,
      });
    }

    // this.setLoading(true);
    const creditquery = `{
            getUser(id: ${this.props.userId}) {
                id
                userNumber
                fullName
                
            }
        }`;
    this.props.GET_USER_CREDIT(creditquery);
  }
  async UNSAFE_componentWillReceiveProps(nextprops) {
    const {
      cartItem,
      orderDetails,
      userNotification,
      feedbackData,
      t,
      collapseUserNav,
      collapsefilter,
    } = nextprops;
    if (collapsefilter === true || collapseUserNav === true) {
      this.setState({
        isMenuOn: false,
      });
    }
    if (SessionStorage.getItem("cartId")) {
      const data = JSON.parse(atob(SessionStorage.getItem("cartId")));
      this.setState({
        cartId: data,
      });
    }
    if (
      this.props.cartItem !== cartItem &&
      this.props.cartItem.id !== cartItem.id
    ) {
      const cart = JSON.parse(SessionStorage.getItem("cart"));
      if (cart && cart.length > 0) {
        this.setState({
          totalItems: cart && cart.length + 1,
        });
      } else {
        this.setState({
          totalItems: 1,
        });
      }
    }
    if (this.props.orderDetails !== orderDetails) {
      this.setState({ totalItems: 0 });
    }

    if (this.props.userNotification !== userNotification) {
      this.newCount(userNotification);
    }
    if (this.props.feedbackData !== feedbackData) {
      toast.success(t("tostMsg.feedbackmsg"));
    }
  }

  newCount = (userNotification) => {
    let total = 0;
    for (let i = 0; i < userNotification.length; i++) {
      if (userNotification[i].isNew === 1) {
        total += 1;
      }
    }
    this.setState({
      unreadmsg: total,
    });
  };

  closeLogin = () => {
    if (this.state.activeLogin) {
      this.handleActiveLogin();
      this.props.onLogin();
    }
  };

  updateNotification = async (item) => {
    const query = `mutation {  
        updateNotice ( 
             id: ${item.id},    
           input: {
             isNew: false,
           } 
         ) {
           success
             message
             output(defaultLanguage: "en") {
               modificationDate
             }
         }
       }`;
    await this.props.UPDATE_USER_NOTIFICATION(query);

    const payload = {
      userId: this.props.userId,
      order: "id",
      lang: this.props.lang,
      orderkey: "desc",
      offset: 0,
    };
    await this.props.GET_USER_NOTIFICATION(payload);
  };

  render() {
    const lang = navigator.language;
    const { userNotification, creditDetails, t } = this.props;

    const OrderStatusNotificationList =
      userNotification &&
      userNotification.length > 0 &&
      userNotification.slice(0, 3).map((item, index) => {
        return (
          <div
            className={
              item.isNew !== 1
                ? "dropdown-item"
                : "cursor_pointer dropdown-item"
            }
            key={index}
            onClick={
              item.isNew !== 1 ? null : () => this.updateNotification(item)
            }
          >
            <h5 className="font-weight-bold">
              {item.isNew === "true" && (
                <span className="bdg-small badge badge-warning">&nbsp;</span>
              )}{" "}
              <span>{item.title}</span>{" "}
              <small>
                {moment.unix(item.creationDate).format("HH:mm DD/MM/YYYY")}
              </small>
            </h5>
            <div>{item.description}</div>
          </div>
        );
      });

    const items = [
      {
        label: (
          <NavLinks to="/accounting" className="nav-link">
            profile
          </NavLinks>
        ),
        icon: <IconUser />,
        key: "0",
      },
      {
        label: (
          <NavLinks to="/orders" className="nav-link">
            order
          </NavLinks>
        ),
        icon: <IconBox />,
        key: "1",
      },
      {
        label: (
          <NavLinks to="/security" className="nav-link">
            settings
          </NavLinks>
        ),
        icon: <IconSetting />,
        key: "2",
      },
      {
        type: "divider",
      },
      {
        label: (
          <Link
            to="#"
            className="nav-link"
            onClick={() => this.props.isLogout()}
          >
            Logout
          </Link>
        ),
        icon: <IconLogout />,
        key: "3",
      },
    ];
    return (
      <Fragment>
        {this.props.isUserAuthenticated ? (
          // desktop version
          <Fragment>
            <div className="user-nav desktop-display">
              <Nav className={"navigate-nav"} navbar>
                <NavItem>
                  <NavLinks to="/products" className="nav-link">
                    <span className="nav-icon">
                      <span className="tires-tire"></span>
                    </span>
                    <span className="nav-txt">{t("topHeader.products")}</span>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    to="/orders"
                    className="nav-link"
                    // data-text={t("topHeader.Orders")}
                  >
                    <span className="nav-icon">
                      <IconOrder />
                    </span>
                    <span className="nav-txt">{t("topHeader.Orders")}</span>
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks to="/brand" className="nav-link">
                    <span className="nav-icon">
                      <IconHome />
                    </span>
                    <span className="nav-txt">{t("topHeader.brand")}</span>
                  </NavLinks>
                </NavItem>
              </Nav>

              <Suspense fallback={<Loader />}>
                <Search
                  isMobile={false}
                  changeLanguage={this.props.changeLanguage}
                />
              </Suspense>
              <Nav className={"site-setting"} navbar>
                <Fragment>
                  {/* <NavItem style={{ display: "flex", alignItems: "center" }}>
                    <LanguageChange
                      isMobile={this.props.isMobile}
                      changeLanguage={this.props.changeLanguage}
                      isUserAuthenticated={this.props.isUserAuthenticated}
                    />
                  </NavItem> */}
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      <span className="nav-icon">
                        <IconBell />
                      </span>
                      {parseInt(this.state.unreadmsg) > 0 && (
                        <span className="qtylable">{this.state.unreadmsg}</span>
                      )}
                    </DropdownToggle>
                    <DropdownMenu className="minw-22 noti-dropdown">
                      <DropdownItem>
                        {this.state.unreadmsg} {t("topHeader.unread")}
                      </DropdownItem>
                      {OrderStatusNotificationList.length > 0 ? (
                        OrderStatusNotificationList
                      ) : (
                        <div className="text-center">No Data</div>
                      )}
                      <DropdownItem>
                        <Link to="/notification" style={{ color: "#FFF" }}>
                          {t("topHeader.viewAll")}
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLinks
                      to="/cart"
                      className="nav-link"
                      data-text={t("topHeader.Cart")}
                    >
                      <span className="nav-icon">
                        <IconCart />
                      </span>
                      {this.props.itemIncart.length > 0 && (
                        <span className="qtylable">
                          {this.props.itemIncart.length}
                        </span>
                      )}
                    </NavLinks>
                  </NavItem>
                  <NavItem>
                    <Dropdown
                      className="user-account-dropdown"
                      placement="bottomRight"
                      overlayClassName="user-account-dropdown-overlay"
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          {creditDetails && creditDetails.profile && (
                            <div className="img-profile">
                              <img src={UserMarkup} alt="image" />
                            </div>
                          )}
                          <div className="username-detail">
                            <span className="name">
                              {creditDetails && creditDetails.fullName}
                            </span>
                            {/* <span className="user-name">@username</span> */}
                          </div>
                          <svg
                            className="dropdown-arrow"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5999 7.45831L11.1666 12.8916C10.5249 13.5333 9.4749 13.5333 8.83324 12.8916L3.3999 7.45831"
                              stroke="#8C887F"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Space>
                      </a>
                    </Dropdown>
                  </NavItem>
                </Fragment>

                {/* // Mobile version */}
              </Nav>
            </div>
            <Nav className="mobile-display">
              <NavItem>
                <Suspense fallback={<Loader />}>
                  <Search
                    isMobile={false}
                    changeLanguage={this.props.changeLanguage}
                  />
                </Suspense>
              </NavItem>
              <NavItem>
                <NavLinks
                  style={{
                    paddingTop: "2px",
                  }}
                  to="/products"
                  className="nav-link"
                  data-text={t("topHeader.Products")}
                >
                  <span className="nav-icon icon-tires-mobile">
                    <span className="tires-tire"></span>
                  </span>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/orders"
                  className="nav-link"
                  data-text={t("topHeader.Orders")}
                >
                  <span className="nav-icon">
                    <IconOrder />
                  </span>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/cart"
                  className="nav-link"
                  data-text={t("topHeader.Cart")}
                >
                  <span className="nav-icon">
                    <IconCart />
                  </span>
                  {this.props.itemIncart.length > 0 && (
                    <span className="qtylable">
                      {this.props.itemIncart.length}
                    </span>
                  )}
                </NavLinks>
              </NavItem>
              <NavItem className="hamberger" onClick={this.showMenu}>
                <span className="nav-icon">
                  <IconHemberger />
                </span>
              </NavItem>
            </Nav>
          </Fragment>
        ) : (
          // header navigation logout
          <div className="header-logout">
            <Nav className={lang === "he" ? "mr-auto" : "ml-auto"} navbar>
              <NavItem>
                <Link
                  to="/brand"
                  className="nav-link"
                  data-text={t("topHeader.login")}
                >
                  {t("topHeader.brand")}
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/"
                  className="nav-link"
                  data-text={t("topHeader.login")}
                >
                  {t("topHeader.login")}
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/about"
                  className="nav-link"
                  data-text={t("topHeader.About")}
                >
                  {t("topHeader.About")}
                </Link>
              </NavItem>
              <NavItem>
                {this.props.isMobile ? (
                  <a href="tel:+972 74 723 23 00" className="nav-link">
                    {this.props.adminData &&
                      this.props.adminData.showCustomerSupport &&
                      t("topHeader.call")}
                  </a>
                ) : (
                  this.props.adminData &&
                  this.props.adminData.showCustomerSupport && (
                    <div
                      onClick={() => {
                        this.setState({ callModalToggle: true });
                      }}
                      className="nav-link cursor_pointer"
                    >
                      {t("topHeader.call")}
                    </div>
                  )
                )}
              </NavItem>
            </Nav>
          </div>
        )}

        <CallModal
          callModalToggle={this.state.callModalToggle}
          callModalClose={() => {
            this.setState({ callModalToggle: false });
          }}
          adminData={this.props.adminData}
        />
        <div className="mobile-navigation">
          <Navigation
            isMenuOn={this.state.isMenuOn}
            showMenu={this.showMenu}
            changeLanguage={this.props.changeLanguage}
            isLogout={this.props.isLogout}
            giveFeedbackModalClose={this.props.giveFeedbackModalClose}
          />
        </div>
      </Fragment>
    );
  }
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserPanel)
);
