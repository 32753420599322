import Auth from '../../models/Auth'
import * as Types from '../../constents/actionTypes'
import Axios from '../../models/Axios'
import { API_URL } from '../../constents/helpers'

/* Login Functionality */
export const SET_USER_LOGIN=(payload)=> async (dispatch) => {
    try {
        const {username,password} = payload
        if(!username || !password) {
            dispatch({ type: Types.AUTH_LOGIN_ERROR , authLoginError: `Please Provide User Information `});
            return
        }
        const authResponse = await Auth.loginWithUserNameAndPassword(payload)
        let {user_id} = authResponse;
        dispatch({type: Types.SET_TOKEN , user_id})
        dispatch({ type: Types.IS_USER_AUTHENTICATED , isUserAuthenticated: true, userId:user_id})
    } catch(error) {
        return dispatch({ type: Types.AUTH_LOGIN_ERROR , authLoginError: error.message});
    }
}

export const SET_LOGIN_AUTH_ERROR = (message) => (dispatch) => {
    dispatch({ type: Types.AUTH_LOGIN_ERROR , authLoginError: message});
}

export const SET_IS_USER_AUTHENTICATED=() =>(dispatch)=>{
    try{
        const authResponse = Auth.initialize()
        let {userId, isUserAuthenticated} = authResponse;
        
        dispatch({ type: Types.IS_USER_AUTHENTICATED , isUserAuthenticated: isUserAuthenticated, userId:userId})
    }
    
    catch(error){

    }
}

export const VERIFY_USER_EMAIL=(email, lang)=>async(dispatch)=>{
    try{
        const response = await Axios.axios.post(`${API_URL}resetPassword/?emailOrPhone=${email}&lang=${lang}`)
        if(response){
            // debugger
            dispatch({
                type: Types.VERIFY_USER_EMAIL,
                userverifyed:response.data,
                email:email
            })
        }
    }catch(error){
        dispatch({
            type: Types.VERIFY_USER_EMAIL_ERROR,
            error: error.response.data.details
        })
    }
}

export const RESEND_USER_EMAIL=(email)=>async(dispatch)=>{
    try{
        const response = await Axios.axios.post(`${API_URL}resetpassword/?emailOrPhone=${email}`)
        if(response){
            dispatch({
                type: Types.RESET_USER_EMAIL,
                resendverifyed:response.data,
            })
        }
    }catch(error){
        dispatch({
            type: Types.VERIFY_USER_EMAIL_ERROR,
            error: error.response.data.details
        })
    }
}

export function LOGOUT() {
    return dispatch => {
        Auth.logout()
        dispatch({ type: Types.ON_LOGOUT });
    }
}

export function CLEAR_AUTH_DATA(){
    return dispatch =>{
        dispatch({
            type:Types.CLEAR_AUTH_DATA
        })
    }
}