import {
  Card,
  CardBody,
  CardHeader,
  Input,
  FormGroup,
  Label,
  Button,
  Alert,
} from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { API_URL } from "../../constents/helpers";
import { connect } from "react-redux";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import showicon from "../../assets/eye.svg";
import Axios from "../../models/Axios";

const mapStateToProps = (state) => {
  return {
    adminData: state.Layout.adminData,
    lang: state.Layout.defaultLanguage,
  };
};

function Onboarding(props) {
  const [showPass, setShowPass] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation();
  const { token } = useParams();
  const history = useHistory();

  const submitUserInfo = useCallback(
    (e) => {
      e.preventDefault();
      setIsLoading(true);

      if (
        username.length >= 8 &&
        password.length >= 8 &&
        password === confirmPassword
      ) {
        Axios.axios
          .post(
            `${API_URL}set-username-password?lang=${props.lang}&token=${token}`,
            {
              username,
              password,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setSuccessMessage(t("onboardingCard.success"));
              setTimeout(() => {
                history.push("/home");
              }, 3000);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            if (err.response?.data) {
              if (err.response.data.message) {
                setErrorMessage(err.response.data.message);
              } else if (err.response.data.title) {
                setErrorMessage(err.response.data.title);
              } else if (err.response.data.detail) {
                setErrorMessage(err.response.data.detail);
              } else {
                setErrorMessage("Server Error");
              }
            } else {
              setErrorMessage("Server Error");
            }
            setIsLoading(false);
          });
      }
    },
    [
      username,
      password,
      token,
      props,
      confirmPassword,
      setIsLoading,
      history,
      t,
    ]
  );

  return (
    <div className="user-onboarding">
      <Card className="main-card">
        <CardHeader>
          <Link to="/" className="navbar-brand main-logo">
            {props.adminData && (
              <img
                src={
                  `${API_URL}var/assets` + props.adminData.desktopLogo.fullpath
                }
                alt="Tirecloud"
              />
            )}
          </Link>
        </CardHeader>
        <CardBody>
          <div className="lock-icon">
            <svg
              width="47"
              height="47"
              viewBox="0 0 47 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_125_13406)">
                <path
                  d="M9.25758 47H37.7424C38.9223 47 39.8788 46.0435 39.8788 44.8636V20.6515C39.8788 19.4717 38.9223 18.5152 37.7424 18.5152H35.6061V12.1061C35.6061 5.43078 30.1753 0 23.5 0C16.8247 0 11.3939 5.43078 11.3939 12.1061V18.5152H9.25758C8.07774 18.5152 7.12122 19.4717 7.12122 20.6515V44.8636C7.12122 46.0435 8.07774 47 9.25758 47ZM25.6364 33.4677V36.3182C25.6364 37.498 24.6798 38.4545 23.5 38.4545C22.3202 38.4545 21.3636 37.498 21.3636 36.3182V33.4677C20.4994 32.8175 19.9394 31.7837 19.9394 30.6212C19.9394 28.6579 21.5367 27.0606 23.5 27.0606C25.4633 27.0606 27.0606 28.6579 27.0606 30.6212C27.0606 31.7837 26.5006 32.8175 25.6364 33.4677ZM15.6667 12.1061C15.6667 7.78676 19.1807 4.27273 23.5 4.27273C27.8193 4.27273 31.3333 7.78676 31.3333 12.1061V18.5152H15.6667V12.1061Z"
                  fill="#E1E1E1"
                />
              </g>
              <defs>
                <clipPath id="clip0_125_13406">
                  <rect width="47" height="47" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="main-form">
            <h2>Set username and password</h2>
            <form onSubmit={submitUserInfo}>
              <FormGroup>
                <div className="form-label-group">
                  <Input
                    type="text"
                    id="userName"
                    placeholder={t("loginCard.username")}
                    name="username"
                    value={username}
                    required
                    invalid={username.length > 0 && username.length < 8}
                    autoFocus
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                  {/* <Label for="userName">{adminData && adminData.usernameLabel}</Label> */}
                  <Label for="userName">{t("loginCard.username")}</Label>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <Input
                    type={showPass ? "text" : "password"}
                    id="password"
                    placeholder={t("loginCard.password")}
                    name="password"
                    value={password}
                    invalid={
                      password.length > 0 &&
                      (password !== confirmPassword || password.length < 8)
                    }
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  {/* <Label for="password">{adminData && adminData.passwordLabel}</Label> */}
                  <Label for="password">{t("loginCard.password")}</Label>
                </div>
                <div
                  className="showpass"
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                >
                  <img src={showicon} alt="" width="20" />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <Input
                    type={showPass ? "text" : "password"}
                    id="password-confirm"
                    placeholder={t("changePasswordCard.confirmPassword")}
                    name="password"
                    value={confirmPassword}
                    invalid={
                      confirmPassword.length > 0 &&
                      (confirmPassword !== password ||
                        confirmPassword.length < 8)
                    }
                    required
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  {/* <Label for="password">{adminData && adminData.passwordLabel}</Label> */}
                  <Label for="password-confirm">
                    {t("changePasswordCard.confirmPassword")}
                  </Label>
                </div>
                <div
                  className="showpass"
                  onClick={() => {
                    setShowPass(!showPass);
                  }}
                >
                  <img src={showicon} alt="" width="20" />
                </div>
              </FormGroup>
              <FormGroup className="text-center">
                <Button
                  color="primary"
                  className="login-btn"
                  disabled={isLoading || password !== confirmPassword}
                >
                  {t("feedbackModal.cta")}
                  {/* {adminData && adminData.loginButtonLabel} */}
                </Button>
              </FormGroup>
            </form>
          </div>
        </CardBody>
      </Card>
      {errorMessage.length > 0 && <Alert color="danger">{errorMessage}</Alert>}
      {successMessage.length > 0 && (
        <Alert color="info">{successMessage}</Alert>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(Onboarding);
