import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../store/actions/layout";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import logout from "../../assets/logout.png";
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubMenuOn: false,
    };
  }
  showSubMenu = () => {
    this.setState({
      isSubMenuOn: !this.state.isSubMenuOn,
    });
  };
  hidemenu = () => {
    this.props.showMenu();
    this.props.isLogout();
  };

  onLanguageChangeHandler = (lang) => (event) => {
    this.props.DEFAULT_LANGUAGE(lang);
    this.props.showMenu();
    this.props.changeLanguage(lang);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { feedbackData, t } = this.props;
    if (feedbackData !== nextProps.feedbackData) {
      toast.success(t("tostMsg.feedbackmsg"));
    }
  }
  render() {
    const { t, feedbackQuestions } = this.props;
    return (
      <Fragment>
        <div
          className={this.props.isMenuOn ? "menu-box active" : "menu-box"}
          style={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          <div>
            <h3 className="text-right" style={{ padding: "0px 32px" }}>
              <span
                className="close icon-close"
                onClick={this.props.showMenu}
              ></span>
            </h3>
            <ul>
              <li>
                <Link to="/brand" onClick={this.props.showMenu}>
                  <span className="icon-brand mr-3"></span>{" "}
                  {t("topHeader.brand")}
                </Link>
              </li>
              <li>
                <Link to="/products" onClick={this.props.showMenu}>
                  <span className="tires-tire mr-3"></span>{" "}
                  {t("topHeader.products")}
                </Link>
              </li>
              <li>
                <Link to="/notification" onClick={this.props.showMenu}>
                  <span className="icon-bell mr-3"></span>
                  {t("topHeader.notifications")}
                </Link>
              </li>
              <li>
                <Link to="/accounting" onClick={this.props.showMenu}>
                  <span className="icon-user mr-3"></span>
                  {t("topHeader.account")}{" "}
                </Link>
              </li>
              <li>
                <a href="tel:+972 74 723 23 00" onClick={this.props.showMenu}>
                  <span className="icon-phone mr-3"></span>
                  {t("topHeader.call")}
                </a>
              </li>
              <li onClick={this.hidemenu} className="cursor_pointer">
                <span className="mr-3">
                  <img
                    alt="logout"
                    src={logout}
                    style={{ width: "16px" }}
                  ></img>
                </span>
                <Link to="#!">{t("topHeader.logout")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    feedbackData: state.Product.feedbackData,
    feedbackQuestions: state.Product.feedbackQuestions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    DEFAULT_LANGUAGE: (payload) => dispatch(DEFAULT_LANGUAGE(payload)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
