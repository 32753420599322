import axios from 'axios'

class Axios {
    static getInstance() {
        // Initialize instance
        if(!this._axios) {
            const _axios = axios.create({
                headers: {
                    'Content-Type' : 'application/json',
                }
                
            })
            this._axios = _axios
        }
        return this._axios
    }
    
}

export default Axios