import * as Types from "../../constents/actionTypes";
import _ from "lodash";

const defaultState = {
  userCartId: [],
  cartItem: "",
  cartList: [],
  filterOptions: null,
  filterList: [],
  listProducts: [],
  itemIncart: [],
  recentView: [],
  morelistEnd: false,
  searchData: "",
  shippingAddress: {
    lastUsedShippingAddress: null,
    userDefaultAddress: null,
    shippingAddresses: null,
  },
  promoDetails: null,
  moreProducts: [],
};
// const Storage = window.localStorage
const SessionStorage = window.sessionStorage;

const handler = (state = defaultState, action) => {
  //console.log(`state in auth is :`,state);
  switch (action.type) {
    case Types.CART_ITEMS: {
      return {
        ...state,
        cartItem: action.payload,
      };
    }
    case Types.FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: action.filterOptions,
      };
    }
    case Types.UPDATE_FILTER_OPTIONS: {
      return {
        ...state,
        updatedfilterOptions: action.updatedfilterOptions,
        // filterOptions:action.updatedfilterOptions,
        selectedFilters: action.selectedFilters,
      };
    }
    case Types.FILTER_PRODUCT: {
      return {
        ...state,
        listProducts: action.filterList,
        selectedFilters: action.selectedFilters,
      };
    }
    case Types.SELECT_WAREHOUSE: {
      return {
        ...state,
        selectedWarehouse: action.selectedWarehouse,
      };
    }
    case Types.FILTER_PRODUCT_ERROR: {
      if (action.error) {
        return {
          ...state,
          morelistMsg: action.error,
        };
      }
      return {
        ...state,
        morelistEnd: action.morelistEnd,
        morelistMsg: action.morelistMsg,
      };
    }
    case Types.LIST_PRODUCT: {
      return {
        ...state,
        listProducts: action.listProducts,
        selectedFilters: action.selectedFilters,
        morelistEnd: action.morelistEnd,
      };
    }
    case Types.LOADMORE_PRODUCT: {
      const finalList = state.listProducts.concat(action.moreProducts);
      return {
        ...state,
        listProducts: finalList,
        moreProducts: action.moreProducts,
      };
    }
    case Types.LOADMORE_PRODUCT_WITH_SEARCH: {
      const finalList = state.listProducts.concat(action.moreProducts2);
      return {
        ...state,
        listProducts: finalList,
        moreProducts: action.moreProducts2,
      };
    }
    case Types.SEARCH_PRODUCTS: {
      // let newselectedFilters = state.selectedFilters.concat(action.searchData)
      return {
        ...state,
        listProducts: action.searchedProductsList,
        searchedProductsListat: new Date().getTime(),
        warehouse: action.warehouse,
        searchData: action.searchData,
      };
    }
    case Types.CREATE_CART: {
      SessionStorage.setItem(
        "cartId",
        btoa(JSON.stringify(action.createdCartDetails.id))
      );
      return {
        ...state,
        createdCartDetails: action.createdCartDetails,
        userCartId: action.createdCartDetails,
        invoiceStatus: undefined,
        shipAddress: undefined,
      };
    }
    case Types.UPDATE_CART: {
      const cartId = JSON.parse(atob(SessionStorage.getItem("cartId")));
      return {
        ...state,
        // createdCartDetails:action.createdCartDetails,
        updateCartDetails: action.updateCartDetails,
        cartId: cartId,
      };
    }
    case Types.GET_CART_DETAIL: {
      return {
        ...state,
        itemIncart: action.itemIncart ? action.itemIncart : [],
      };
    }
    case Types.GET_PROMOS: {
      return {
        ...state,
        promoList: action.payload,
      };
    }
    case Types.CONFIRM_ORDER: {
      return {
        ...state,
        orderItemsList: action.payload,
      };
    }
    case Types.CONFIRM_CALLOFF: {
      return {
        ...state,
        callOffOrderDetails: action.orderDetails,
      };
    }
    case Types.CONFIRM_CALLOFF_ERROR: {
      return {
        ...state,
        callOffOrderError: action.callOffOrderError,
      };
    }
    case Types.CREATE_ORDER_STATUS: {
      return {
        ...state,
        orderStatus: action.orderStatus,
      };
    }
    case Types.CREATE_INVOICE: {
      return {
        ...state,
        invoiceStatus: action.invoiceStatus,
      };
    }
    case Types.PROMO_VALIDATE: {
      return {
        ...state,
        promoDetails: action.promoDetails,
      };
    }
    case Types.PROMO_VALIDATE_ERROR: {
      // debugger
      return {
        ...state,
        promoValidateError: action.error,
      };
    }
    case Types.CREATE_ORDER: {
      return {
        ...state,
        orderDetails: action.orderDetails,
      };
    }
    case Types.ADD_RECENT_VIEW: {
      return {
        ...state,
        recentAdded: action.recentAdded,
      };
    }
    case Types.GET_RECENT_VIEW: {
      return {
        ...state,
        recentView: action.recentView ? action.recentView : [],
      };
    }
    case Types.LIST_PRODUCT_ERROR: {
      // debugger
      return {
        ...state,
        morelistEnd: true,
        morelistMsg: action.error,
      };
    }
    case Types.SEND_MAIL: {
      return {
        ...state,
        mailsend: action.payload,
      };
    }
    case Types.SEND_MAIL_ERROR: {
      return {
        ...state,
        mailsendError: action.errorMsg,
      };
    }
    case Types.SEND_MAIL_CALLOFF: {
      return {
        ...state,
        mailsendCallOff: action.payload,
      };
    }
    case Types.EXPORT_ORDER: {
      return {
        ...state,
        exportDetails: action.exportDetails,
      };
    }
    case Types.EXPORT_CALLOFF: {
      return {
        ...state,
        calloffExportDetails: action.calloffExportDetails,
      };
    }
    case Types.CLEAR_FILTERS: {
      return {
        ...state,
        searchData: null,
        selectedWarehouse: undefined,
      };
    }
    case Types.UPDATE_ADD_TO_CART_QUANTITY: {
      const updateAddToCartQtyListProductState = _.cloneDeep(
        state.listProducts
      );
      const updateAddToCartQtyItemIndex =
        updateAddToCartQtyListProductState.findIndex(
          (item) => item.id === action.payload.itemId
        );
      let updateAddToCartQtyItem =
        updateAddToCartQtyListProductState[updateAddToCartQtyItemIndex];
      updateAddToCartQtyItem.addToCartQty = action.payload.quantity;
      updateAddToCartQtyListProductState[updateAddToCartQtyItemIndex] =
        updateAddToCartQtyItem;

      return {
        ...state,
        listProducts: _.cloneDeep(updateAddToCartQtyListProductState),
      };
    }
    case Types.UPDATE_RESERVED_STOCK_QUANTITY: {
      const updateReservedStockCartItemsState = _.cloneDeep(state.itemIncart);
      const uupdateReservedStockCartItemIndex =
        updateReservedStockCartItemsState.findIndex(
          (item) => item.id === action.payload.itemId
        );
      let updateReservedStockItem =
        updateReservedStockCartItemsState[uupdateReservedStockCartItemIndex];
      updateReservedStockItem.reservedStockQuantity = action.payload.quantity;
      updateReservedStockCartItemsState[uupdateReservedStockCartItemIndex] =
        updateReservedStockItem;

      return {
        ...state,
        itemIncart: _.cloneDeep(updateReservedStockCartItemsState),
      };
    }
    case Types.UPDATE_RECENTLY_VIEWED_PRODUCT_QUANTITY: {
      const updateRecentlyViewedProductItemsState = _.cloneDeep(
        state.recentView
      );

      if (
        updateRecentlyViewedProductItemsState &&
        updateRecentlyViewedProductItemsState.length
      ) {
        const uupdateRecentlyViewedProductItemIndex =
          updateRecentlyViewedProductItemsState.findIndex((item) => {
            for (const size of item.tireSize) {
              if (size.id === action.payload.itemId) {
                return true;
              }
            }
            return false;
          });

        let updateRecentlyViewedProductItem =
          updateRecentlyViewedProductItemsState[
            uupdateRecentlyViewedProductItemIndex
          ];

        updateRecentlyViewedProductItem.recentlyViewedProductItemQuantity =
          action.payload.quantity;
        updateRecentlyViewedProductItemsState[
          uupdateRecentlyViewedProductItemIndex
        ] = updateRecentlyViewedProductItem;
      }

      return {
        ...state,
        recentView: _.cloneDeep(updateRecentlyViewedProductItemsState),
      };
    }

    case Types.UPDATE_CART_ITEM:
      const updateReservedStockCartItemsState = _.cloneDeep(state.itemIncart);
      const updateCartItemIndex = updateReservedStockCartItemsState.findIndex(
        (item) => item.id === action.itemiId
      );
      let updateItem = updateReservedStockCartItemsState[updateCartItemIndex];
      updateItem.quantity = action.updatedQuantity
        ? action.updatedQuantity
        : updateReservedStockCartItemsState[updateCartItemIndex].quantity;
      // debugger
      // updateItem.specialPrice = action.itemIncart[updateCartItemIndex].specialPrice?action.itemIncart[updateCartItemIndex].specialPrice:updateReservedStockCartItemsState[updateCartItemIndex].specialPrice
      // updateItem.disableCounter = false
      updateReservedStockCartItemsState[updateCartItemIndex] = updateItem;
      // debugger

      // console.log(updateReservedStockCartItemsState)

      updateReservedStockCartItemsState.forEach((item) => {
        if (item.disableCounter) {
          item.disableCounter = false;
        }
      });

      return {
        ...state,
        UpdatedItemId: action.itemiId,
        itemIncart: action.itemIncart
          ? _.cloneDeep(updateReservedStockCartItemsState)
          : [],
        // itemIncart: action.itemIncart ? action.itemIncart : []
      };
    case Types.CLEAR_UPDATEDITEM:
      return {
        ...state,
        UpdatedItemId: action.UpdatedItemId,
      };
    case Types.GET_USER_CART_ID:
      // debugger
      if (action.payload.length) {
        SessionStorage.setItem(
          "cartId",
          btoa(JSON.stringify(action.payload[0].id))
        );
      }
      return {
        ...state,
        userCartId: action.payload,
      };
    case Types.GET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: { ...action.payload },
      };
    case Types.GET_SHIPPING_ADDRESS_2:
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          shippingAddresses: [...action.payload.shippingAddresses],
        },
      };
    case Types.SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shipAddressId: action.shipAddressId,
      };
    case Types.SHIPPING_ADDRESS: {
      return {
        ...state,
        shipAddressId: action.shipAddressId,
      };
    }
    case Types.UPDATE_CART_ITEM_DISABLE:
      const updateCartItemDisableItems = _.cloneDeep(state.itemIncart);
      const updateCartItemDisableItemIndex =
        updateCartItemDisableItems.findIndex(
          (item) => item.id === action.payload.id
        );
      if (updateCartItemDisableItemIndex !== -1) {
        let updateCartItemDisableItem =
          updateCartItemDisableItems[updateCartItemDisableItemIndex];
        updateCartItemDisableItem.disableCounter = action.payload.value;
        updateCartItemDisableItems[updateCartItemDisableItemIndex] =
          updateCartItemDisableItem;
      }
      return {
        ...state,
        itemIncart: _.cloneDeep(updateCartItemDisableItems),
      };
    case Types.CREATE_CART_ITEM:
      return {
        ...state,
        itemIncart: action.payload ? action.payload : [],
      };
    case Types.GENERATE_ORDER_ITEMNUMBER: {
      return {
        ...state,
        orderItemNumber: action.orderItemNumber,
      };
    }
    case Types.GENERATE_INVOICE_NUMBER: {
      return {
        ...state,
        invoiceNumber: action.invoiceNumber,
      };
    }
    case Types.GENERATE_ORDER_NUMBER: {
      return {
        ...state,
        orderNumber: action.orderNumber,
      };
    }
    case Types.UPDATE_RESERVED_STOCK: {
      return {
        ...state,
        updateReservedStock: action.payload,
      };
    }
    case Types.UPDATE_PRICE: {
      // debugger
      const updatePriceListProductState = _.cloneDeep(state.listProducts);
      const updatePriceItemIndex = updatePriceListProductState.findIndex(
        (item) => item.id === action.payload.itemId
      );
      let updatePriceItem = updatePriceListProductState[updatePriceItemIndex];
      updatePriceItem.selectedPrice = action.payload.selectPrice;
      updatePriceListProductState[updatePriceItemIndex] = updatePriceItem;

      return {
        ...state,
        listProducts: _.cloneDeep(updatePriceListProductState),
      };
    }
    case Types.RECENT_UPDATE_PRICE: {
      const updateRecentlyViewedProductItemsState = _.cloneDeep(
        state.recentView
      );
      if (updateRecentlyViewedProductItemsState.length) {
        const uupdateRecentlyViewedProductItemIndex =
          updateRecentlyViewedProductItemsState.findIndex(
            (item) => item.tireSize[0].id === action.payload.itemId
          );
        let updateRecentlyViewedProductItem =
          updateRecentlyViewedProductItemsState[
            uupdateRecentlyViewedProductItemIndex
          ];
        updateRecentlyViewedProductItem.tireSize[0].selectedPrice =
          action.payload.selectPrice;
        updateRecentlyViewedProductItemsState[
          uupdateRecentlyViewedProductItemIndex
        ] = updateRecentlyViewedProductItem;
      }

      return {
        ...state,
        recentView: _.cloneDeep(updateRecentlyViewedProductItemsState),
      };
    }
    case Types.NORTIFY_ME: {
      return {
        ...state,
        nortifymeSuccess: action.payload,
      };
    }
    case Types.DELETE_ITEM: {
      return {
        ...state,
        deleteItem: new Date().getTime(),
        verifyVoucher: null,
      };
    }
    case Types.VERIYFY_VOUCHER: {
      let voucherData = action.verifyVoucher;
      return {
        ...state,
        verifyVoucher: voucherData,
      };
    }
    case Types.GET_PROMO_VOUCHER: {
      return {
        ...state,
        promoVoucher: action.promoVoucher,
      };
    }
    case Types.VALIDATE_USER_FOR_FIRSTTIME_PROMO: {
      return {
        ...state,
        userValidated: action.userValidated,
      };
    }
    case Types.CLEAR_DATA: {
      return {
        ...state,
        userCartId: [],
        selectedFilters: {},
        searchData: "",
        cartItem: "",
        cartList: [],
        filterList: [],
        listProducts: [],
        itemIncart: [],
        recentView: [],
        morelistEnd: false,
        createdCartDetails: null,
        promoDetails: null,
        shippingAddress: {
          lastUsedShippingAddress: null,
          userDefaultAddress: null,
          shippingAddresses: null,
        },
      };
    }
    default:
      return state;
  }
};

export default handler;
