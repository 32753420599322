import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// import translationEN from '../src/locales/en/translation.json';
// import translationDE from '../src/locales/en/translation.json';
// // the translations
// const resources = {
//   en: {
//     translation: translationEN
//   },
//   de: {
//     translation: translationDE
//   }
// };

const fallbackLng = ['en']; 
const availableLanguages = ['en', 'he', 'de'];

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng,
    // resources,
    debug: process.env.NODE_ENV !== "production",
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
