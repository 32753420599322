export const GET_PROMO_VALIDATE_QUERY = (filter) => {
  return {
    query: `{
    getPromotionCodeListing(${filter}) {
      edges {
        node {
          id
          isActive
          code
          promotion {
            ... on object_promotion {
              id
              promotionCodeValidDate
              promotionCodeValidDateValue
              promotionCodeValidDays
              promotionCodeValidDaysValue
              discountType
              discountTypeValue
              validFrom
              validUntil
            }
          }
        }
      }
    }
  }
  `,
  };
};

export const GET_PRODUCT_DETAIL_QUERY = (productId, lang) => `{
    getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      id
      width
      height
      diameter
      contructionType
      loadRating
      loadRating1
      speedRating
      speedRating1
      speedRating2
      suffix
      loadCapacity
      oeKey
      dots {
        ... on object_tireDot {
          id
          price
          stock
          specialPrice {
            ... on object_specialPrice {
              specialPrice
              id
              validFrom
              validTo
              minPurchaseQuantity
              user{
                ... on object_user{
                  id
                }
              }
            }
          }
        }
      }
      wetGrip
      fuelEfficiency
      rollingNoise
      noisePerformance
      code
      promotion {
        ... on object_promotion {
          id
          minPurchaseType
          minPurchaseValue
          discountType
          discountTypeValue
          freeShipping
          includeCheapestProductFree
          freeProductQuantity
          maxDiscountAmount
          freeTire {
            ... on object_freeTire {
              id
              stock
              tire {
                ... on object_tireSize {
                  id
                  width
                  height
                  diameter
                  contructionType
                  loadRating
                  loadRating1
                  speedRating
                  speedRating1
                  speedRating2
                  suffix
                  loadCapacity
                  oeKey
                  tire {
                    ... on object_tire {
                      title
                      sizeDescription
                      frFlag
                      ssrMark
                      evKey
                    }
                  }
                }
              }
            }
          }
          providePromotionCode
          promotionCodeValidDays
          promotionCodeValidDaysValue
          promotionCodeValidDate
          promotionCodeValidDateValue
          validFrom
          validUntil
          title
          userGroup {
            ... on object_userGroup {
              id
              groupType
            }
          }
        }
      }
    }
  }`;

export const GET_PRODUCT_IMAGES_QUERY = (productId, lang) => {
  return `{
    getTireSize(id:  ${productId}, defaultLanguage: "${lang}") {
      mediaFiles {
        ... on object_tireSize_mediaFiles {
          element {
            selectType
            image {
              image {
                id
                fullpath
              }
            }
            video {
              poster {
                fullpath
              }
              title
              type
              description
            }
            thumbnail {
              image {
                id
                fullpath
              }
            }
            extThumbnail
            extImageorVideo
          }
        }
      }
      productLine {
        ... on object_productLine {
          id
          productLineName
          image{
            image{
              id
              fullpath
            }
          }
        }
      }
    }
  }`;
};

export const GET_PRODUCT_TIRE_DETAIL_QUERY = (productId, lang) => {
  return `{
    getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
        ... on object_tire {
          id
          detailLabel
          title
          sizeDescription
          frFlag
          ssrMark
          description1
          description2
          pmsf
          icePict
          factbook {
            ... on object_factbook {
              factbookURL
              title
              target
            }
          }
          segment {
            ... on object_segment {
              name
            }
          }
          brand {
            ... on object_brand {
              brandName
            }
          }
          certifiedBy {
            ... on object_certifiedBy {
              tooltip
              icon {
                fullpath
              }
            }
          }
        }
      }
      }
    }`;
};

export const GET_PRODUCT_CATEGORIES_QUERY = (productId, lang) => {
  return `{getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
    tire {
        tireCategory {
            ... on object_tireCategory {
              tooltip
              icon {
                fullpath
              }
            }
          }
      }
    }
}`;
};

export const GET_PRODUCT_TECH_QUERY = (productId, lang) => {
  return `{getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
    tire {
        technologyLabel
        technology {
          ... on object_tire_technology {
            element {
              title
              description
              image {
                image {
                  fullpath
                }
              }
            }
          }
        }
      }
    }
}`;
};

export const GET_PRODUCT_FEATURES_QUERY = (productId, lang) => {
  return `{getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
          featureandBenefitLabel
          featureandBenefit {
            ... on object_tire_featureandBenefit {
              element {
                title
                description
                image {
                  image {
                    fullpath
                  }
                }
              }
            }
          }
        }
      }
  }`;
};

export const GET_PRODUCT_EU_LABELS_QUERY = (productId, lang) => {
  return `{getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
          euTireLabelLabel
          euTireLabel {
          ... on object_tire_euTireLabel {
              element {
              title
              description
              image {
                  image {
                  fullpath
                  }
              }
              }
          }
          }
        }
      }
  }`;
};

export const GET_PRODUCT_PRESS_QUERY = (productId, lang) => {
  return `{getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
          euTireLabelLabel
          euTireLabel {
          ... on object_tire_euTireLabel {
              element {
              title
              description
              image {
                  image {
                  fullpath
                  }
              }
              }
          }
          }
        }
      }
  }`;
};

export const GET_PRODUCT_DETAIL_ALL_DATA_QUERY = (productId, lang) => {
  return `{
    getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      id
      width
      height
      diameter
      contructionType
      loadRating
      loadRating1
      speedRating
      speedRating1
      speedRating2
      suffix
      loadCapacity
      oeKey
      dots {
        ... on object_tireDot {
          id
          price
          stock
          yearOfManufacture
          specialPrice {
            ... on object_specialPrice {
              specialPrice
              id
              validFrom
              validTo
              minPurchaseQuantity
              user{
                ... on object_user{
                  id
                }
              }
            }
          }
        }
      }
      wetGrip
      fuelEfficiency
      rollingNoise
      noisePerformance
      code
      promotion {
        ... on object_promotion {
          id
          minPurchaseType
          minPurchaseValue
          discountType
          discountTypeValue
          freeShipping
          includeCheapestProductFree
          freeProductQuantity
          maxDiscountAmount
          freeTire {
            ... on object_freeTire {
              id
              stock
              tire {
                ... on object_tireSize {
                  id
                  width
                  height
                  diameter
                  contructionType
                  loadRating
                  loadRating1
                  speedRating
                  speedRating1
                  speedRating2
                  suffix
                  loadCapacity
                  oeKey
                  tire {
                    ... on object_tire {
                      title
                      sizeDescription
                      frFlag
                      ssrMark
                      evKey
                    }
                  }
                }
              }
            }
          }
          providePromotionCode
          promotionCodeValidDays
          promotionCodeValidDaysValue
          promotionCodeValidDate
          promotionCodeValidDateValue
          validFrom
          validUntil
          title
          userGroup {
            ... on object_userGroup {
              id
              groupType
            }
          }
        }
      }
    }
    getProductImage: getTireSize(id:  ${productId}, defaultLanguage: "${lang}") {
      mediaFiles {
        ... on object_tireSize_mediaFiles {
          element {
            selectType
            image {
              image {
                id
                fullpath
              }
            }
            video {
              poster {
                fullpath
              }
              title
              type
              description
            }
            thumbnail {
              image {
                id
                fullpath
              }
            }
            extThumbnail
            extImageorVideo
          }
        }
      }
      productLine {
        ... on object_productLine {
          id
          productLineName
          image{
            image{
              id
              fullpath
            }
          }
        }
      }
    }
    getTireDetail: getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
        ... on object_tire {
          id
          detailLabel
          title
          sizeDescription
          frFlag
          ssrMark
          description1
          description2
          pmsf
          icePict
          evKey
          factbook {
            ... on object_factbook {
              factbookURL
              title
              target
            }
          }
          segment {
            ... on object_segment {
              name
            }
          }
          brand {
            ... on object_brand {
              brandName
            }
          }
          certifiedBy {
            ... on object_certifiedBy {
              tooltip
              icon {
                fullpath
              }
            }
          }
        }
      }
    }
    getProductTech: getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
        technologyLabel
        technology {
          ... on object_tire_technology {
            element {
              title
              description
              image {
                image {
                  fullpath
                }
              }
            }
          }
        }
      }
    }
    getProductFeatures: getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
        featureandBenefitLabel
        featureandBenefit {
          ... on object_tire_featureandBenefit {
            element {
              title
              description
              image {
                image {
                  fullpath
                }
              }
            }
          }
        }
      }
    }
    getEULabels: getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
        euTireLabelLabel
        euTireLabel {
          ... on object_tire_euTireLabel {
            element {
              title
              description
              image {
                image {
                  fullpath
                }
              }
            }
          }
        }
      }
    }
    getPress: getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
        euTireLabelLabel
        euTireLabel {
          ... on object_tire_euTireLabel {
            element {
            title
            description
            image {
              image {
                fullpath
                }
              }
            }
          }
        }
      }
    }
    getCategories: getTireSize(id: ${productId}, defaultLanguage: "${lang}") {
      tire {
        tireCategory {
          ... on object_tireCategory {
            tooltip
            icon {
              fullpath
            }
          }
        }
      }
    }
  }`;
};

export const GET_PRODUCT_FEATURE_ITEM_QUERY = (lang, currentDate, currentTime) => {
  return `{getTireSizeListing(defaultLanguage: "${lang}", filter: "{\\"showasFeaturedProduct\\" : \\"1\\", \\"validFrom\\": {\\"$lte\\" :\\"${currentDate} ${currentTime}\\"}, \\"validUntil\\": {\\"$gte\\" :\\"${currentDate} ${currentTime}\\"} }") {
    edges {
      node {
        id
        width
        height
        diameter
        contructionType
        loadRating
        loadRating1
        speedRating
        speedRating1
        speedRating2
        suffix
        loadCapacity
        oeKey
        validFrom
        validUntil
        featureProductLabel
        dots {
          ... on object_tireDot {
            price
            stock
            specialPrice {
              ... on object_specialPrice {
                id
                validTo
                validFrom
                specialPrice
                minPurchaseQuantity
                user {
                    ... on object_user{
                      id
                    }
                  }
                userGroup {
                  ... on object_userGroup {
                    id
                    groupType
                  }
                }
              }
            }
          }
        }
        tire {
          ... on object_tire {
            id
            title
            sizeDescription
            frFlag
            ssrMark
            evKey
            segment {
              ... on object_segment {
                name
              }
            }
            brand {
              ... on object_brand {
                brandName
              }
            }
            certifiedBy {
              ... on object_certifiedBy {
                tooltip
                icon {
                  fullpath
                }
              }
            }
          }
        }
        promotion {
          ... on object_promotion {
            id
            minPurchaseType
            minPurchaseValue
            discountType
            discountTypeValue
            freeShipping
            includeCheapestProductFree
            freeProductQuantity
            maxDiscountAmount
            freeTire {
              ... on object_freeTire {
                id
                stock
                tire {
                  ... on object_tireSize {
                    code
                    width
                    height
                    diameter
                    contructionType
                    loadRating
                    loadRating1
                    speedRating
                    speedRating1
                    speedRating2
                    suffix
                    loadCapacity
                    oeKey
                    tire {
                      ... on object_tire {
                        title
                        sizeDescription
                        frFlag
                        ssrMark
                      }
                    }
                  }
                }
              }
            }
            providePromotionCode
            promotionCodeValidDays
            promotionCodeValidDaysValue
            promotionCodeValidDate
            promotionCodeValidDateValue
            validFrom
            validUntil
            title
            userGroup {
              ... on object_userGroup {
                id
                groupType
              }
            }
          }
        }
      }
    }
  }
}`;
};

export const GET_PRODUCT_FILTER_QUERY = () => {
  return `{
    getTireSizeListing {
      edges {
        node {
          speedRating
          matchCode
          loadRating
          dots {
            ... on object_tireDot {
              stock
            }
          }
        }
      }
    }
  }`;
};

export const GET_PRODUCT_RECENTLY_VIEW_QUERY = (userId, lang) => {
  return `{
    getUser(id: ${userId}, defaultLanguage: "${lang}") {
        recentView {
        ... on object_recentView {
          tireSize {
            ... on object_tireSize {
              id
              width
              height
              diameter
              contructionType
              loadRating
              loadRating1
              speedRating
              speedRating1
              speedRating2
              suffix
              loadCapacity
              oeKey
              validFrom
              validUntil
              dots {
                ... on object_tireDot {
                  price
                  stock
                  dotCode
                  specialPrice {
                    ... on object_specialPrice {
                      specialPrice
                      id
                      validFrom
                      validTo
                      minPurchaseQuantity
                      user{
                        ... on object_user{
                          id
                        }
                      }
                    }
                  }
                }
              }
              tire {
                ... on object_tire {
                  id
                  title
                  sizeDescription
                  frFlag
                  ssrMark
                  isForSale
                  evKey
                  segment {
                    ... on object_segment {
                      name
                    }
                  }
                  brand {
                    ... on object_brand {
                      brandName
                    }
                  }
                  certifiedBy {
                       ... on object_certifiedBy {
                      tooltip
                      icon {
                        fullpath
                      }
                    }             
                  }
                }
              }
              promotion {
                ... on object_promotion {
                  id
                  minPurchaseType
                  minPurchaseValue
                  discountType
                  discountTypeValue
                  freeShipping
                  includeCheapestProductFree
                  freeProductQuantity
                  maxDiscountAmount
                  freeTire {
                    ... on object_freeTire {
                      id
                      stock
                      tire {
                        ... on object_tireSize {
                          id
                          width
                          height
                          diameter
                          contructionType
                          loadRating
                          loadRating1
                          speedRating
                          speedRating1
                          speedRating2
                          suffix
                          loadCapacity
                          oeKey
                          tire {
                            ... on object_tire {
                              title
                              sizeDescription
                              frFlag
                              ssrMark
                            }
                          }
                        }
                      }
                    }
                  }
                  providePromotionCode
                  promotionCodeValidDays
                  promotionCodeValidDaysValue
                  promotionCodeValidDate
                  promotionCodeValidDateValue
                  validFrom
                  validUntil
                  title
                }
              }
            }
          }
        }
      }
    }
  }`;
};
