import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import reducer from "../store/reducer";
import thunk from "redux-thunk";

import { createLogger } from "redux-logger";
const logger = createLogger();
const envt = process.env.REACT_APP_ENVIROMENT;
let allStoreEnhancers;
if (
  envt === "DEVELOP" ||
  envt === "ALPHA" ||
  envt === "BETA" ||
  envt === "STAGE"
) {
  allStoreEnhancers = compose(
    applyMiddleware(thunk, logger),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f) => f
  );
} else {
  allStoreEnhancers = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f) => f
  );
}

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducer,
    }),
    allStoreEnhancers
  );
}
