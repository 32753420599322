import React, { Component, Fragment } from "react";
import { Row, Col, Label, FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  FILTER_PRODUCT,
  UPDATE_FILTER_OPTIONS,
  GET_FILTER_OPTIONS,
  REMOVE_SEARCH,
} from "../../store/actions/cart";
import { FILTER_ORDER } from "../../store/actions/userDetail";
import { withTranslation } from "react-i18next";
import Loader from "../../Layout/Loader";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import he from "date-fns/locale/he";
registerLocale("he", he);

// import localization from 'moment/locale/he'
let _move = (arr, from, to) =>
  arr.map((item, i) =>
    i === to
      ? arr[from]
      : i >= Math.min(from, to) && i <= Math.max(from, to)
      ? arr[i + Math.sign(to - from)]
      : item
  );

const mapStateToProps = (state) => {
  return {
    selectedFilters: state.Cart.selectedFilters,
    searchData: state.Cart.searchData,
    filterOptions: state.Cart.filterOptions,
    updatedfilterOptions: state.Cart.updatedfilterOptions,
    lang: state.Layout.defaultLanguage,
    userId: state.Auth.userId,
    listProducts: state.Cart.listProducts,
    morelistMsg: state.Cart.morelistMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UPDATE_FILTER_OPTIONS: (payload) =>
      dispatch(UPDATE_FILTER_OPTIONS(payload)),
    FILTER_PRODUCT: (payload) => dispatch(FILTER_PRODUCT(payload)),
    FILTER_ORDER: (payload) => dispatch(FILTER_ORDER(payload)),
    GET_FILTER_OPTIONS: (lang) => dispatch(GET_FILTER_OPTIONS(lang)),
    REMOVE_SEARCH: () => dispatch(REMOVE_SEARCH()),
  };
};

class FiltersSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: "",
      width: "",
      height: "",
      inch: "",
      segment: "",
      load: "",
      speed: "",
      minprice: 0,
      maxprice: 0,
      brand: "",
      promotion: "",
      orderkey: "",
      order: "asc",
      orderDate: "",
      orderStatus: "",
      orderShipment: "",
      Passenger: false,
      Trucks: false,
      Others: false,
      filterOptions: null,
      isLoading: false,
      updatedfilterOptions: null,
    };
  }
  // componentDidMount() {
  //   debugger
  //   this.setState({
  //       filterOptions:this.props.filterOptions
  //   })
  // }
  UNSAFE_componentWillReceiveProps(nextprops) {
    const {
      filterOptions,
      searchData,
      updatedfilterOptions,
      lang,
      listProducts,
      morelistMsg,
    } = this.props;

    if (filterOptions !== nextprops.filterOptions) {
      // debugger
      this.setState({
        filterOptions: nextprops.filterOptions,
      });
      this.getBrand(nextprops.filterOptions);
    }
    if (lang !== nextprops.lang) {
      this.props.GET_FILTER_OPTIONS(nextprops.lang);
    }
    if (nextprops.selectedFilters !== undefined) {
      if (
        nextprops.selectedFilters.width ||
        nextprops.selectedFilters.height ||
        nextprops.selectedFilters.inch ||
        nextprops.selectedFilters.segment ||
        nextprops.selectedFilters.load ||
        nextprops.selectedFilters.speed ||
        nextprops.selectedFilters.brand ||
        nextprops.selectedFilters.promotion ||
        nextprops.selectedFilters.orderkey ||
        nextprops.selectedFilters.Others ||
        nextprops.selectedFilters.Passenger ||
        nextprops.selectedFilters.Trucks
      ) {
        // debugger
        this.setState({
          width: nextprops.selectedFilters.width
            ? nextprops.selectedFilters.width
            : "",
          height: nextprops.selectedFilters.height
            ? nextprops.selectedFilters.height
            : "",
          inch: nextprops.selectedFilters.inch
            ? nextprops.selectedFilters.inch
            : "",
          segment: nextprops.selectedFilters.segment
            ? nextprops.selectedFilters.segment
            : "",
          load: nextprops.selectedFilters.load
            ? nextprops.selectedFilters.load
            : "",
          speed: nextprops.selectedFilters.speed
            ? nextprops.selectedFilters.speed
            : "",
          brand: nextprops.selectedFilters.brand
            ? nextprops.selectedFilters.brand
            : "",
          promotion: nextprops.selectedFilters.promotion
            ? nextprops.selectedFilters.promotion
            : "",
          orderkey: nextprops.selectedFilters.orderkey,
        });
      } else {
        this.clear();
        // debugger
      }
    }

    if (searchData !== nextprops.searchData) {
      // debugger
      if (
        (nextprops.searchData && nextprops.searchData.includes("Truck")) ||
        (nextprops.searchData && nextprops.searchData.includes("Trucks"))
      ) {
        this.setState({
          Trucks: true,
        });
      }
      if (
        (nextprops.searchData && nextprops.searchData.includes("Passenger")) ||
        (nextprops.searchData && nextprops.searchData.includes("Passengers"))
      ) {
        this.setState({
          Passenger: true,
        });
      } else {
        this.setState({
          searchData: nextprops.searchData,
        });
      }
      setTimeout(() => {
        this.getupdatedData();
      }, 500);
    }
    if (updatedfilterOptions !== nextprops.updatedfilterOptions) {
      this.setState({
        updatedfilterOptions: nextprops.updatedfilterOptions,
        brandList: nextprops.updatedfilterOptions.brand,
      });
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
    if (listProducts !== nextprops.listProducts) {
      this.setState({
        isLoading: false,
      });
    }
    if (!listProducts && !nextprops.listProducts) {
      this.setState({
        isLoading: false,
      });
    }
    if (morelistMsg !== nextprops.morelistMsg) {
      this.setState({
        isLoading: false,
      });
    }
  }
  applyFilter = () => {
    const filters = this.state;
    const payload = {
      userId: this.props.userId,
      width: filters.width,
      height: filters.height,
      inch: filters.inch,
      segment: filters.segment,
      load: filters.load,
      speed: filters.speed,
      brand: filters.brand,
      promotion: filters.promotion,
      orderkey: filters.orderkey,
      order: filters.order,
      searchData: filters.searchData,
      lang: this.props.lang,
      Passenger: filters.Passenger,
      Trucks: filters.Trucks,
      Others: filters.Others,
    };
    this.setState({ isLoading: true });
    this.props.FILTER_PRODUCT(payload);
    this.props.showfilters();
  };
  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.getupdatedData();
    }, 500);
  };

  onPromotionChange = (e) => {
    this.setState({
      promotion: e.target.value === "2" ? 2 : 1,
    });
    setTimeout(() => {
      this.getupdatedData();
    }, 500);
  };

  selectOthersegemt = (segment) => {
    if (segment === "Passenger") {
      this.setState({
        Passenger: !this.state.Passenger,
      });
    }
    if (segment === "Trucks") {
      this.setState({
        Trucks: !this.state.Trucks,
      });
    }
    if (segment === "Others") {
      this.setState({
        Others: !this.state.Others,
      });
    }

    setTimeout(() => {
      this.getupdatedData();
    }, 500);
  };

  getupdatedData = () => {
    const filters = this.state;
    let payload = {
      userId: this.props.userId,
      width: filters.width,
      height: filters.height,
      inch: filters.inch,
      segment: filters.segment,
      load: filters.load,
      speed: filters.speed,
      brand: filters.brand,
      promotion: filters.promotion,
      orderkey: filters.orderkey,
      order: filters.order,
      searchData: filters.searchData,
      lang: this.props.lang,
      Passenger: filters.Passenger,
      Trucks: filters.Trucks,
      Others: filters.Others,
    };
    if (payload) {
      // this.setState({isLoading:true})
      this.props.UPDATE_FILTER_OPTIONS(payload);
    }
  };
  getBrand = (filterOptions) => {
    let barumId;
    let continentalId;
    if (filterOptions.brand) {
      for (let i = 0; i < filterOptions.brand.length; i++) {
        if (
          filterOptions.brand[i].name &&
          filterOptions.brand[i].name.includes("BARUM")
        ) {
          barumId = i;
        }
        if (
          (filterOptions.brand[i].name &&
            filterOptions.brand[i].name.includes("Continental")) ||
          (filterOptions.brand[i].name &&
            filterOptions.brand[i].name.includes("CONTINENTAL"))
        ) {
          continentalId = i;
        }
      }
    }

    // console.log("BARUM :", barumId, "Continental:", continentalId  )

    let brands = _move(filterOptions.brand, barumId, 0);
    let finalFilters = _move(brands, continentalId, 0);
    this.setState({
      brandList: finalFilters,
    });
  };

  clear = () => {
    this.setState({
      width: "",
      height: "",
      inch: "",
      segment: "",
      load: "",
      speed: "",
      brand: "",
      promotion: "",
      orderkey: "",
      order: "asc",
      filterOptions: this.props.filterOptions,
      searchData: "",
      orderDate: "",
      orderStatus: "",
      orderShipment: "",
      Passenger: false,
      Trucks: false,
      Others: false,
      // updatedfilterOptions: null,
    });
  };

  filtersremove = () => {
    const payload = {
      lang: this.props.lang,
      userId: this.props.userId,
    };
    this.clear();
    this.props.FILTER_PRODUCT(payload);
    const orderFilter = undefined;
    this.props.FILTER_ORDER(orderFilter);
    this.props.showfilters();
    this.props.REMOVE_SEARCH();
    this.props.UPDATE_FILTER_OPTIONS(payload);
  };

  filterOrder = () => {
    // debugger
    const filters = this.state;
    const payload = {
      orderDate: filters.orderDate
        ? moment(filters.orderDate).format("YYYY-MM-DD")
        : null,
      orderStatus: filters.orderStatus,
      orderShipment: filters.orderShipment,
    };
    this.props.FILTER_ORDER(payload);
    this.props.showfilters();
  };
  setDetails = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  UNSAFE_componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.props.onChangePage);
    this.props.onChangePage(history.location);
  }

  datePickerInputChangeHandler = (date) => {
    this.setState({ orderDate: date });
  };
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  render() {
    const { t } = this.props;
    const {
      brandList,
      filterOptions,
      orderStatus,
      orderShipment,
      updatedfilterOptions,
    } = this.state;

    const tireWidth_filter =
      filterOptions &&
      filterOptions.width.length > 0 &&
      filterOptions.width.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      });
    const tireHeight_filter =
      filterOptions &&
      filterOptions.height.length > 0 &&
      filterOptions.height.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      });
    const tireDiameter_filter =
      filterOptions &&
      filterOptions.diameter.length > 0 &&
      filterOptions.diameter.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      });
    const tireSegment_filter =
      filterOptions &&
      filterOptions.segment.length > 0 &&
      filterOptions.segment.map((item, index) => {
        return (
          item &&
          item.id && (
            <option key={index} value={item.id}>
              {item && item.name && item.name}
            </option>
          )
        );
      });
    const tireLoadRating_filter =
      filterOptions &&
      filterOptions.loadRating.length > 0 &&
      filterOptions.loadRating.sort().map((item, index) => {
        return (
          item && (
            <option key={index} value={item}>
              {item && item}
            </option>
          )
        );
      });
    const tireSpeedRating_filter =
      filterOptions &&
      filterOptions.speedRating.length > 0 &&
      filterOptions.speedRating.sort().map((item, index) => {
        return (
          item && (
            <option key={index} value={item}>
              {item}
            </option>
          )
        );
      });

    const tireWidth_updatedfilter =
      updatedfilterOptions &&
      updatedfilterOptions.width.length > 0 &&
      updatedfilterOptions.width.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      });
    const tireHeight_updatedfilter =
      updatedfilterOptions &&
      updatedfilterOptions.height.length > 0 &&
      updatedfilterOptions.height.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      });
    const tireDiameter_updatedfilter =
      updatedfilterOptions &&
      updatedfilterOptions.diameter.length > 0 &&
      updatedfilterOptions.diameter.map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      });
    const tireSegment_updatedfilter =
      updatedfilterOptions &&
      updatedfilterOptions.segment.length > 0 &&
      updatedfilterOptions.segment.map((item, index) => {
        return (
          item &&
          item.id && (
            <option key={index} value={item.id}>
              {item && item.name && item.name}
            </option>
          )
        );
      });
    const tireLoadRating_updatedfilter =
      updatedfilterOptions &&
      updatedfilterOptions.loadRating.length > 0 &&
      updatedfilterOptions.loadRating.sort().map((item, index) => {
        return (
          item && (
            <option key={index} value={item}>
              {item && item}
            </option>
          )
        );
      });
    const tireSpeedRating_updatedfilter =
      updatedfilterOptions &&
      updatedfilterOptions.speedRating.length > 0 &&
      updatedfilterOptions.speedRating?.sort().map((item, index) => {
        return (
          item && (
            <option key={index} value={item}>
              {item}
            </option>
          )
        );
      });

    const tireWidth = updatedfilterOptions
      ? tireWidth_updatedfilter
      : tireWidth_filter;
    const tireHeight = updatedfilterOptions
      ? tireHeight_updatedfilter
      : tireHeight_filter;
    const tireDiameter = updatedfilterOptions
      ? tireDiameter_updatedfilter
      : tireDiameter_filter;
    const tireSegment = updatedfilterOptions
      ? tireSegment_updatedfilter
      : tireSegment_filter;
    const tireLoadRating = updatedfilterOptions
      ? tireLoadRating_updatedfilter
      : tireLoadRating_filter;
    const tireSpeedRating = updatedfilterOptions
      ? tireSpeedRating_updatedfilter
      : tireSpeedRating_filter;

    const tireBrand =
      brandList &&
      brandList.length > 0 &&
      brandList
        .filter((item) => typeof item !== "undefined")
        .map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          );
        });

    return (
      <Fragment>
        {this.state.isLoading ? <Loader /> : null}
        {/* {this.state.search && <Redirect to="/products"/>} */}
        <div
          className={
            this.props.collapsefilter ? "filters-box open" : "filters-box"
          }
        >
          <h3>
            {t("filters.title")}{" "}
            <span
              className="close icon-close"
              onClick={this.props.showfilters}
            ></span>
          </h3>
          {this.props.location.pathname === "/orders" && (
            <Fragment>
              <FormGroup className="row">
                <Col className="px-1">
                  <Label>{t("filters.date")}</Label>
                  {/* <Input type="date" name="date" value="mm-dd-yyyy" id="exampleDate" placeholder="date placeholder" /> */}
                  {/* {moment(this.state.orderDate).locale('he', localization).format('LLL')} 
                                    <Input type="date" name="orderDate" placeholder="mm-dd-yyyy" onChange={this.setDetails} value={this.state.orderDate}/>*/}
                  <DatePicker
                    selected={this.state.orderDate}
                    onChange={this.datePickerInputChangeHandler}
                    style={{ border: "2px solid red" }}
                    className="form-control"
                    maxDate={new Date()}
                    placeholderText={t("complaintPage.fileComlaint.dateformat")}
                    dateFormat="dd/MM/yyyy"
                    onChangeRaw={this.handleDateChangeRaw}
                    locale={this.props.lang === "he" ? "he" : null}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col className="px-1">
                  <Label>{t("filters.status")}</Label>
                  <select
                    className="select-css"
                    name="orderStatus"
                    value={orderStatus}
                    onChange={this.setDetails}
                  >
                    <option>{t("OrderPage.orderStatus.all")}</option>

                    <option value="In process">
                      {t("OrderPage.orderStatus.inProcess")}
                    </option>
                    <option value="Accepted">
                      {t("OrderPage.orderStatus.accepted")}
                    </option>
                    <option value="Rejected">
                      {t("OrderPage.orderStatus.rejected")}
                    </option>
                    <option value="Invoiced">
                      {t("OrderPage.orderStatus.orderCreated")}
                    </option>
                  </select>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col className="px-1">
                  <Label>{t("filters.shipment")}</Label>
                  <select
                    className="select-css"
                    name="orderShipment"
                    value={orderShipment}
                    onChange={this.setDetails}
                  >
                    <option>{t("filters.all")}</option>
                  </select>
                </Col>
              </FormGroup>
              <div className="filter-button_btn">
                <FormGroup className="row align-items-center">
                  <Col className="px-1">
                    <Button
                      color="primary rounded-pill font-weight-bold text-nowrap"
                      block
                      onClick={this.filterOrder}
                    >
                      {t("filters.applyBtn")}
                      <span className="icon-next align-middle"></span>
                    </Button>
                  </Col>
                </FormGroup>
                <FormGroup className="row align-items-center">
                  <Col className="px-1">
                    <Button
                      outline
                      color="secondary rounded-pill font-weight-bold"
                      block
                      onClick={this.filtersremove}
                    >
                      {t("filters.clearBtn")}
                    </Button>
                  </Col>
                </FormGroup>
              </div>
            </Fragment>
          )}
          {(this.props.location.pathname === "/products" ||
            this.props.location.pathname === "/newproducts") && (
            <Fragment>
              <FormGroup className="row">
                <Col
                  className="px-1"
                  onClick={() => this.selectOthersegemt("Passenger")}
                >
                  <label className="checkbox path">
                    <input
                      type="checkbox"
                      checked={this.state.Passenger}
                      readOnly
                    />
                    <svg viewBox="0 0 21 21">
                      <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                    </svg>
                    {t("filters.Passenger")}
                  </label>
                </Col>
                <Col
                  className="px-1"
                  onClick={() => this.selectOthersegemt("Trucks")}
                >
                  <label className="checkbox path">
                    <input
                      type="checkbox"
                      checked={this.state.Trucks}
                      readOnly
                    />
                    <svg viewBox="0 0 21 21">
                      <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                    </svg>
                    {t("filters.Trucks")}
                  </label>
                </Col>
                <Col
                  className="px-1"
                  onClick={() => this.selectOthersegemt("Others")}
                >
                  <label className="checkbox path">
                    <input
                      type="checkbox"
                      checked={this.state.Others}
                      readOnly
                    />
                    <svg viewBox="0 0 21 21">
                      <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                    </svg>
                    {t("filters.Others")}
                  </label>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col className="px-1">
                  <Label>{t("filters.width")}</Label>
                  <select
                    className="select-css"
                    value={this.state.width}
                    name="width"
                    onChange={this.onChange}
                  >
                    <option value="">{t("filters.all")}</option>
                    {tireWidth}
                  </select>
                </Col>
                <Col className="px-1">
                  <Label>{t("filters.ratio")}</Label>
                  <select
                    className="select-css"
                    name="height"
                    value={this.state.height}
                    onChange={this.onChange}
                  >
                    <option value="">{t("filters.all")}</option>
                    {tireHeight}
                  </select>
                </Col>
                <Col className="px-1">
                  <Label>{t("filters.rim")}</Label>
                  <select
                    className="select-css"
                    name="inch"
                    value={this.state.inch}
                    onChange={this.onChange}
                  >
                    <option value="">{t("filters.all")}</option>
                    {tireDiameter}
                  </select>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col className="px-1">
                  <Label>{t("filters.segment")}</Label>
                  <select
                    className="select-css"
                    name="segment"
                    value={this.state.segment}
                    onChange={this.onChange}
                  >
                    <option value="">{t("filters.all")}</option>
                    {tireSegment}
                  </select>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col className="px-1">
                  <Label>{t("filters.loadIndex")}</Label>
                  <select
                    className="select-css"
                    name="load"
                    value={this.state.load}
                    onChange={this.onChange}
                  >
                    <option value="">{t("filters.all")}</option>
                    {tireLoadRating}
                  </select>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col className="px-1">
                  <Label>{t("filters.speedIndex")}</Label>
                  <select
                    className="select-css"
                    name="speed"
                    value={this.state.speed}
                    onChange={this.onChange}
                  >
                    <option value="">{t("filters.all")}</option>
                    {tireSpeedRating}
                  </select>
                </Col>
              </FormGroup>
              {/* <FormGroup className="row align-items-center">
                            <Col className="px-1">
                                    <Label>Min. Price</Label>
                                    
                                    <select className="select-css" name="minprice" value={this.state.minprice} onChange={this.onChange}>
                                        <option value="">All</option>
                                        {tirePrice}
                                    </select>
                            </Col>
                            <Col className="px-0 text-center" xs={1} sm={1}>
                                    -
                            </Col>
                            <Col className="px-1">
                                <Label>Max. Price</Label>
                                <select className="select-css" name="maxprice" value={this.state.maxprice} onChange={this.onChange}>
                                        <option value="">All</option>
                                        {tirePrice}
                                    </select>
                            </Col>
                        </FormGroup> */}
              <FormGroup className="row align-items-center">
                <Col className="px-1">
                  <Label>{t("filters.brand")}</Label>
                  <select
                    className="select-css"
                    name="brand"
                    value={this.state.brand}
                    onChange={this.onChange}
                  >
                    <option value="">{t("filters.all")}</option>
                    {tireBrand}
                  </select>
                </Col>
              </FormGroup>
              <FormGroup className="row align-items-center">
                <Col className="px-1">
                  <Label>{t("filters.promotions.title")}</Label>
                  <select
                    className="select-css"
                    name="promotion"
                    value={this.state.promotion}
                    onChange={this.onPromotionChange}
                  >
                    <option>{t("filters.all")}</option>
                    <option index="0" value="1">
                      {t("filters.promotions.Yes")}
                    </option>
                    <option index="1" value="2">
                      {t("filters.promotions.No")}
                    </option>
                  </select>
                </Col>
              </FormGroup>
              {/* <FormGroup className="row align-items-center">
                            <Col className="px-1">
                                    <Label>Promotions</Label>
                                    <select className="select-css" name="promotion" value={this.state.promotion} onChange={this.onChange}>
                                        <option value="">All</option>
                                        {tirePromotion}
                                    </select>
                            </Col>
                        </FormGroup> */}
              {/*    <FormGroup className="row">
                                <Col xl={12} className="px-1 mb-2 mt-2">{t('filters.promotions.title')}</Col>
                                <Col className="px-1">
                                    <label htmlFor="rdo-1" className="btn-radio">
                                        <input type="radio" id="rdo-1" name="radio-grp" onChange={e => this.setState({ promotion: 1 })} checked={this.state.promotion === 1}/>
                                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                                            <circle cx="10" cy="10" r="9"></circle>
                                            <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                            <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                        </svg>
                                        <span>{t('filters.promotions.Yes')}</span>
                                    </label>
                                </Col>
                                <Col className="px-1">
                                    <label htmlFor="rdo-2" className="btn-radio">
                                        <input type="radio" id="rdo-2" name="radio-grp" onChange={e => this.setState({ promotion: 0 })} checked={this.state.promotion === 0}/>
                                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                                            <circle cx="10" cy="10" r="9"></circle>
                                            <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner"></path>
                                            <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer"></path>
                                        </svg>
                                        <span>{t('filters.promotions.No')}</span>
                                    </label>
                                </Col>
                            </FormGroup>    */}
              <Row className="mb-3 ">
                <Col
                  xs={6}
                  sm={6}
                  md={12}
                  lg={12}
                  xl={12}
                  className="px-1 mb-3"
                >
                  <Button
                    color="primary rounded-pill font-weight-bold text-nowrap"
                    block
                    onClick={this.applyFilter}
                  >
                    {t("filters.applyBtn")}{" "}
                    <span className="icon-next align-middle"></span>
                  </Button>
                </Col>
                <Col xs={6} sm={6} md={12} lg={12} xl={12} className="px-1">
                  <Button
                    outline
                    color="secondary rounded-pill font-weight-bold"
                    block
                    onClick={this.filtersremove}
                  >
                    {t("filters.clearBtn")}
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(FiltersSelection))
);
