import React, { Fragment } from "react";
import { Input, Col, Button, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { SEARCH_PRODUCTS } from "../../store/actions/cart";
import { toast } from "react-toastify";
import Loader from "../../Layout/Loader";
// import { Redirect } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../store/actions/layout";
import { onCollapseToggle } from "../../store/actions/layout";
import AutoCompleted from "./AutoCompleted";

const mapStateToProps = (state) => {
  return {
    searchedProductsListat: state.Cart.searchedProductsListat,
    defaultLanguage: state.Layout.defaultLanguage,
    closeSearchBox: state.Layout.closeSearchBox,
    collapsefilter: state.Layout.collapsefilter,
    userId: state.Auth.userId,
    filterProductItem: state.Product.filterProductItem,
    selectedWarehouse: state.Cart.selectedWarehouse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SEARCH_PRODUCTS: (payload) => dispatch(SEARCH_PRODUCTS(payload)),
    DEFAULT_LANGUAGE: (payload) => dispatch(DEFAULT_LANGUAGE(payload)),
    // onPageChange: (payload) => dispatch(onPageChange(payload)),
    onCollapseToggle: (payload) => dispatch(onCollapseToggle(payload)),
  };
};

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: "",
      searchField: true,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname !== "/products") {
      this.setState({
        searchField: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    const { searchedProductsListat } = this.props;
    if (searchedProductsListat !== nextprops.searchedProductsListat) {
      this.setState({
        isLoading: false,
      });
    }
    if (window.location.pathname !== "/products") {
      this.setState({
        searchField: false,
      });
    } else {
      this.setState({
        searchField: true,
      });
    }
    // if (nextprops.collapsefilter === true) {
    //   this.setState({
    //     searchField: false,
    //   });
    // }
    // if (nextprops.closeSearchBox === false) {
    //   this.setState({
    //     searchField: false,
    //   });
    // }
  }

  onSearch = () => {
    const { userId } = this.props;
    // if (!this.state.searchFilter) {
    //   toast.error(this.props.t("tostMsg.headerSearch"));
    //   return;
    // }
    const filters = {
      searchFilter: this.state.searchFilter,
      warehouse: this.props.selectedWarehouse,
      lang: this.props.defaultLanguage,
      userId: userId,
    };
    this.setState({ isLoading: true });
    this.props.SEARCH_PRODUCTS(filters);
    this.setState({ searchFilter: "" });
    this.props.history.push("/products?s=true");
  };
  onSelectSearch = (value) => {
    const { userId } = this.props;
    // if (!value) {
    //   toast.error(this.props.t("tostMsg.headerSearch"));
    //   return;
    // }
    const filters = {
      searchFilter: value,
      warehouse: this.props.selectedWarehouse,
      lang: this.props.defaultLanguage,
      userId: userId,
    };
    this.setState({ isLoading: true });
    this.props.SEARCH_PRODUCTS(filters);
    this.setState({ searchFilter: "" });
    this.props.history.push("/products");
  };

  handalKeypress = (e) => {
    if (e.key === "Enter") {
      this.onSearch();
    }
  };

  onChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  mobileSearch = () => {
    /* added to collapse the filter layout */
    if (window.location.pathname === "/products") {
      if (this.props.collapsefilter)
        this.props.onCollapseToggle({ collapsefilter: false });
    }

    /* ================================== */

    this.setState({
      searchField: !this.state.searchField,
    });
    // const payload = {
    //     closeSearchBox: !this.props.closeSearchBox
    // }
    // this.props.onPageChange(payload)
  };

  langChange = (e) => {
    const { value } = e.target;
    this.props.changeLanguage(value);
    this.props.DEFAULT_LANGUAGE(value);
  };

  closeAlert = () => {
    let alertBox = document.getElementsByClassName("alert");
    if (alertBox[0].classList.contains("show"))
      alertBox[0].style.display = "none";
    //if (alertBox[0].classList.contains("active")) { alertBox[0].classList.remove('active'); }
  };

  render() {
    const { isMobile, t, defaultLanguage, filterProductItem } = this.props;
    return (
      <Fragment>
        {this.state.isLoading && <Loader />}
        {/* {isMobile && this.state.searchField ? <div className="dropdownLayer" onClick={this.mobileSearch}></div> : null} */}
        {/* {this.state.redirect && <Redirect push to="/products" />} */}
        {isMobile && window.innerWidth < 700 ? (
          <Col xs={1} sm={1} md={2} lg={2} xl={2} className="px-0">
            <div style={{ position: "relative", height: "34px" }}>
              {/* {window.location.pathname === "/products" && */}
              {this.state.searchField && (
                <div className="mobileSearch">
                  {/* <Input
                    type="text"
                    className="rounded-pill"
                    placeholder={t("topHeader.productSearch")}
                    name="searchFilter"
                    onChange={this.onChange}
                    onKeyPress={this.handalKeypress}
                    onClick={this.closeAlert}
                  />
                  <Button color="primary" className="search-btn rounded-pill" onClick={this.onSearch}>
                    <span className="icon-search"></span>
                  </Button> */}
                  <AutoCompleted
                    onSearch={this.onSearch}
                    filterProductItem={filterProductItem}
                    setValue={this.onChange}
                    setWarehouse={this.setWarehouse}
                    onSelectSearch={this.onSelectSearch}
                    isMobile={isMobile}
                  />
                </div>
              )}
              {/* <Button color="primary" className="search-btn rounded-pill search-btn-mob" onClick={this.mobileSearch}>
                <span className="icon-search"></span>
              </Button> */}
            </div>
          </Col>
        ) : (
          <div className="top-search-box">
            <AutoCompleted
              onSearch={this.onSearch}
              filterProductItem={filterProductItem}
              setValue={this.onChange}
              setWarehouse={this.setWarehouse}
              onSelectSearch={this.onSelectSearch}
              isMobile={isMobile}
            />

            {/* <Button
              color="primary"
              className="search-btn rounded-pill"
              onClick={this.onSearch}
            >
              <span className="icon-search"></span>
            </Button> */}
          </div>
        )}
      </Fragment>
    );
  }
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
);
