import { PARENTS_ID } from '../../constents/helpers';

export const GET_CART_DETAIL_QUERY = (id, lang) => {
  return `{
    getCart(id: ${id}, defaultLanguage: "${lang}") {\
      id user {
        ... on object_user {
          id
          userGroup {
            ... on object_userGroup {
              id 
              groupType
            }
          }
        }
      } 
      cartItem {
        ... on object_cartItem {
          id 
          quantity 
          tireDot {
            ... on object_tireDot {
              id
              price
              stock
              yearOfManufacture
              specialPrice {
                ... on object_specialPrice {
                  id
                  specialPrice
                  validFrom
                  validTo
                  minPurchaseQuantity
                  user {
                    ... on object_user {
                      id
                    }
                  }
                  userGroup {
                    ... on object_userGroup {
                      id
                      groupType
                    }
                  }
                }
              }
            }
          }
          tireSize {
            ... on object_tireSize {
              id 
              width 
              height          
              diameter 
              contructionType 
              loadRating 
              loadRating1 
              speedRating 
              speedRating1          
              speedRating2 
              suffix 
              loadCapacity 
              oeKey
              validFrom 
              validUntil 
              tire {
                ... on object_tire {
                  id 
                  title 
                  sizeDescription 
                  frFlag 
                  ssrMark 
                  evKey
                  segment {
                    ... on object_segment {
                      name
                    }
                  }
                  brand {
                    ... on object_brand {
                      brandName
                    }
                  }
                  certifiedBy {
                    ... on object_certifiedBy {
                      tooltip 
                      icon { 
                        fullpath
                      }
                    }
                  }
                }
              }
              promotion {
                ... on object_promotion {
                  id 
                  minPurchaseType
                  minPurchaseValue 
                  discountType 
                  discountTypeValue 
                  freeShipping 
                  includeCheapestProductFree 
                  freeProductQuantity 
                  maxDiscountAmount 
                  freeTire { 
                    ... on object_freeTire { 
                      id 
                      stock 
                      tire { 
                        ... on object_tireSize { 
                          id 
                          width 
                          height 
                          diameter 
                          contructionType 
                          loadRating 
                          speedRating 
                          tire { 
                            ... on object_tire { 
                              title 
                            }
                          }
                        }
                      }
                    }
                  }
                  providePromotionCode 
                  promotionCodeValidDays 
                  promotionCodeValidDaysValue 
                  promotionCodeValidDate 
                  promotionCodeValidDateValue 
                  validFrom 
                  validUntil 
                  title 
                  userGroup {
                    ... on object_userGroup {
                      id 
                      groupType 
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
};

export const CREATE_CART_QUERY = (cartkey, userId) => {
  return `mutation {
    createCart(parentId: ${PARENTS_ID.CREATE_CART}, key: "cart${cartkey}", input: {totalAmount: 0.00, user: {id: ${userId}, type: "object"}}) {
      success
      message
      output(defaultLanguage: "en") {
        id
        key
        creationDate
        fullpath
        totalAmount
      }
    }
  }`;
};

export const GET_USER_CART_ID_AND_FEATURED_PRODUCTS_QUERY = (
  id,
  lang,
  currentDate,
  currentTime
) => {
  return `{
    getUser(id:${id}) {
        cart{
          ... on object_cart {
            id
          }
        }
      }
    getTireSizeListing(defaultLanguage: "${lang}", filter: "{\\"showasFeaturedProduct\\" : \\"1\\", \\"validFrom\\": {\\"$lte\\" :\\"${currentDate} ${currentTime}\\"}, \\"validUntil\\": {\\"$gte\\" :\\"${currentDate} ${currentTime}\\"} }") {
      edges {
        node {
          id
          width
          height
          diameter
          contructionType
          loadRating
          loadRating1
          speedRating
          speedRating1
          speedRating2
          suffix
          loadCapacity
          oeKey
          validFrom
          validUntil
          featureProductLabel
          mediaFiles {
            ... on object_tireSize_mediaFiles {
              element {
                selectType
                image {
                  image {
                    id
                    fullpath
                  }
                }
                video {
                  poster {
                    fullpath
                  }
                  title
                  type
                  description
                }
                thumbnail {
                  image {
                    id
                    fullpath
                  }
                }
                extThumbnail
                extImageorVideo
              }
            }
          }
          dots {
            ... on object_tireDot {
              id
              price
              stock
              yearOfManufacture
              specialPrice {
                ... on object_specialPrice {
                  id
                  validTo
                  validFrom
                  specialPrice
                  minPurchaseQuantity
                  user {
                      ... on object_user{
                        id
                      }
                    }
                  userGroup {
                    ... on object_userGroup {
                      id
                      groupType
                    }
                  }
                }
              }
            }
          }
          tire {
            ... on object_tire {
              id
              title
              sizeDescription
              frFlag
              ssrMark
              evKey
              segment {
                ... on object_segment {
                  name
                }
              }
              brand {
                ... on object_brand {
                  brandName
                }
              }
              certifiedBy {
                ... on object_certifiedBy {
                  tooltip
                  icon {
                    fullpath
                  }
                }
              }
            }
          }
          promotion {
            ... on object_promotion {
              id
              minPurchaseType
              minPurchaseValue
              discountType
              discountTypeValue
              freeShipping
              includeCheapestProductFree
              freeProductQuantity
              maxDiscountAmount
              freeTire {
                ... on object_freeTire {
                  id
                  stock
                  tire {
                    ... on object_tireSize {
                      code
                      width
                      height
                      diameter
                      contructionType
                      loadRating
                      loadRating1
                      speedRating
                      speedRating1
                      speedRating2
                      suffix
                      loadCapacity
                      oeKey
                      tire {
                        ... on object_tire {
                          title
                          sizeDescription
                          frFlag
                          ssrMark
                        }
                      }
                    }
                  }
                }
              }
              providePromotionCode
              promotionCodeValidDays
              promotionCodeValidDaysValue
              promotionCodeValidDate
              promotionCodeValidDateValue
              validFrom
              validUntil
              title
              userGroup {
                ... on object_userGroup {
                  id
                  groupType
                }
              }
            }
          }
        }
      }
    }
  }`;
};
